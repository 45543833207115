import React, {FC, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {Button, Tooltip, Alert, Space, message} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import {fetchReservationByLegacyId} from "../../store/caseProfileSlice";
import {setOriginCurrentFinances} from "../../store/wrapUpSlice";
import {GetResponse, RelocationCaseReservationAttributesType} from "@common/typing";
import {ReservationDetails} from "../../typing";
import {getReservationDetails} from "../../utils";
import cx from "classnames";
import styles from "./SyncFinances.module.scss";

interface SyncFinancesProps {}

export const SyncFinances: FC<SyncFinancesProps> = () => {
  const dispatch = useAppDispatch();

  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;

  // finances
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;

  const [lastDestinationResDataTotal, setLastDestinationResDataTotal] = useState(destinationResData[0]?.total);
  const [lastCurrentOriginResDataTotal, setLastCurrentOriginResDataTotal] = useState(currentOriginResData.total);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //sync finances on load
    lookForFinances();
  }, []);

  useEffect(() => {
    if (lastDestinationResDataTotal !== destinationResData[0]?.total && isCaseOpen) {
      message.info("Destination reservation finances has been updated", 3);
      setLastDestinationResDataTotal(destinationResData[0]?.total);
    }
  }, [destinationResData]);

  useEffect(() => {
    if (lastCurrentOriginResDataTotal !== currentOriginResData.total && isCaseOpen) {
      message.info("Origin reservation finances has been updated", 3);
      setLastCurrentOriginResDataTotal(currentOriginResData.total);
    }
  }, [currentOriginResData]);

  const lookForFinances = async () => {
    if (isCaseOpen) {
      setLoading(true);
      // validate
      if (!currentOriginResData || !currentOriginResData.resId) {
        message.error("No origin reservation found");
      }

      // get finances and update state
      try {
        const reservation: GetResponse<RelocationCaseReservationAttributesType> = await fetchReservationByLegacyId(
          currentOriginResData.resId as number
        );
        let reservationDetails = {} as ReservationDetails;
        reservationDetails = await getReservationDetails(reservation);
        if (reservationDetails.total != currentOriginResData.total) {
          dispatch(
            setOriginCurrentFinances({
              ...reservationDetails,
            })
          );
        }
        setLoading(false);
      } catch (error) {
        message.error("Error while getting origin reservation. Please check if the reservation exists");
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={cx({[styles.hiddenItem]: !isCaseOpen || !isMidStayFM})}>
        <Space direction="vertical">
          <Space wrap>
            <Alert
              message="Finances might not be synced"
              description="If you made any changes to the reservation, or noticed that finances are different, update them by clicking on this button"
              type="info"
              showIcon
              action={
                <Tooltip title="Click to Sync Finances">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<SyncOutlined spin={loading} />}
                    onClick={() => {
                      lookForFinances();
                    }}
                    loading={loading}
                  />
                </Tooltip>
              }
            />
          </Space>
        </Space>
      </div>
    </>
  );
};
