import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit} from "../index";
import {FormSubmissionSteps} from "../../../constants";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {VacasaBilledStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";

type VacasaBilledStepProps = {
  title: string;
  readOnly: boolean;
};

export const VacasaBilledStep = ({title, readOnly}: VacasaBilledStepProps) => {
  const dispatch = useAppDispatch();
  const {approvedBy} = useSelector((state: RootState) => state.formSubmission.formData);
  const {previousStep} = useSelector((state: RootState) => state.formSubmission);

  return (
    <div className={generalStyles.stepContainer}>
      <h3>{title}</h3>
      <Formik
        initialValues={{
          approvedBy,
        }}
        onSubmit={(values) => {
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.RESUME, previousStep: FormSubmissionSteps.VACASA_BILLED}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.RESUME, buttonAction: "next"}));
        }}
        validationSchema={VacasaBilledStepSchema}
      >
        {({values, errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {/* Approve By Section */}
              <label htmlFor="approvedBy">
                Please enter the GM who approved Vacasa-billing. {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field type="text" name="approvedBy" value={values.approvedBy} onChange={handleChange} />
                  {touched.approvedBy && errors.approvedBy && <p className={generalStyles.validationError}>{errors.approvedBy}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{approvedBy}</span>
              )}
            </div>

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton
                  step={previousStep === FormSubmissionSteps.SPLIT_BILLED ? FormSubmissionSteps.SPLIT_BILLED : FormSubmissionSteps.BILLING}
                  previousStep={FormSubmissionSteps.VACASA_BILLED}
                />
                <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                  Next
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
