import React, {useState} from "react";
import _ from "lodash";
import styles from "./HistoryItem.module.scss";
import Editor from "rich-markdown-editor";

interface HistoryItemProps {
  item: {
    title: JSX.Element;
    time: string;
    type: "note" | "none";
    note?: string;
    subNote?: string;
    link?: string;
  };
}

export const HistoryItem: React.FunctionComponent<HistoryItemProps> = (HistoryItemProp) => {
  const [fullNote, setFullNote] = useState(false);
  const handleFullNote = () => {
    setFullNote(!fullNote);
  };
  const fullNoteLengthLimit = 60;
  const noteCutOff = HistoryItemProp.item.note && HistoryItemProp.item.note.trim().length > fullNoteLengthLimit;

  return (
    <div>
      <div className={styles.title}>
        <div>{HistoryItemProp.item.title}</div>
        <div className={styles.time}>{HistoryItemProp.item.time}</div>
      </div>
      {HistoryItemProp.item.type === "note" && (
        <div className={styles.note}>
          {HistoryItemProp.item.subNote && (
            <>
              <i className={styles.subNote}>{HistoryItemProp.item.subNote}</i>
              <br />
            </>
          )}
          {_.isNil(HistoryItemProp.item.note) || HistoryItemProp.item.note.trim() === "" ? (
            <i>Empty Note</i>
          ) : (
            <>
              {!noteCutOff ? (
                <>
                  <Editor value={HistoryItemProp.item.note} readOnly />
                </>
              ) : (
                <>
                  {fullNote ? (
                    <>{<Editor value={HistoryItemProp.item.note} readOnly />}</>
                  ) : (
                    <>{<Editor value={HistoryItemProp.item.note?.substring(0, fullNoteLengthLimit)} readOnly />}</>
                  )}
                  <a className={styles.noteLink} onClick={handleFullNote}>
                    {" "}
                    ...{fullNote ? "read less" : "read more"}
                  </a>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
