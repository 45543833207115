import React from "react";
import styles from "./Skeleton.module.scss";

interface Props {
  elements: string[];
}

/*
Element Options to display:
- smallText
- mediumText
- largeText
- separator
- avatar
- thumbnail
*/

const Skeleton = ({elements}: Props) => {
  return (
    <>
      {elements.map((element: any, idx: any) => (
        <div key={idx} className={`${styles.skeleton} ${styles[element]}`} />
      ))}
    </>
  );
};

export default Skeleton;
