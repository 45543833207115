import React from "react";
import {useNavigate, createSearchParams} from "react-router-dom";
import styles from "./QueueTableItemReport.module.scss";
import {defaultFilterGenerator} from "../../constants";

export const QueueTableItemReport = ({item}: any): JSX.Element => {
  const navigate = useNavigate();
  const fillBlank = (data?: number) => (data ? data : "---");
  const getUnInitiated = (data?: number) => (data ? ` (${data} not init.)` : " (0 not init.)");
  const emailToName = (mail?: string) => mail?.split("@")[0].split(".").join(" ");
  const filters = defaultFilterGenerator.byAssignedTo(item.assigned_to);
  const getItemContent = (itemContent: any) => {
    return (
      <>
        <span className={styles.textBold}>{fillBlank(itemContent.total)}</span>
        <span className={styles.unInitiated}>{itemContent.total ? getUnInitiated(itemContent.unInitiated) : ""}</span>
      </>
    );
  };

  return (
    <div className={styles.tableItem}>
      <div className={styles.item} onClick={() => {}}>
        <a onClick={() => navigate({pathname: "/queue", search: `?${createSearchParams(filters)}`})} className={styles.nameLink}>
          {emailToName(item.assigned_to)}
        </a>
      </div>
      <div className={styles.item}>{getItemContent(item.low)}</div>
      <div className={styles.item}>{getItemContent(item.medium)}</div>
      <div className={styles.item}>{getItemContent(item.high)}</div>
      <div className={styles.item}>
        {getItemContent({total: item.total, unInitiated: item.high.unInitiated + item.medium.unInitiated + item.low.unInitiated})}
      </div>
    </div>
  );
};
