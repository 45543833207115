import React from "react";
import {FormSubmission} from "../index";
import styles from "./FormSubmissionContainer.module.scss";

export const FormSubmissionContainer = () => {
  return (
    <div className={styles.formContainer}>
      <FormSubmission />
    </div>
  );
};
