import React from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {removeCasesSelection, resetCasesSelection, selectedCaseType} from "../../store/bulkSelectionSlice";
import {useSelector} from "react-redux";
import styles from "./BulkSelection.module.scss";
import cx from "classnames";
import {Badge} from "@vacasa/react-components-lib";
import {BsX} from "react-icons/bs";
import isEmpty from "lodash/isEmpty";
import {getPriorityClass} from "../../utils";

export const BulkSelection = () => {
  const dispatch = useAppDispatch();
  const casesSelected: selectedCaseType[] = useSelector((state: RootState) => state.bulkSelection.casesSelected);

  return (
    <div className={styles.bulkSelectionContainer}>
      <div className={styles.bulkHeader}>
        <div className={styles.title}>Bulk Selection</div>
        <br />
        <div className={styles.statsSection}>
          <div>Selected Count: {casesSelected.length}</div>
          <div className={styles.link} onClick={() => dispatch(resetCasesSelection())}>
            Clear all
          </div>
        </div>
        <div className={styles.headerCases}>
          <div className={styles.headerItem}>Priority</div>
          <div className={styles.headerItem}>Case ID</div>
          <div className={styles.headerItem}>Res ID</div>
          <div className={styles.headerItem} />
        </div>
      </div>
      <div className={styles.inner}>
        <div className={styles.table}>
          {isEmpty(casesSelected) ? (
            <h3 className={styles.noSelected}>No cases selected</h3>
          ) : (
            <>
              {casesSelected.map((caseSelected, index) => (
                <div key={index} className={styles.item}>
                  <Badge
                    text={caseSelected.priority.name}
                    variant={"error"}
                    outline={false}
                    disabled={false}
                    isPill={true}
                    closeButton={false}
                    customClass={cx(styles.customClassBadge, styles[getPriorityClass(caseSelected.priority.id)])}
                  />
                  <div>{caseSelected.id}</div>
                  <div>{caseSelected.legacy_reservation_id}</div>
                  <a onClick={() => dispatch(removeCasesSelection([caseSelected]))} className={styles.unselectBtn}>
                    <BsX size={20} />
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
