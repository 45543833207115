import * as _ from "lodash";

export interface Finance {
    fees: number;
    taxes: number;
    rent: number;
    tp: number;
}

export interface SplitStayFinance extends Finance {
    id: number;
}

export interface UpdateFinancePayload {
    notes: string;
    updated_by: string;
    finances: Finance;
    finances_after_adjustment: Finance;
    comparable_unit: Finance | null;
    splitstays: SplitStayFinance[];
}

export const formikKey = (financeType: string, id: number) => `splitstay_finance_${financeType}_${id}`;
export const mapFormikToFinances = (data: any, splitStays: any, email: string) => {
    const payload: UpdateFinancePayload = {
        notes: data.notes,
        updated_by: email,
        finances: {
            fees: data.finance_fees_pre,
            taxes: data.finance_taxes_pre,
            rent: data.finance_rent_pre,
            tp: data.finance_tp_pre,
        },
        finances_after_adjustment: {
            fees: data.finance_fees_post,
            taxes: data.finance_taxes_post,
            rent: data.finance_rent_post,
            tp: data.finance_tp_post,
        },
        comparable_unit: null,
        splitstays: [],
    };

    // If Comparable Unit Push Finances
    if (![data.new_finance_fees, data.new_finance_rent, data.new_finance_taxes, data.new_finance_tp].every(_.isNil)) {
        payload.comparable_unit = {
            fees: data.new_finance_fees,
            taxes: data.new_finance_taxes,
            rent: data.new_finance_rent,
            tp: data.new_finance_tp,
        };
    }

    // If SplitStays push to payload
    splitStays.forEach((splitStay: any) => {
        payload.splitstays.push({
            id: splitStay.id,
            fees: data[formikKey("fees", splitStay.id)],
            taxes: data[formikKey("taxes", splitStay.id)],
            rent: data[formikKey("rent", splitStay.id)],
            tp: data[formikKey("tp", splitStay.id)],
        });
    });

    return payload;
};
