import React, {FC} from "react";
import {
  floatFixed,
  carouselDataType,
  urlFinancesContent,
  amountFinancesContent,
  carouselDataToItems,
  originalFinancesTable,
  sumFinances,
} from "../../../utils";
import {ReservationDetails} from "../../../typing";
import {FrontendConfiguration} from "@common/configuration";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import isNil from "lodash/isNil";
import localStyles from "../WrapUpFinances.module.scss";
import {FinancesColumn, FinancesCarousel} from "../../../components";

interface SplitStayFinancesProps {}

export const SplitStayFinances: FC<SplitStayFinancesProps> = () => {
  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const {url} = FrontendConfiguration.adminConfig();

  // finances
  const snapshotOriginPreResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPre) as any;
  const snapshotOriginPostResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPost) as any;
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const midStayTotals = useSelector((state: RootState) => state.wrapUp.finances.midStayFMTotals) as any;

  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;

  const createFinanceCarouselItem = (resDetail: ReservationDetails, title: string): carouselDataType =>
    !isNil(resDetail.resId)
      ? {
          title,
          listingLink: {url: resDetail.unitId ? `${urlVacasaCom}/unit/${resDetail?.unitId}` : resDetail?.unitUrl, urlText: resDetail.unitName || ""},
          resIdLink: {url: `${url}/admin/dashboard/reservation/${resDetail.resId}`, urlText: String(resDetail.resId)},
          rent: {primaryAmount: {amount: resDetail.rent}, secondaryAmount: {amount: null}},
          fees: {primaryAmount: {amount: resDetail.fees}, secondaryAmount: {amount: null}},
          tp: {primaryAmount: {amount: resDetail.tp}, secondaryAmount: {amount: null}},
          taxes: {primaryAmount: {amount: resDetail.taxes}, secondaryAmount: {amount: null}},
          total: {primaryAmount: {amount: resDetail.total}, secondaryAmount: {amount: null}},
        }
      : {
          title,
          listingLink: {url: resDetail.unitUrl, urlText: "Link"},
          resIdLink: {urlText: `Conf # ${resDetail.confirmationCode}`},
          rent: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
          fees: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
          tp: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
          taxes: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
          total: {primaryAmount: {amount: floatFixed(resDetail.total)}, secondaryAmount: {amount: null}},
        };

  const createSplitStayDestinationCarousel = (resDetails: ReservationDetails[]): carouselDataType[] =>
    resDetails.map((e, index, list) => createFinanceCarouselItem(e, `Res ${index + 1} of ${list.length}`));

  const originResFinanceReference = isMidStayFM && !isCaseOpen ? snapshotOriginPostResData : currentOriginResData;
  const regularCarousel: carouselDataType[] = [
    {
      title: "Split Stay Totals",
      listingLink: {urlText: "Multiple"},
      resIdLink: {urlText: "Multiple"},
      rent: {
        primaryAmount: {amount: sumFinances("rent", destinationResData)},
        secondaryAmount: {amount: sumFinances("rent", destinationResData) - snapshotOriginPreResData?.rent, wrapper: "(+/-)"},
      },
      fees: {
        primaryAmount: {amount: sumFinances("fees", destinationResData)},
        secondaryAmount: {amount: sumFinances("fees", destinationResData) - snapshotOriginPreResData?.fees, wrapper: "(+/-)"},
      },
      tp: {
        primaryAmount: {amount: sumFinances("tp", destinationResData)},
        secondaryAmount: {amount: sumFinances("tp", destinationResData) - snapshotOriginPreResData?.tp, wrapper: "(+/-)"},
      },
      taxes: {
        primaryAmount: {amount: sumFinances("taxes", destinationResData)},
        secondaryAmount: {amount: sumFinances("taxes", destinationResData) - snapshotOriginPreResData?.taxes, wrapper: "(+/-)"},
      },
      total: {
        primaryAmount: {amount: sumFinances("total", destinationResData)},
        secondaryAmount: {amount: sumFinances("total", destinationResData) - snapshotOriginPreResData?.total, wrapper: "(+/-)", bold: true},
      },
    },
    ...createSplitStayDestinationCarousel(destinationResData),
  ];

  const midStayCarousel: carouselDataType[] = [
    {
      title: "MSFM Totals (Split Stay)",
      listingLink: {urlText: "Multiple"},
      resIdLink: {urlText: "Multiple"},
      rent: {
        primaryAmount: {amount: midStayTotals?.rent - snapshotOriginPreResData?.rent, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.rent, wrapper: "="},
      },
      fees: {
        primaryAmount: {amount: midStayTotals?.fees - snapshotOriginPreResData?.fees, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.fees, wrapper: "="},
      },
      tp: {
        primaryAmount: {amount: midStayTotals?.tp - snapshotOriginPreResData?.tp, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.tp, wrapper: "="},
      },
      taxes: {
        primaryAmount: {amount: midStayTotals?.taxes - snapshotOriginPreResData?.taxes, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.taxes, wrapper: "="},
      },
      total: {
        primaryAmount: {amount: midStayTotals?.total - snapshotOriginPreResData?.total, wrapper: "+/-", bold: true},
        secondaryAmount: {amount: midStayTotals?.total, wrapper: "="},
      },
    },
    {
      title: "Unused Nights Removed",
      listingLink: {url: `${urlVacasaCom}/unit/${snapshotOriginPreResData?.unitId}`, urlText: snapshotOriginPreResData?.unitName},
      resIdLink: {url: `${url}/admin/dashboard/reservation/${snapshotOriginPreResData?.resId}`, urlText: snapshotOriginPreResData?.resId},
      rent: {
        primaryAmount: {amount: snapshotOriginPreResData?.rent - originResFinanceReference?.rent},
        secondaryAmount: {amount: -originResFinanceReference?.rent, wrapper: "(+/-)"},
      },
      fees: {
        primaryAmount: {amount: snapshotOriginPreResData?.fees - originResFinanceReference?.fees},
        secondaryAmount: {amount: -originResFinanceReference?.fees, wrapper: "(+/-)"},
      },
      tp: {
        primaryAmount: {amount: snapshotOriginPreResData?.tp - originResFinanceReference?.tp},
        secondaryAmount: {amount: -originResFinanceReference?.tp, wrapper: "(+/-)"},
      },
      taxes: {
        primaryAmount: {amount: snapshotOriginPreResData?.taxes - originResFinanceReference?.taxes},
        secondaryAmount: {amount: -originResFinanceReference?.taxes, wrapper: "(+/-)"},
      },
      total: {
        primaryAmount: {amount: snapshotOriginPreResData?.total - originResFinanceReference?.total},
        secondaryAmount: {amount: -originResFinanceReference?.total, wrapper: "(+/-)", bold: true},
      },
    },
    ...createSplitStayDestinationCarousel(destinationResData),
  ];

  return (
    <div className={localStyles.financesTableTwoColumns}>
      {originalFinancesTable(snapshotOriginPreResData, false)}

      {!isMidStayFM ? (
        <FinancesCarousel items={carouselDataToItems(regularCarousel)} showTitles={false} />
      ) : (
        <FinancesCarousel items={carouselDataToItems(midStayCarousel)} showTitles={false} />
      )}
    </div>
  );
};
