import React, {useEffect, useState} from "react";
import styles from "./FilterUnassign.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {updateUnassignedFilter} from "../../store/queueSlice";

export const FilterUnassign: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(false);
  const currentIsUnassigned = useSelector((state: RootState) => state.queue.filters.unassigned.value);
  const nextValue = +(currentIsUnassigned !== 1);

  // prepopulate check if unassigned is updated somewhere else, like queryParams
  useEffect(() => {
    if (currentIsUnassigned === 1) {
      setChecked(true);
    }
  }, [currentIsUnassigned]);

  const handleOnCheck = () => {
    dispatch(updateUnassignedFilter(nextValue));
    setChecked(Boolean(nextValue));
  };

  return (
    <>
      <label className={styles.label}>
        <input style={{verticalAlign: "middle"}} type="checkbox" checked={checked} className={styles.filterCheckbox} onChange={handleOnCheck} /> View
        unassigned cases only
      </label>
    </>
  );
};
