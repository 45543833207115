import {ReservationDetails} from "../../typing";
import {ReservationCancellationPayload} from "@common/typing";

export const floatFixed = (n: number) => parseFloat(Number(n).toFixed(2));

/**
 * Gets Reservation Data + finances
 * @param reservation
 */
export const getReservationDetails = (reservation: any): ReservationDetails => {
    const resAttr = reservation?.data?.attributes?.data?.attributes;
    return {
        resId: parseInt(reservation?.data?.attributes?.data?.id),
        unitUrl: `vacasa.com/unit/${resAttr?.unit_id}`,
        unitId: resAttr?.unit_id,
        unitName: `${resAttr?.unit_name} - ${resAttr?.unit_address?.city}, ${resAttr?.unit_address?.state}`,
        rent: floatFixed(resAttr?.rent) || 0,
        fees: floatFixed(resAttr?.fee_amount) || 0,
        tp: floatFixed(resAttr?.trip_protection_fee) || 0,
        taxes: floatFixed(resAttr?.tax_amount) || 0,
        total: floatFixed(resAttr?.rent + resAttr?.fee_amount + resAttr?.tax_amount + resAttr?.trip_protection_fee) || 0,
    };
};

/**
 * Gets Reservation Data + finances from Comparable Unit
 * @param comparableUnit
 */
export const getReservationDetailsFromComparableUnit = (comparableUnit: any): ReservationDetails => {
    const compUnitAttr = comparableUnit.attributes;
    return {
        resId: compUnitAttr?.legacy_reservation_id,
        unitUrl: compUnitAttr?.public_url,
        unitId: compUnitAttr?.legacy_unit_id || null,
        unitName: compUnitAttr?.public_url_name,
        rent: floatFixed(compUnitAttr?.finance_rent),
        fees: floatFixed(compUnitAttr?.finance_fees),
        tp: floatFixed(compUnitAttr?.finance_tp),
        taxes: floatFixed(compUnitAttr?.finance_taxes),
        total: floatFixed(
            compUnitAttr?.finance_rent +
                compUnitAttr?.finance_fees +
                compUnitAttr?.finance_taxes +
                compUnitAttr?.finance_tp +
                compUnitAttr?.external_amount
        ),
        confirmationCode: compUnitAttr?.external_confirmation_code,
    } as ReservationDetails;
};

/**
 * Create Reservation Payload for Cancelling reservations
 * @param client An identifier for the service initiating the cancellation request,
 * @param type Type of cancellation
 * @param email User Email
 */
export const createCancellationPayload = (client: string, type: string, email: string): ReservationCancellationPayload =>
    ({
        data: {
            type: "cancel_reservation",
            attributes: {
                finances: {
                    type,
                },
                client,
                cancelled_by_email: email,
                legacy_reservation_cancellation_tag_id: 3,
                cancelled_note: "This was cancelled from the Relo UI.",
            },
        },
    } as ReservationCancellationPayload);
