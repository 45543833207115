import React, {useLayoutEffect} from "react";
import {HolidaysContainer} from "../../components";
import {BackSectionV2} from "../../components/back-section-v2/BackSectionV2";

export function HolidaysView() {
  useLayoutEffect(() => {}, []);

  return (
    <>
      <BackSectionV2 text="Holidays Settings" />
      <HolidaysContainer />
    </>
  );
}
