import React, {useEffect, useState} from "react";
import {RadioButton, DatePicker} from "@vacasa/react-components-lib";
import {SearchBar} from "../../index";
import {FrontendConfiguration} from "@common/configuration";
import {typesOfBooking, TypesOfBooking} from "../../../constants";
import {mailToName, Regex} from "@common/utils";
import * as dayjs from "dayjs";
import cx from "classnames";
import styles from "./Splitstay.module.scss";
import {typeBookingToText, textToTypeBooking, floatFixed} from "../../../utils";
import {BsTrash} from "react-icons/bs";
import _ from "lodash";

export type SplitStayItem = {
  id?: number;
  compUnitId: number;
  type: number;
  authorizedBy: string | null;
  unitUrl: string;
  resId: number | null;
  checkin: string;
  checkout: string;
  unitName: string;
  validInputs: boolean;
  confirmationCode?: string;
  externalAmount: number | null;
  reservationCancelled: boolean;
};

interface SplitStayProps {
  editMode: boolean;
  index: number;
  item: SplitStayItem;
  isSelected: boolean;
  onChange?: Function;
  onDelete?: Function;
}

export const SplitStay = ({item, index, isSelected, editMode, onChange, onDelete}: SplitStayProps) => {
  const [typeOfBooking, setTypeOfBooking] = useState(typeBookingToText(item.type) || typeBookingToText(TypesOfBooking.VACASA_UNIT));
  const [checkInDate, setCheckInDate] = useState<string>(item.checkin);
  const [checkOutDate, setCheckOutDate] = useState<string>(item.checkout);
  const [authorizedBySearch, setAuthorizedBySearch] = useState<string>(item.authorizedBy || "");
  const [authorizedBy, setAuthorizedBy] = useState<string | null>(item.authorizedBy);
  const [unitUrl, setUnitUrl] = useState<string>(item.unitUrl);
  const [unitName, setUnitName] = useState<string>(item.unitName);
  const [resId, setResId] = useState<number | null>(item.resId);
  const [confirmationCode, setConfirmationCode] = useState<string>(item.confirmationCode || "");
  const [externalAmount, setExternalAmount] = useState<number | null>(item?.externalAmount || null);
  const [externalAmountString, setExternalAmountString] = useState<string>(!_.isNil(item.externalAmount) ? item.externalAmount.toString() : "");
  const {url} = FrontendConfiguration.adminConfig();
  const [firstRender, setFirstRender] = useState(true);
  const [validUrl, setValidUrl] = useState(false);
  const [validAuthorizedBy, setValidAuthorizedBy] = useState(false);
  const [validResId, setValidResId] = useState(false);
  const [validConfirmationCode, setValidConfirmationCode] = useState(false);
  const [validExternalAmount, setValidExternalAmount] = useState(false);

  useEffect(() => {
    if (!firstRender) reset();
    if (firstRender) setFirstRender(false);
  }, [typeOfBooking]);

  const reset = () => {
    setAuthorizedBy("");
    setUnitUrl("");
    setUnitName("");
    setResId(null);
    setValidAuthorizedBy(false);
    setValidResId(false);
    setValidUrl(false);
    setValidConfirmationCode(false);
    setValidExternalAmount(false);
  };

  const getRegExString = () => {
    const urlRegex = "^(https?:\\/\\/(?:www\\.|(?!www))|www\\.|https?:\\/\\/)?";
    const domainRegex = `${urlRegex}vacasa\\.com`;
    switch (textToTypeBooking(typeOfBooking)) {
      case TypesOfBooking.VACASA_UNIT:
        return `${domainRegex}\\/.+$`;
      case TypesOfBooking.OUTSIDE_BOOKING:
        return `${urlRegex}.+\\..{2,3}($|\\/.*)`;
      default:
        return ".+$";
    }
  };

  const isValidUrl = (url: string) => {
    const regex = new RegExp(getRegExString());
    if (regex.test(url)) setValidUrl(true);
    else setValidUrl(false);
  };

  const isValidResId = (resId: string) => {
    const fixedValue = resId.replace(/\s/g, "");
    const regex = new RegExp(`^${Regex.digit}+$`);
    const valid = regex.test(fixedValue);
    setValidResId(valid);
    return valid;
  };

  const isValidConfirmationCode = (code: string) => {
    const fixedValue = code.replace(/\s/g, "");
    const regex = new RegExp(`^.+$`);
    const valid = regex.test(fixedValue);
    setValidConfirmationCode(valid);
    return valid;
  };

  const isValidExternalAmount = (total: string) => {
    const fixedValue = total.replace(/\s/g, "");
    const regex = new RegExp(`^[\\-\\+]?[0-9]*([.][0-9]+)?$`);
    const valid = regex.test(fixedValue);
    setValidExternalAmount(valid);
    return valid;
  };

  const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = e.target.value.replace(/\s/g, "");
    setUnitUrl(fixedValue);
    isValidUrl(fixedValue);
  };

  const handleResIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = e.target.value.replace(/\s/g, "").replace(/\D/g, "");
    if (isValidResId(fixedValue)) setResId(parseInt(fixedValue));
    if (fixedValue === "") setResId(null);
  };

  const handleOnChangeAuthorizedBy = (email: string) => {
    setAuthorizedBySearch(email || "");
    setAuthorizedBy(email || null);
    isValidAuthorizedBy(email || "");
  };

  const handleConfirmationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = event.target.value.replace(/\s/g, "");
    setConfirmationCode(fixedValue);
    isValidConfirmationCode(fixedValue);
  };

  const handleExternalAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = event.target.value.replace(/\s/g, "");
    setExternalAmountString(fixedValue);
    if (isValidExternalAmount(fixedValue)) setExternalAmount(floatFixed(parseFloat(fixedValue)));
    if (fixedValue === "") setExternalAmount(null);
  };

  const handleDelete = () => {
    if (onDelete) onDelete(index);
  };

  const inputsValidation = () => {
    if (typeOfBooking === typeBookingToText(TypesOfBooking.VACASA_UNIT)) return validResId;
    else {
      if (isSelected) return validAuthorizedBy && validUrl && validConfirmationCode && validExternalAmount;
      return validAuthorizedBy && validUrl;
    }
  };

  const isValidAuthorizedBy = (email: string) => setValidAuthorizedBy(!_.isEmpty(email));

  useEffect(() => {
    isValidUrl(item.unitUrl);
    isValidResId(item.resId ? item.resId.toString() : "");
    isValidAuthorizedBy(item.authorizedBy || "");
    isValidConfirmationCode(item.confirmationCode || "");
    isValidExternalAmount(item.externalAmount?.toString() || "");
  }, []);

  useEffect(() => {
    if (onChange) {
      const state: SplitStayItem = {
        id: item.id,
        compUnitId: item.compUnitId,
        type: textToTypeBooking(typeOfBooking),
        authorizedBy,
        unitUrl,
        resId,
        checkin: checkInDate,
        checkout: checkOutDate,
        unitName: unitName || "",
        validInputs: inputsValidation(),
        confirmationCode,
        externalAmount: externalAmount,
        reservationCancelled: item.reservationCancelled,
      };
      onChange(index, state);
    }
  }, [
    authorizedBy,
    resId,
    checkInDate,
    checkOutDate,
    unitUrl,
    typeOfBooking,
    confirmationCode,
    externalAmount,
    validUrl,
    validResId,
    validAuthorizedBy,
    validConfirmationCode,
    validExternalAmount,
  ]);

  const VacasaUnit = () => (
    <div className={styles.vacasaUnitSection}>
      <span className={styles.subTitles}>Res ID</span>
      {editMode ? (
        <input
          type="text"
          className={styles.textBoxWrapUp + " " + (!validResId ? styles.textBoxWarning : "")}
          value={resId ? resId.toString() : ""}
          onChange={handleResIdChange}
        />
      ) : (
        <span className={styles.noEditText}>
          <a href={url + "/admin/dashboard/reservation/" + item.resId} target="_blank" rel="noopener noreferrer" className={styles.urlPlain}>
            {item.resId}
          </a>
        </span>
      )}

      {!editMode && (
        <>
          <span className={cx(styles.urlTitle, !editMode && styles.urlTitlePlain)}>{editMode ? "URL for listing" : "URL"}</span>
          <a href={item.unitUrl} className={styles.urlPlain} target="_blank" rel="noopener noreferrer">
            {item.unitName}
          </a>
        </>
      )}
    </div>
  );

  const OutsideBooking = () => (
    <div className={styles.outsideBookingSection}>
      <span className={styles.subTitles}>Authorized by</span>
      <>
        {editMode ? (
          <>
            <SearchBar
              placeholder="Search Agents"
              value={authorizedBySearch}
              onSearchChange={setAuthorizedBySearch}
              handleOnChange={handleOnChangeAuthorizedBy}
            />
            {!validAuthorizedBy && <div className={styles.textBoxWarningText}>Invalid Authorized By</div>}
          </>
        ) : (
          <span className={styles.datesPlain}>{mailToName(item.authorizedBy || "")}</span>
        )}
        <span className={cx(styles.subTitles, styles.urlOutsideBooking)}>URL for Outside Booking unit</span>
        {editMode ? (
          <input
            type="text"
            className={styles.textBox + " " + (!validUrl ? styles.textBoxWarning : "")}
            value={unitUrl ? unitUrl : ""}
            onChange={handleUrl}
          />
        ) : (
          <a href={item.unitUrl} className={styles.urlPlain} target="_blank" rel="noopener noreferrer">
            {item.unitUrl}
          </a>
        )}
        {isSelected && (
          <div className={styles.outsideBookingSection}>
            <span className={cx(styles.subTitles, styles.urlOutsideBooking)}>Confirmation # (optional)</span>
            <input
              type="text"
              className={styles.textBox + " " + (!validConfirmationCode ? styles.textBoxWarning : "")}
              value={confirmationCode}
              onChange={handleConfirmationCodeChange}
            />
            <span className={cx(styles.subTitles, styles.urlOutsideBooking)}>Total Cost (required)</span>
            <input
              type="text"
              className={styles.textBox + " " + (!validExternalAmount ? styles.textBoxWarning : "")}
              value={externalAmountString}
              onChange={handleExternalAmountChange}
            />
          </div>
        )}
      </>
    </div>
  );

  return (
    <div className={styles.splitstayContainer}>
      <div className={styles.subTitleGrid}>
        <div>
          <span className={styles.unitNumber}>Unit #{index}</span>
        </div>
        {editMode && (
          <div className={styles.alignRight}>
            <BsTrash style={{verticalAlign: "middle"}} className={styles.editBtn} onClick={handleDelete} />
          </div>
        )}
      </div>

      {/* Dates Section */}
      <div className={styles.datesContainer}>
        <div className={styles.dates}>
          <span className={styles.datesTitle}>Check in</span>
          {editMode ? (
            <DatePicker
              label=""
              value={dayjs(checkInDate).add(1, "days").format("YYYY-MM-DD")}
              onChange={(e) => setCheckInDate(dayjs(e).format("YYYY-MM-DD"))}
              variant="inline"
              disabled={false}
              decoration=""
              hiddenLabel={true}
              disableToolbar={true}
            />
          ) : (
            <span className={styles.datesPlain}>{item.checkin}</span>
          )}
        </div>
        <div className={styles.dates}>
          <span className={styles.datesTitle}>Check out</span>
          {editMode ? (
            <DatePicker
              label=""
              value={dayjs(checkOutDate >= checkInDate ? checkOutDate : checkInDate)
                .add(1, "days")
                .format("YYYY-MM-DD")}
              onChange={(e) => setCheckOutDate(dayjs(e).format("YYYY-MM-DD"))}
              variant="inline"
              disabled={false}
              decoration=""
              hiddenLabel={true}
              disableToolbar={true}
              minDate={dayjs(checkInDate).add(1, "days").format("YYYY-MM-DD")}
            />
          ) : (
            <span className={styles.datesPlain}>{item.checkout}</span>
          )}
        </div>
      </div>

      {/* Type of booking Section */}
      {editMode && (
        <div className={styles.bookingTypeContainer}>
          <>
            <span className={styles.bookingTypeTitle}>Type of Booking</span>
            {typesOfBooking.map((option) => (
              <RadioButton
                key={option.id}
                checked={typeOfBooking}
                label={option.name}
                setter={setTypeOfBooking}
                value={option.name}
                disabled={false}
              />
            ))}
          </>
        </div>
      )}
      {!!typeOfBooking ? (typeOfBooking === typeBookingToText(TypesOfBooking.VACASA_UNIT) ? VacasaUnit() : OutsideBooking()) : null}
    </div>
  );
};
