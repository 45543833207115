import React, {FC} from "react";
import styles from "../wrap-up-component/WrapUp.module.scss";
import {BsExclamationTriangle} from "react-icons/bs";

interface WrapUpMoveNewUnitAlertProps {}

export const WrapUpMoveNewUnitAlert: FC<WrapUpMoveNewUnitAlertProps> = () => {

  return (
    <div className={styles.alertDiv}>
      <div className={styles.containerAlert}>
        <div>
          <BsExclamationTriangle style={{verticalAlign: "bottom"}} />
        </div>
        <div className={styles.alertDivText}>
          Please return to the Unit Options section under “Initiation & Follow up„ tab to select the option our Guest is choosing before proceeding
          with Wrap-up.
        </div>
      </div>
      <div>
        <button className={styles.button}>Choose Unit</button>
      </div>
    </div>
  );
};
