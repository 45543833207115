import React, {FC} from "react";
import styles from "./CaseStatus.module.scss";

interface CaseStatusProps {}

export const CaseStatus: FC<CaseStatusProps> = () => (
  <div className={styles.caseStatus}>
    <div className={styles.caseStatusTitle}>Case Status</div>
    <div className={styles.caseStatusInput}>
      <select className={styles.caseStatusDdl}>
        <option value="open">Open</option>
        <option value="closed">Closed</option>
      </select>
    </div>
  </div>
);
