import React, {FC} from "react";
import {useSelector} from "react-redux";
import styles from "../wrap-up-alerts/WrapUpAlerts.module.scss";
import {AlertComponent} from "../alert/AlertComponent";
import {FrontendConfiguration} from "@common/configuration";
import {
  setAlertWrapUpCompleted,
  setAlertWrapUpReversed,
  setAlertCancellation,
  setAlertCasHoldExist,
  setAlertCleanAfterStay,
  setAlertReopenError,
} from "../../store/wrapUpSlice";
import {RootState, useAppDispatch} from "../../store/store";

interface WrapUpAlertsProps {}

export const WrapUpAlerts: FC<WrapUpAlertsProps> = (props) => {
  const wrapUpCompleted = useSelector((state: RootState) => state.wrapUp.alerts.wrapUpCompleted) as any;
  const wrapUpReversed = useSelector((state: RootState) => state.wrapUp.alerts.wrapUpReversed) as any;
  const casHoldExist = useSelector((state: RootState) => state.wrapUp.alerts.casHoldExist) as any;
  const cleanAfterStay = useSelector((state: RootState) => state.wrapUp.alerts.cleanAfterStay) as any;
  const cancellation = useSelector((state: RootState) => state.wrapUp.alerts.cancellation) as any;
  const {cancellationFailedIds, originalResUnitId, casHoldId} = useSelector((state: RootState) => state.wrapUp.alertsData) as any;
  const reopenCaseHasError = useSelector((state: RootState) => state.wrapUp.alerts.reopenCaseHasError) as any;
  const reopenCaseErrorObject = useSelector((state: RootState) => state.wrapUp.alertsData.reopenCaseErrorObject) as any;

  const {url} = FrontendConfiguration.adminConfig();

  const dispatch = useAppDispatch();

  return (
    <>
      {wrapUpCompleted && (
        <AlertComponent
          text={<>Success! This wrap-up is complete.</>}
          className="success"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertWrapUpCompleted(false))}
        />
      )}

      {wrapUpReversed && (
        <AlertComponent
          text={<>Success! This wrap-up is reversed, the case is open now.</>}
          className="success"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertWrapUpReversed(false))}
        />
      )}

      {casHoldExist && (
        <AlertComponent
          text={
            <>
              This cases had a Reservation
              <a className={styles.link} target={"_blank"} rel="noopener noreferrer" href={`${url}/admin/dashboard/reservation/${casHoldId}?`}>
                {" "}
                Hold{" "}
              </a>
              with a Clean After Stay applied. If you don't need it anymore go to Resedit and cancel it.
            </>
          }
          className="warning"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertCasHoldExist(false))}
        />
      )}

      {cleanAfterStay && (
        <AlertComponent
          text={
            <>
              We are unable to schedule a Clean After Stay. Please create it manually.{" "}
              <a className={styles.link} target="_blank" rel="noopener noreferrer" href={`${url}/admin/resgrid.php?UnitID=${originalResUnitId}`}>
                Go to Resedit
              </a>
            </>
          }
          className="warning"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertCleanAfterStay(false))}
        />
      )}

      {cancellation && (
        <AlertComponent
          text={<>We are unable to cancel reservations {cancellationFailedIds.join(", ")} associated with this case. Please, cancel it manually.</>}
          className="warning"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertCancellation(false))}
        />
      )}

      {reopenCaseHasError && (
        <AlertComponent
          text={reopenCaseErrorObject?.error}
          className="warning"
          iconSize="25"
          setShowAlert={() => dispatch(setAlertReopenError(false))}
        />
      )}
    </>
  );
};
