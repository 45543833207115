import React from "react";
import ReactDOM from "react-dom/client";
//Add to the entry point of project makes webpack,
//pack the resets before all the other styles,
//so when an async component loads it doesnt
//import/include the resets again.
//This allow use antd css style withour breaking the app css
import "./styles/customs/antd.css";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import Relocation from "./Relocation";
import {datadogLogs} from "@datadog/browser-logs";
import {Provider} from "react-redux";
import {store} from "./store/store";
import "./index.scss";

const environment = FrontendConfiguration.environment();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

environment !== "local" &&
  datadogLogs.init({
    clientToken: FrontendConfiguration.getDataDogToken(),
    site: FrontendConfiguration.dataDogUrl(),
    forwardErrorsToLogs: true,
    env: FrontendConfiguration.datadogGlobalContext().env,
    sampleRate: 100,
  });
datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RELOCATION));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()} followRedirect={true}>
        <Provider store={store}>
          <Relocation />
        </Provider>
      </ImplicitFlow>
    </ThemeProvider>
  </React.StrictMode>
);
