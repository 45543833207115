import React from "react";
import {Image} from "@vacasa/react-components-lib";
import styles from "./Spinner.module.scss";

export const Spinner = () => {
  return (
    <div className={styles.spinner}>
      <Image.Spinner width={70} />
    </div>
  );
};
