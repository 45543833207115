import React from "react";
import {Formik, Field, Form} from "formik";
import {HeaderEdit} from "../index";
import {FormSubmissionSteps} from "../../../constants";
import {RootState, useAppDispatch} from "../../../store/store";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {useSelector} from "react-redux";
import {TypeOfFmStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./TypeOfFmStep.module.scss";
import cx from "classnames";

type TypeOfFmStepProps = {
  title: string;
  readOnly: boolean;
};

export const TypeOfFmStep = ({title, readOnly}: TypeOfFmStepProps) => {
  const dispatch = useAppDispatch();
  const {isMSFM} = useSelector((state: RootState) => state.formSubmission.formData);

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.TYPE_OF_FM} previousStep={FormSubmissionSteps.RESUME} />
      {!readOnly && (
        <p className={styles.noteText}>
          <strong>NOTE:</strong> This is the new form to be used to submit all FMs. Please read and fill out correctly.
        </p>
      )}
      <p className={generalStyles.question}>
        Is the arrival within 3 days? {!readOnly && " (MSFM is a yes)"} {!readOnly && <span className={generalStyles.obligatory}>*</span>}
      </p>
      <Formik
        initialValues={{
          isMSFM,
        }}
        onSubmit={(values) => {
          const currentStep = values.isMSFM === "yes" ? FormSubmissionSteps.URGENT_FM : FormSubmissionSteps.NON_URGENT_FM;
          dispatch(updateFormData(values));
          dispatch(
            updateStep({
              currentStep,
              previousStep: FormSubmissionSteps.TYPE_OF_FM,
            })
          );
          dispatch(refreshStepPath({currentStep, buttonAction: "next"}));
        }}
        validationSchema={TypeOfFmStepSchema}
      >
        {({errors, touched, handleChange, handleSubmit}) =>
          !readOnly ? (
            <Form onSubmit={handleSubmit}>
              <div className={generalStyles.radioOptions} role="group" aria-labelledby="my-radio-group">
                <div className={generalStyles.radioContainer}>
                  <Field type="radio" name="isMSFM" value="yes" onChange={handleChange} />
                  <span>Yes</span>
                </div>
                <div className={generalStyles.radioContainer}>
                  <Field type="radio" name="isMSFM" value="no" onChange={handleChange} />
                  <span>No</span>
                </div>
              </div>
              {touched.isMSFM && errors.isMSFM && <p className={generalStyles.validationError}>{errors.isMSFM}</p>}
              <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                Next
              </button>
            </Form>
          ) : (
            <span className={generalStyles.response}>{isMSFM}</span>
          )
        }
      </Formik>
    </div>
  );
};
