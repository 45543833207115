import React, {FC, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import styles from "../wrap-up-component/WrapUp.module.scss";
import localStyles from "./WrapUpOwnerBilling.module.scss";
import {updateBilling, setBillingGuestAmount, setBillingOwnerAmount, setBillingOwnerPercent} from "../../store/wrapUpSlice";
import {AlertComponent, CurrencyComponentV2, FinancesColumn} from "..";
import {OptionsAvailable, OptionsAvailableReservationBased, conclusionsNotAllowedToDislayAddCostItem} from "../../constants";
import {RelocationService} from "@common/services";
import {CircularProgress} from "@material-ui/core";
import cx from "classnames";
import {floatFixed} from "../../utils";
import {ReservationDetails} from "../../typing";
import {datadogLogs} from "@datadog/browser-logs";
import isNil from "lodash/isNil";
import {fetchRelocationCaseHistory} from "../../store/caseHistorySlice";

interface WrapUpOwnerBillingProps {
  fmConclusionSelected: number;
}

export const WrapUpOwnerBilling: FC<WrapUpOwnerBillingProps> = ({fmConclusionSelected}) => {
  const dispatch = useAppDispatch();
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const ownerPercentBilled = useSelector((state: RootState) => state.wrapUp.billing.ownerPercent) as any;
  const ownerAmountBilled = useSelector((state: RootState) => state.wrapUp.billing.ownerAmount) as any;
  const guestAmountBilled = useSelector((state: RootState) => state.wrapUp.billing.guestAmount) as any;
  const compUnit = useSelector((state: RootState) => state.wrapUp.compUnit) as any;

  // finances
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const snapshotOriginPreResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPre) as any;
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;
  const midStayFMTotals = useSelector((state: RootState) => state.wrapUp.finances.midStayFMTotals) as any;

  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const relocationRequest = useSelector((state: RootState) => state.caseProfile.relocationRequest) as any;

  const [displayNewReservationCost, setDisplayNewReservationCost] = useState<boolean>(true);
  const [financeDiff, setFinanceDiff] = useState<number>(0);
  const [updatingOwnerBilling, setUpdatingOwnerBilling] = useState<boolean>(false);
  const [updatingGuestBilling, setUpdatingGuestBilling] = useState<boolean>(false);
  const [updatingOwnerPercentBilled, setUpdatingOwnerPercentBilled] = useState<boolean>(false);
  const [ownerNote, setOwnerNote] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const isValidGuestAmountBilled = !isNil(guestAmountBilled);
  const isValidOwnerAmountBilled = !isNil(ownerAmountBilled);
  const displayGuestBilling = [RelocationService.RESOLUTION_FSC, RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT].includes(fmConclusionSelected);

  useEffect(() => {
    const hasConclusionNotAllowedToDisplayAddCostItem = conclusionsNotAllowedToDislayAddCostItem.includes(fmConclusionSelected);
    const validSplitStay =
      compUnit?.attributes?.relocation_case_comparable_unit_type_id &&
      compUnit?.attributes?.relocation_case_comparable_unit_type_id === OptionsAvailable.SPLIT_STAY;
    const validResolutionCancelledRefund =
      relocationCase?.attributes?.relocation_case_resolution_id &&
      relocationCase?.attributes?.relocation_case_resolution_id === RelocationService.RESOLUTION_CANCELLED_REFUND;
    const validResolutionFSC =
      relocationCase?.attributes?.relocation_case_resolution_id &&
      relocationCase?.attributes?.relocation_case_resolution_id === RelocationService.RESOLUTION_FSC;
    if (hasConclusionNotAllowedToDisplayAddCostItem || validSplitStay || validResolutionCancelledRefund || validResolutionFSC) {
      setDisplayNewReservationCost(false);
    } else {
      setDisplayNewReservationCost(true);
    }
  }, [fmConclusionSelected]);

  useEffect(() => {
    calculateFinanceDiff();
  }, []);

  useEffect(() => {
    calculateFinanceDiff();
  }, [
    ownerAmountBilled,
    guestAmountBilled,
    relocationCase,
    isMidStayFM,
    compUnit,
    snapshotOriginPreResData,
    destinationResData,
    currentOriginResData,
    midStayFMTotals,
  ]);

  const calculateNewResDiff = () => {
    if (Math.abs(financeDiff) < ownerAmountBilled + guestAmountBilled) return -1;
    else if (Math.abs(financeDiff) > ownerAmountBilled + guestAmountBilled) return 1;
    else return 0;
  };

  const setFinanceDiffOnWrappedCase = () => {
    switch (relocationCase?.attributes?.relocation_case_resolution_id) {
      case RelocationService.RESOLUTION_FSC:
      case RelocationService.RESOLUTION_CANCELLED_REFUND:
        isMidStayFM ? setFinanceDiff(relocationCase.attributes.finance_difference) : setFinanceDiff(floatFixed(snapshotOriginPreResData?.total));
        break;
      default:
        setFinanceDiff(relocationCase.attributes.finance_difference);
        break;
    }
  };

  const setFinanceDiffOnUnwrappedCase = () => {
    const comparableUnitType = compUnit?.attributes?.relocation_case_comparable_unit_type_id;
    if ([OptionsAvailable.OUTSIDE_BOOKING].includes(comparableUnitType)) {
      if (isMidStayFM)
        setFinanceDiff(floatFixed(currentOriginResData?.total + compUnit?.attributes?.external_amount - snapshotOriginPreResData?.total));
      else setFinanceDiff(floatFixed(compUnit?.attributes?.external_amount - snapshotOriginPreResData?.total));
    } else if (OptionsAvailableReservationBased.includes(comparableUnitType)) {
      if (isMidStayFM) setFinanceDiff(floatFixed(midStayFMTotals?.total - snapshotOriginPreResData?.total));
      else setFinanceDiff(floatFixed(destinationResData[0]?.total - snapshotOriginPreResData?.total));
    } else if (OptionsAvailable.SPLIT_STAY === comparableUnitType) {
      if (isMidStayFM) setFinanceDiff(floatFixed(midStayFMTotals?.total - snapshotOriginPreResData?.total));
      else
        setFinanceDiff(
          floatFixed(
            destinationResData.reduce((acc: number, curr: ReservationDetails) => floatFixed(acc + curr.total), 0) - snapshotOriginPreResData?.total
          )
        );
    } else {
      if (isMidStayFM) setFinanceDiff(floatFixed(currentOriginResData?.total - snapshotOriginPreResData?.total));
      else setFinanceDiff(floatFixed(snapshotOriginPreResData?.total));
    }
  };

  const calculateFinanceDiff = () => {
    !isCaseOpen
      ? setFinanceDiffOnWrappedCase() // if resolved we display stored diff
      : setFinanceDiffOnUnwrappedCase(); // otherwise calculate the diff amount
  };

  const showAlerts = (message: string, type: string) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const updateBillingV2 = async (alertFx: Function, successMessage: string, errorsMessage: string, logMessage: string) => {
    setShowAlert(false);
    try {
      alertFx(true);
      await dispatch(updateBilling());
      await dispatch(fetchRelocationCaseHistory(relocationCase.id));
      alertFx(false);
      showAlerts(successMessage, "success");
    } catch (error) {
      datadogLogs.logger.error(logMessage, {error, relocation_case_id: relocationCase.id});
      showAlerts(errorsMessage, "error");
      throw error;
    } finally {
      alertFx(false);
    }
  };

  const saveOwnerBilling = async () => {
    await updateBillingV2(
      setUpdatingOwnerBilling,
      "Success! Owner Amount Billed updated.",
      "Error! Owner Amount Billed cannot be updated.",
      "Update Owner Billing"
    );
  };

  const saveGuestBilling = async () => {
    await updateBillingV2(
      setUpdatingGuestBilling,
      "Success! Guest Amount Billed updated.",
      "Error! Guest Amount Billed cannot be updated.",
      "Update Guest Billing"
    );
  };

  const saveOwnerPercentBilled = async () => {
    await updateBillingV2(
      setUpdatingOwnerPercentBilled,
      "Success! Owner Percent Billed updated.",
      "Error! Owner Percent Billed cannot be updated.",
      "Update Owner Percent Billed"
    );
  };

  const handleOwnerPercentBilled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const urlRegEx = new RegExp("^([0-9]{1,2}|100)$");
    if (e.currentTarget.value === "") {
      dispatch(setBillingOwnerPercent(0));
      setOwnerNote("");
    } else if (urlRegEx.test(e.currentTarget.value)) {
      dispatch(setBillingOwnerPercent(parseInt(e.currentTarget.value)));
      if (parseFloat(e.currentTarget.value) > 0) setOwnerNote(e.currentTarget.value + "% updated by GX");
      else setOwnerNote("");
    }
  };

  const handleOwnerAmountBilled = (value: number | null) => {
    dispatch(setBillingOwnerAmount(value));
  };

  const handleGuestAmountBilled = (value: number | null) => {
    dispatch(setBillingGuestAmount(value));
  };

  return (
    <div className={styles.body}>
      <div className={styles.title}>Billing Breakdown</div>

      <div className={localStyles.financesTableTwoColumns}>
        <FinancesColumn
          rightMarginContent={true}
          header={"Amount"}
          rows={[
            {title: "RES", content: <span className={styles.financesInnerTableTextBold}>$ {Math.abs(financeDiff)} </span>},
            {title: "OWNER", content: <span className={styles.financesInnerTableText}>$ {Math.abs(ownerAmountBilled)}</span>},
            {title: "GUEST", content: <span className={styles.financesInnerTableText}>$ {Math.abs(guestAmountBilled)}</span>},
            {
              title: "VACASA",
              contentHighlighted: true,
              content: (
                <span
                  className={cx(
                    styles.financesInnerTableTextBold,
                    calculateNewResDiff() < 0 ? styles.positiveValue : calculateNewResDiff() === 0 ? "" : styles.negativeValue
                  )}
                >
                  $ {Math.abs(Math.abs(financeDiff) - Math.abs(ownerAmountBilled) - Math.abs(guestAmountBilled)).toFixed(2)}
                </span>
              ),
            },
          ]}
        />
        <FinancesColumn
          leftMarginContent={true}
          header={"Note"}
          showTitles={false}
          rows={[
            {
              title: "RES",
              content: <span className={styles.financesInnerTableText}>{financeDiff < 0 ? "Amount to be refunded to Guest" : "Cost of FM"}</span>,
            },
            {title: "OWNER", content: <span className={styles.financesInnerTableText}>{ownerNote}</span>},
            {title: "GUEST", content: <></>},
            {
              title: "VACASA",
              contentHighlighted: true,
              content: (
                <span className={styles.financesInnerTableText}>
                  {calculateNewResDiff() < 0 ? "Vacasa Gain " : calculateNewResDiff() === 0 ? "" : "Vacasa Loss "}
                </span>
              ),
            },
          ]}
        />
      </div>

      <hr className={styles.contentSeparatorHr} />
      {showAlert && <AlertComponent text={<>{alertMessage}</>} className={alertType} iconSize="25" setShowAlert={setShowAlert} />}
      <div className={styles.subTitle}>Owner Billing</div>
      <div className={styles.container}>
        <div className={styles.containerItem}>
          <div className={styles.divSeparator}>
            <span className={styles.containerItemTitle}>Amount billed to owner </span>
          </div>
          <div className={styles.containerItemDetail}>
            <div>
              <div className={styles.containerItemDetail}>
                <CurrencyComponentV2 initialValue={ownerAmountBilled} readOnly={false} onChange={handleOwnerAmountBilled} />
                {!isValidOwnerAmountBilled && <span className={styles.nonValid}> *required</span>}
                {!isCaseOpen && !updatingOwnerBilling && (
                  <span className={styles.contentSaveBtn} onClick={saveOwnerBilling}>
                    Save
                  </span>
                )}
                {updatingOwnerBilling ? <CircularProgress color="inherit" size={25} className={styles.savingProgressText} /> : null}
              </div>
              <div className={styles.containerItemDetailLabel}>
                General Relo Agents please submit via{" "}
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeSa-OxqiRLnenweKT1TIyTReettie6Y5oS9nInvw5q1hykFg/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.containerItemDetailLink}
                >
                  Owner billing form.
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerItem}>
          <div className={styles.divSeparator}>
            <span className={styles.containerItemTitle}>% billed to owner</span>
          </div>
          <div className={styles.containerItemDetail}>
            <div className={styles.containerItemDetailGrid}>
              <div className={styles.custom_input}>
                <div className={styles.custom_input_text_field}>
                  <input type="text" className={styles.custom_input_input} value={ownerPercentBilled} onChange={handleOwnerPercentBilled} />
                </div>
                <div className={styles.custom_input_decoration}>%</div>
              </div>
              {!updatingOwnerPercentBilled && (
                <span className={cx(styles.contentSaveBtn, styles.contentSaveBtnPercent)} onClick={saveOwnerPercentBilled}>
                  Save
                </span>
              )}
              {updatingOwnerPercentBilled ? <CircularProgress color="inherit" size={25} className={styles.savingProgressText} /> : null}
            </div>
            <div className={styles.containerItemDetailLabel}>Adjust this percentage as necessary.</div>
          </div>
          <div className={styles.containerItemDetail}>
            <div className={styles.containerItemDetailMessage}>
              Original request approved by:{" "}
              <span className={styles.bold}>{relocationRequest.attributes?.approved_by ? relocationRequest.attributes?.approved_by : "---"}</span>
            </div>
          </div>
        </div>
        {displayNewReservationCost ||
          (compUnit?.attributes?.relocation_case_comparable_unit_type_id === OptionsAvailable.OUTSIDE_BOOKING && (
            <div className={styles.containerItem}>
              <div className={styles.divSeparator}>
                <span className={styles.containerItemTitle}>Contract management fee</span>
              </div>
              <div className={styles.containerItemDetail}>30%</div>
            </div>
          ))}
      </div>
      {displayGuestBilling && (
        <>
          <div className={styles.subTitle}>Guest Billing</div>
          <div className={styles.container}>
            <div className={styles.containerItem}>
              <div className={styles.divSeparator}>
                <span className={styles.containerItemTitle}>Amount billed to Guest </span>
              </div>
              <div className={styles.containerItemDetail}>
                <div>
                  <div className={styles.containerItemDetail}>
                    <CurrencyComponentV2 initialValue={guestAmountBilled} readOnly={false} onChange={handleGuestAmountBilled} />
                    {!isValidGuestAmountBilled && <span className={styles.nonValid}> *required</span>}
                    {!isCaseOpen && !updatingGuestBilling && (
                      <span className={styles.contentSaveBtn} onClick={saveGuestBilling}>
                        Save
                      </span>
                    )}
                    {updatingGuestBilling ? <CircularProgress color="inherit" size={25} className={styles.savingProgressText} /> : null}
                  </div>
                  <div className={styles.containerItemDetailLabel}>If Guest paid additional for FM, enter above.</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
