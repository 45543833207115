// @ts-nocheck
import React from "react";
import "./index.scss";
import {HolidaysView, FormSubmissionView, CaseFinance, CaseProfile, CasesMine, CasesRank, CasesAll} from "./views";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {CaseContainer, FileUploader, WrapUp, QueueHeader} from "./components";
import {NAVIGATION_TABS, ROUTES} from "./constants";

const Relocation = (): JSX.Element => {
  return (
    <div className="relocation">
      <Router>
        <Routes>
          {/** Default Path redirect to Queue */}
          <Route path="/" element={<Navigate to="queue" />} />

          {/* Case Profile & WrapUp */}
          <Route path="case/:case_id" element={<CaseContainer />}>
            <Route path={NAVIGATION_TABS.INITIATION} element={<CaseProfile />} />
            <Route path={NAVIGATION_TABS.FILES} element={<FileUploader />} />
            <Route path={NAVIGATION_TABS.WRAPUP} element={<WrapUp />} />
          </Route>

          <Route path="case/:case_id/finances" element={<CaseFinance />} />

          {/* Queue: All, Mine, Ranking */}
          <Route path={ROUTES.QUEUE} element={<QueueHeader />}>
            <Route path={ROUTES.QUEUE_ALL_CASES} element={<CasesAll />} />
            <Route path={ROUTES.QUEUE_MY_CASES} element={<CasesMine />} />
            <Route path={ROUTES.QUEUE_CASES_BY_RANK} element={<CasesRank />} />
          </Route>

          <Route path={ROUTES.HOLIDAYS} element={<HolidaysView />} />
          <Route path={ROUTES.SUBMISSION_FORM} element={<FormSubmissionView title="FM Reporting - Field Ops to CX" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

export default Relocation;
