import dayjs from "dayjs";
import {FrontendConfiguration} from "@common/configuration";

// @ts-ignore
export const addDays = (dateString: Dayjs, days: number): Dayjs => {
    return dateString ? dayjs(dateString).add(days, "day") : null;
};

export const nowInDate = dayjs().format("YYYY-MM-DD");

export const isReservationInThePast = (checkout: string) => {
    return checkout ? checkout < nowInDate : null;
};

/**
 *
 * validate that extend date is greater than last night
 *
 * @param    string  $extendedEndDate  extended end date to be validated
 * @param    string  $lastNight The last night of the reservation
 * @return      boolean true if extended end date is greater than last night
 *
 */
export const validateExtendedEndDate = (extendedEndDate: string, lastNight: string): boolean => {
    return lastNight >= extendedEndDate;
};

/**
 * Checks if a string corresponds to a valid date.
 *
 * @param {string} date The date string to validate.
 * @returns {boolean} Returns true if the string corresponds to a valid date, false otherwise.
 */
export const isDateValid = (date: string): boolean => {
    const timestamp = Date.parse(date);
    return isNaN(timestamp);
};

/**
 * Generates the date in utc format from the Frontend configuration
 * @param Date newDate
 * @returns string
 */
export const parsedDate = (date: Date): string => {
    const parsedFollowUpDate = new dayjs.tz(date, FrontendConfiguration.getTimeZone());
    //Modify date components to match what user specified in the ui
    const forcedFollowUpDate = parsedFollowUpDate
        .set("date", date.getDate())
        .set("month", date.getMonth())
        .set("year", date.getFullYear())
        .set("hour", date.getHours())
        .set("minute", date.getMinutes());

    //Parsed Date back to UTC
    const parsedBackToUTC = forcedFollowUpDate.utc();
    const newDate: string = parsedBackToUTC.toISOString();
    return newDate;
};
