import React, {FC} from "react";
import styles from "./SingleCaseViewHeader.module.scss";
import {FrontendConfiguration} from "@common/configuration";
import {createSearchParams, useNavigate} from "react-router-dom";
import {BackSectionV3} from "../back-section-v3/BackSectionV3";
import {Col, Row} from "antd";
import {RelocationService} from "@common/services";
import {relocationPriorityDesc} from "../../constants";
import cx from "classnames";
import _ from "lodash";

// date Management
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {useEffect} from "react";
import {fetchRelocationCaseCount} from "../../store/caseProfileSlice";
import {getDateTimeZone} from "../../utils";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface PropTypes {
  relocationCase: any;
  reservation: any;
}
export const SingleCaseViewHeader: FC<PropTypes> = ({relocationCase, reservation}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const unitActiveCasesCount = useSelector((state: RootState) => state.caseProfile.unitActiveCasesCount);
  const isLoadingCaseCount = useSelector((state: RootState) => state.caseProfile.isLoadingCaseCount);

  // format data
  const unitCode = relocationCase?.attributes?.code?.split("-")[0];
  const legacyReservationId = relocationCase?.attributes?.legacy_reservation_id;
  const legacyUnitId = reservation?.attributes?.unit_id;
  const createdOn = getDateTimeZone(reservation?.attributes?.creation_date, "YYYY-MM-DD hh:mma z");
  const source = reservation?.attributes?.reservation_source_display_name;
  const sourceName = reservation?.attributes?.reservation_source_name?.replace(/_/g, " ")?.replace(/\b\w/g, (l: string) => l.toUpperCase());
  const checkin = dayjs(reservation?.attributes?.first_night)?.format("YYYY-MM-DD");
  const priority = _.filter(relocationPriorityDesc, {id: relocationCase?.attributes?.priority?.id})[0].desc;
  const status = relocationCase?.attributes?.relocation_case_status_id;

  useEffect(() => {
    dispatch(fetchRelocationCaseCount(unitCode));
  }, []);

  // See Priority Status
  let displayMessage,
    displayClass,
    displayClassSecondary = "";
  switch (status) {
    case RelocationService.STATUS_DONE:
      displayMessage = "COMPLETED";
      displayClass = styles.subTitleCompleted;
      displayClassSecondary = styles.titleCompleted;
      break;
    case RelocationService.STATUS_PENDING:
      displayMessage = "not initiated";
      displayClass = styles.title;
      displayClassSecondary = cx(styles.subTitle, styles.subTitleNotInitiated);
      break;
    case RelocationService.STATUS_IN_PROGRESS:
      displayMessage = "in progress";
      displayClass = styles.title;
      displayClassSecondary = cx(styles.subTitle, styles.subTitleInProgress);
      break;
  }

  const handleClickRedirect = () => {
    if (unitActiveCasesCount <= 0) return;
    navigate({pathname: "/queue", search: `?${createSearchParams({unitCode: unitCode})}`});
  };

  return (
    <>
      <div className={styles.caseHeader}>
        {/** Back Section && Priority Status */}
        <div>
          <BackSectionV3 text={""} status={0} />
          <Row>
            <Col span={23}>
              <h1>
                <span className={displayClass}>{priority}</span>
                {status === RelocationService.STATUS_DONE ? " " : " | "}
                <span className={displayClassSecondary}>{displayMessage}</span>
              </h1>
            </Col>
          </Row>
        </div>

        {/** Reservation Tags **/}
        <div className={styles.reservationHeader}>
          <div className={styles.reservationHeaderSubTitle}>Created on</div>
          <div className={styles.reservationHeaderText}>{createdOn}</div>
          <div className={styles.reservationHeaderSubTitle}>Source</div>
          <div className={styles.reservationHeaderText}>{source}</div>
          {source !== sourceName && (
            <>
              <div className={styles.reservationHeaderSubTitle}>Source Name</div>
              <div className={styles.reservationHeaderText}>{sourceName}</div>
            </>
          )}

          <div className={styles.reservationHeaderSubTitle}>Unit Code</div>
          <div className={styles.reservationHeaderText}>
            <a
              href={FrontendConfiguration.getAdminUrl() + `/admin/dashboard/units/index?UnitID=${legacyUnitId}`}
              className={styles.noTextDecoration}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.reservationHeaderLink}>{unitCode}</span>
            </a>

            {/* CASES COUNT */}
            <span
              className={styles.reservationHeaderLink}
              style={{cursor: unitActiveCasesCount > 0 ? "pointer" : "auto", width: "11%", paddingLeft: 5}}
              onClick={handleClickRedirect}
            >
              ({isLoadingCaseCount ? "..." : ` ${unitActiveCasesCount} active case${unitActiveCasesCount > 1 ? "s" : ""} `})
            </span>
          </div>
          <div className={styles.reservationHeaderSubTitle}>Res ID</div>
          <div className={styles.reservationHeaderText}>
            <a
              href={FrontendConfiguration.getAdminUrl() + `/admin/dashboard/reservation/${legacyReservationId}`}
              className={styles.noTextDecoration}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.reservationHeaderLink}>{legacyReservationId}</span>
            </a>
          </div>
          <div className={styles.reservationHeaderSubTitle}>Check in</div>
          <div className={styles.reservationHeaderText}>{checkin}</div>
        </div>
      </div>
    </>
  );
};

export default SingleCaseViewHeader;
