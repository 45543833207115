import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {FilesAttributesType, FileRequestType} from "@common/typing";
import {RelocationService} from "@common/services";
import type {UploadFile} from "antd/es/upload/interface";

export const getPresignedUrlForUploadFile = createAsyncThunk("fileUploader/getPresignedUrlForUploadFile", async (data: FileRequestType) => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.uploadFile(data.case_id, data.filename);
    return response.data;
});

export const getPresignedUrlForDownloadFile = createAsyncThunk("fileUploader/getPresignedUrlForUploadFile", async (data: FileRequestType) => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.downloadFile(data.case_id, data.filename);
    return response.data;
});

export const getUploadedFiles = createAsyncThunk("fileUploader/getUploadedFiles", async (caseId: number) => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.getUploadedFiles(caseId);
    return response.data;
});

export const uploadFile = createAsyncThunk("fileUploader/uploadFile", async (data: {pud: FilesAttributesType; file: UploadFile}) => {
    const putResponse = await fetch(data.pud.attributes.url, {
        method: "PUT",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        body: data.file as unknown as BodyInit,
    });
    return putResponse;
});

export interface FileUploaderSliceState {
    files: Array<FilesAttributesType>;
    uploading: boolean;
    loadingFiles: boolean;
}

const initialState: FileUploaderSliceState = {
    files: [],
    uploading: false,
    loadingFiles: false,
};

const fileUploaderSlice = createSlice({
    name: "fileUploader",
    initialState,
    reducers: {
        clearList: (state) => {
            state.files = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPresignedUrlForUploadFile.pending, (state, action) => {
                state.uploading = true;
            })
            .addCase(getPresignedUrlForUploadFile.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.lastUploadedFile = payload as FilesAttributesType;
                console.log("payload", payload);
                state.uploading = false;
            })
            .addCase(getPresignedUrlForUploadFile.rejected, (state, action) => {
                state.uploading = false;
            })
            .addCase(uploadFile.pending, (state, action) => {
                state.uploading = true;
            })
            .addCase(uploadFile.fulfilled, (state, {payload}) => {
                state.uploading = false;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.uploading = false;
            })
            .addCase(getUploadedFiles.pending, (state, action) => {
                state.loadingFiles = true;
            })
            .addCase(getUploadedFiles.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.files = payload;
            })
            .addCase(getUploadedFiles.rejected, (state, action) => {
                state.loadingFiles = false;
            });
    },
});

export const {clearList} = fileUploaderSlice.actions;

export default fileUploaderSlice.reducer;
