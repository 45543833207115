import React, {FC} from "react";
import {useSelector} from "react-redux";
import styles from "../wrap-up-component/WrapUp.module.scss";
import localStyles from "./WrapUpStayInHome.module.scss";
import {WrapUpGuestSatisfactionRefund} from "../index";
import {setGuestSatisfactionNote} from "../../store/wrapUpSlice";
import {RootState, useAppDispatch} from "../../store/store";

interface WrapUpStayInHomeProps {
  selectedOption: number;
}

export const WrapUpStayInHome: FC<WrapUpStayInHomeProps> = (props) => {
  const {selectedOption} = props;
  const dispatch = useAppDispatch();
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const guestSatisfactionNote = useSelector((state: RootState) => state.wrapUp.refund.note) as any;

  const handleNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setGuestSatisfactionNote(e.currentTarget.value));
  };

  return (
    <div className={localStyles.body}>
      <div className={styles.contentDiv}>
        <WrapUpGuestSatisfactionRefund selectedOption={selectedOption} />

        <div className={styles.contentSeparatorDiv}>
          <div className={styles.divSeparator}>
            <span className={styles.containerItemTitle}>Note</span>
          </div>
          {!isCaseOpen ? (
            <div className={styles.containerItemDetail}>
              <p className={styles.noEditText}>{guestSatisfactionNote}</p>
            </div>
          ) : (
            <div className={styles.containerItemDetail}>
              <textarea className={styles.contentTextArea} onChange={handleNote} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
