import React, {FC, useEffect} from "react";
import styles from "./CaseNotes.module.scss";
import {useState} from "react";
import {VerticalTimeline, FollowUp} from "../index";
import {MdPhoneForwarded} from "react-icons/md";
import {Spinner} from "..";
import _ from "lodash";
import {getLastHistoryActionNumber, getHistoryActionNumber, getDateTimeZone, parsedDate, getUserInfo} from "../../utils";
import {HistoryActionsOutboundContactDetails, HistoryActionsOutboundContact, CaseTaskNextStep, CaseHistoryActions, CaseTasks} from "../../constants";
import {RelocationCaseHistory} from "../../typing";
import {RelocationCaseHistoryAttributesType} from "@common/typing";
import {RelocationService} from "@common/services";
import {CircularProgress} from "@material-ui/core";
import {Checkbox} from "@vacasa/react-components-lib";
import {HoursMinutesComponent} from "../hourminutes-input/HoursMinutesComponent";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import cx from "classnames";
import dayjs from "dayjs";
import Editor from "rich-markdown-editor";
import {datadogLogs} from "@datadog/browser-logs";
import {addRelocationCaseHistory, fetchRelocationCaseHistory, initializeRelocationCase, followupRelocationCase} from "../../store/caseHistorySlice";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {fetchRelocationCase} from "../../store/caseProfileSlice";
import {
  RelocationCaseInitializationType,
  RelocationCaseInitializationAttributesType,
  RelocationCaseFollowupType,
  RelocationCaseFollowupAttributesType,
} from "@common/typing";
import {message} from "antd";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface CaseNotesProps {}

export const CaseNotes: FC<CaseNotesProps> = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const {isLoading, isCreatingCaseHistory, relocationCaseHistory} = useSelector((state: RootState) => state.caseHistory);
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;

  //Component states
  const [active, setActive] = useState(true);
  const [internalNote, setInternalNote] = useState("");
  const [internalNoteEmpty, setInternalNoteEmpty] = useState(true);
  const {email: userEmail} = getUserInfo();
  const [events, setEvents] = useState([] as any);
  const [adjustNextFollowUp, setAdjustNextFollowUp] = useState(false);
  const [nextAdjustedFollowUpDate, setNextAdjustedFollowUpDate] = useState<Date>(new Date());
  const [followUpHour, setFollowUpHour] = useState<string>("");
  const [enableAdjustFollowUpBtn, setEnableAdjustFollowUpBtn] = useState(false);
  const [updatedFollowUpDate, setUpdatedFollowUpDate] = useState<boolean>(false);
  const [isValidFollowupConfig, setIsValidFollowupConfig] = useState<boolean>(false);
  const stateInitial = {
    selection: {
      email: false,
      text: false,
      phone: false,
    },
    note: "",
  };
  const [state, setState] = useState(stateInitial);

  const changeActive = (val: any) => {
    setActive(val);
  };

  const outboundContactNew = {
    completed: false,
    adjustNextFollowUp: adjustNextFollowUp,
  };

  const getPastOutboundContacts = () => {
    const filtered = relocationCaseHistory.filter((cH: RelocationCaseHistory) =>
      HistoryActionsOutboundContact.includes(cH.attributes?.relocation_case_history_action_id)
    );
    const sorted = _.sortBy(filtered, ["attributes.created_at"]);
    return [
      ...sorted.map((cH: RelocationCaseHistory) => {
        const title =
          HistoryActionsOutboundContactDetails[cH.attributes?.relocation_case_history_action_id as keyof typeof HistoryActionsOutboundContactDetails]
            ?.displayName;
        const actionNumber = getHistoryActionNumber(cH, relocationCaseHistory);
        return {
          active: false,
          title: (
            <>
              {`${title}${actionNumber ? " " + actionNumber : ""}`} <span className={styles.titleCompleted}>(completed)</span>
            </>
          ),
          children: (
            <FollowUp
              followUp={{
                completed: true,
                adjustNextFollowUp: adjustNextFollowUp,
              }}
              state={{
                selection: {
                  email: cH.attributes?.meta?.email as boolean,
                  text: cH.attributes?.meta?.text as boolean,
                  phone: cH.attributes?.meta?.phone as boolean,
                },
                note: "",
              }}
              setState={setState}
            />
          ),
          clicked: false,
        };
      }),
    ];
  };

  const getCurrentOutboundContact = () => {
    const historyToPublish = CaseTaskNextStep[relocationCase.attributes?.relocation_case_task_id as keyof typeof CaseTaskNextStep]?.historyToPublish;
    const title = HistoryActionsOutboundContactDetails[historyToPublish as keyof typeof HistoryActionsOutboundContactDetails]?.displayName;
    const lastNumber = getLastHistoryActionNumber(historyToPublish, relocationCaseHistory);
    return {
      active: true,
      title: <>{`${title}${!_.isNil(lastNumber) ? " " + (lastNumber + 1) : ""}`}</>,
      children: (
        <FollowUp followUp={outboundContactNew} state={state} setState={setState} handleAddCaseHistory={handleRecordInitializationOrFollowUp} />
      ),
    };
  };

  const getOutboundContacts = () => [...getPastOutboundContacts(), getCurrentOutboundContact()];

  useEffect(() => {
    if (!_.isEmpty(relocationCase) && !isCreatingCaseHistory) setEvents(getOutboundContacts());
  }, [relocationCase, relocationCaseHistory, isCreatingCaseHistory, adjustNextFollowUp, state]);

  const handleChangeNote = (evt: string) => {
    setInternalNote(evt.trim());
  };

  //Check if notes are empty
  useEffect(() => {
    if (internalNote && internalNote !== "\\") setInternalNoteEmpty(false);
    else setInternalNoteEmpty(true);
  }, [internalNote]);

  const handleCreateInternalNote = async () => {
    const data = {
      relocation_case_id: relocationCase.id,
      relocation_case_history_action_id: CaseHistoryActions.GENERAL_NOTE,
      value: internalNote,
      created_by: userEmail,
    } as RelocationCaseHistoryAttributesType;
    await dispatch(addRelocationCaseHistory(data));
    setInternalNoteEmpty(true);
    changeActive(true);
    await dispatch(fetchRelocationCaseHistory(relocationCase.id));
  };

  const processNewFollowUpTime = (dateString: string, valid: boolean) => {
    if (valid) {
      let updatedSelectedDate = new Date(dateString);
      setFollowUpHour(updatedSelectedDate.getHours().toString());
      setNextAdjustedFollowUpDate(updatedSelectedDate);
    } else {
      setFollowUpHour("");
    }
  };

  /**
   * Perform Initiation or FollowUp on Relocation Case based on Next Step for the current Task.
   */
  const handleRecordInitializationOrFollowUp = async () => {
    if (!enableAdjustFollowUpBtn) return;

    setEnableAdjustFollowUpBtn(false);
    const caseTaskNextStep = CaseTaskNextStep[relocationCase.attributes?.relocation_case_task_id as keyof typeof CaseTaskNextStep];
    caseTaskNextStep.historyToPublish === CaseHistoryActions.INITIATE ? await addInitializationCase() : await addFollowUpCase();
    await dispatch(fetchRelocationCase(relocationCase.id));
    await dispatch(fetchRelocationCaseHistory(relocationCase.id));
    setEnableAdjustFollowUpBtn(true);
    setAdjustNextFollowUp(false);
    setUpdatedFollowUpDate(true);
    setState(stateInitial);
  };

  /**
   * Parse Date & Communication evidence and dispatch Initialize thunk reducer.
   */
  const addInitializationCase = async () => {
    const {email, text, phone} = state.selection;
    const payload: RelocationCaseInitializationAttributesType = {
      note: state.note,
      email: email,
      phone: phone,
      text: text,
      updated_by: userEmail,
    } as RelocationCaseInitializationAttributesType;

    if (adjustNextFollowUp) {
      const newFollowUpDate: string = parsedDate(nextAdjustedFollowUpDate);
      payload.next_followup = newFollowUpDate;
    }
    const data: RelocationCaseInitializationType = {
      id: relocationCase.id,
      payload: payload,
    } as RelocationCaseInitializationType;

    const response: any = await dispatch(initializeRelocationCase(data));
    _.isNil(response.payload) ? message.error("Error initializing case") : message.success(response.payload);
  };

  /**
   *  Parse Date & Communication evidence and dispatch Followup thunk reducer.
   */
  const addFollowUpCase = async () => {
    const {email, text, phone} = state.selection;
    const payload: RelocationCaseFollowupAttributesType = {
      note: state.note,
      email: email,
      phone: phone,
      text: text,
      updated_by: userEmail,
    } as RelocationCaseFollowupAttributesType;

    if (adjustNextFollowUp) {
      const newFollowUpDate: string = parsedDate(nextAdjustedFollowUpDate);
      payload.next_followup = newFollowUpDate;
    }

    const data: RelocationCaseFollowupType = {
      id: relocationCase.id,
      payload: payload,
    } as RelocationCaseFollowupType;

    const response: any = await dispatch(followupRelocationCase(data));
    _.isNil(response.payload) ? message.error("Error updating new follow up") : message.success(response.payload);
  };

  /**
   * check if the user has selected a follow up time and if the user has selected a follow up time
   * and if isValidFollowupConfig
   */
  useEffect(() => {
    if (adjustNextFollowUp && isValidFollowupConfig) setEnableAdjustFollowUpBtn(true);
    else if (!adjustNextFollowUp) setEnableAdjustFollowUpBtn(true);
    else setEnableAdjustFollowUpBtn(false);
  }, [adjustNextFollowUp, isValidFollowupConfig]);

  /**
   * check if the note and contact fields are completed
   */
  useEffect(() => {
    setIsValidFollowupConfig(
      (state.selection.email || state.selection.text || state.selection.phone) && state.note !== "" && state.note !== "\\" && followUpHour !== ""
    );
  }, [state, followUpHour]);

  return (
    <div className={styles.component}>
      <div className={styles.title}>Case Note</div>
      {_.isEmpty(relocationCase) || isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.linkSection}>
            <div className={active ? styles.linkActive : styles.linkInactive} style={{cursor: "pointer"}} onClick={() => changeActive(true)}>
              {" "}
              Outbound Contact
            </div>
            <div className={active ? styles.linkInactive : styles.linkActive} style={{cursor: "pointer"}} onClick={() => changeActive(false)}>
              {" "}
              Internal Note
            </div>
          </div>
          {active ? (
            <div className={styles.checkboxAdjust}>
              <VerticalTimeline events={events} />
              <div>
                <label>
                  <MdPhoneForwarded style={{verticalAlign: "middle", marginRight: "10px"}} />
                  <span className={styles.followUpDueText}>Follow up due</span>
                  <span className={styles.followUpDueTextInactive}>
                    {getDateTimeZone(relocationCase.attributes?.follow_up_date, "YYYY-MM-DD [at] H:mmA z")}
                  </span>
                  {updatedFollowUpDate && <span className={`${styles.followUpDueUpdated}`}>updated</span>}
                </label>
              </div>
              <div className={styles.checkboxAdjust}>
                <Checkbox
                  type="default"
                  checked={adjustNextFollowUp}
                  label="Adjust next follow-up"
                  onChange={() => setAdjustNextFollowUp(!adjustNextFollowUp)}
                />
                {adjustNextFollowUp && (
                  <>
                    <div className={styles.dateSelectorContainer}>
                      <div className={styles.timepickerContainer}>
                        <HoursMinutesComponent value={nextAdjustedFollowUpDate} onChange={processNewFollowUpTime} readOnly={false} />
                        <label className={styles.label}>(PT)</label>
                      </div>
                    </div>
                    <div>
                      {relocationCase.attributes?.relocation_case_status_id === RelocationService.STATUS_PENDING ? (
                        <button
                          className={`${styles.btnUpdateFollowUp} ${enableAdjustFollowUpBtn ? styles.btnAllowed : styles.btnDisabled}`}
                          onClick={handleRecordInitializationOrFollowUp}
                        >
                          Record Initation
                        </button>
                      ) : (
                        <button
                          className={`${styles.btnUpdateFollowUp} ${enableAdjustFollowUpBtn ? styles.btnAllowed : styles.btnDisabled}`}
                          onClick={handleRecordInitializationOrFollowUp}
                        >
                          Record follow up
                        </button>
                      )}

                      {isCreatingCaseHistory ? (
                        <div className={styles.searchingProgress}>
                          <CircularProgress color="inherit" size={25} />
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.divSection}>
                <Editor
                  placeholder="Start typing..."
                  className={cx(styles.internalTextArea, internalNoteEmpty ? styles.internalTextAreaInvalid : styles.internalTextAreaValid)}
                  onChange={(value: any) => handleChangeNote(value())}
                />
              </div>
              <div className={styles.btnBox}>
                <button
                  className={cx(styles.btn, internalNoteEmpty || isCreatingCaseHistory ? styles.btnDisabled : styles.btnAllowed)}
                  onClick={handleCreateInternalNote}
                  disabled={isCreatingCaseHistory || internalNoteEmpty}
                >
                  Save
                </button>
                {isCreatingCaseHistory ? (
                  <div className={styles.searchingProgress}>
                    <CircularProgress color="inherit" size={25} />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CaseNotes;
