import React from "react";
import styles from "./BackSectionV2.module.scss";
import {BsArrowLeftShort} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "antd";
import {SettingsMenu} from "../settings-menu/SettingsMenu";

export const BackSectionV2 = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <span className={styles.arrow} style={{cursor: "pointer", width: "11%"}} onClick={() => navigate(-1)}>
        <BsArrowLeftShort style={{verticalAlign: "middle"}} size={"1.5rem"} />
        Back
      </span>
      <Row>
        <Col span={12}>
          <h1 className={styles.title}>{props.text}</h1>
        </Col>
        <Col span={11} style={{textAlign: "right", paddingRight: "20px"}}>
          <SettingsMenu />
        </Col>
      </Row>
    </>
  );
};
