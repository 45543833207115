import React, {FC, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RootState, useAppDispatch} from "../../store/store";
import styles from "./WrapUp.module.scss";
import {CircularProgress} from "@material-ui/core";
import {
  WrapUpCancelAndFsc,
  WrapUpCancelAndRefund,
  WrapUpFMNoLongerNeeded,
  WrapUpStayInHome,
  WrapUpMoveToNewUnit,
  WrapUpAlerts,
  AlertComponent,
} from "..";
import {ADMIN_USERS, CaseHistoryActions, OptionsAvailable, WrapUpOptions} from "../../constants";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import {addRelocationCaseHistory, fetchRelocationCaseHistory} from "../../store/caseHistorySlice";
import {EventService, RelocationService} from "@common/services";
import {datadogLogs} from "@datadog/browser-logs";
import {EventSources, EventTypes} from "@common/utils";
import {RelocationCaseHistoryAttributesType} from "@common/typing";
import {RelocationCaseComparableUnit} from "../../typing";
import {
  cancelAndMarkCompUnitWithResAttached,
  cancelAndMarkSplitStayCompUnit,
  getReservationDetailsFromComparableUnit,
  getResolutions,
  getUserInfo,
} from "../../utils";
import {fetchRelocationCase} from "../../store/caseProfileSlice";
import {fetchCompUnits, fetchComparableUnit, fetchSplitStay} from "../../store/compUnitSlice";
import {
  reOpenCase,
  save,
  setAlertCancellation,
  setAlertCasHoldExist,
  setAlertData,
  setAlertReopenError,
  setAlertWrapUpCompleted,
  setAlertWrapUpReversed,
  setCleanAfterStay,
  setCompUnit,
  setDestinationResData,
  setIsMidStayFM,
  setGuestSatisfactionNote,
  setGuestSatisfactionAmount,
  resetAlerts,
} from "../../store/wrapUpSlice";
import {BsInfoCircle} from "react-icons/bs";

interface WrapUpProps {}

export const WrapUp: FC<WrapUpProps> = (props) => {
  // Local State
  const [allowWrapUp, setAllowWrapUp] = useState(true);
  const [selectedOption, setSelectedOption] = useState(0);
  const [disableOption, setDisableOption] = useState(false);

  const {email: userEmail} = getUserInfo();
  const [savingWrapUp, setSavingWrapUp] = useState<boolean>(false);
  const [reopeningWrapUp, setReopeningWrapUp] = useState<boolean>(false);
  const [cancellingReservation, setCancellingReservation] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const relocationRequest = useSelector((state: RootState) => state.caseProfile.relocationRequest) as any;
  const relocationCaseComparableUnits = useSelector((state: RootState) => state.compUnit.relocationCaseComparableUnits) as any;
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const isCaseResolved = useSelector((state: RootState) => state.caseProfile.isCaseResolved) as any;
  const originalResUnitId = useSelector((state: RootState) => state.wrapUp.alertsData.originalResUnitId);
  const compUnit = useSelector((state: RootState) => state.wrapUp.compUnit) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;
  const financesVerifiedByUser = useSelector((state: RootState) => state.wrapUp.financesVerifiedByUser) as any;
  const creatingHold = useSelector((state: RootState) => state.wrapUp.creatingHold) as any;

  /**
   * In case resolution is set(WrapUp is Done) we must disabled the options
   * and display the information related to the resolution
   */
  useEffect(() => {
    const displayWrapUp = async () => {
      dispatch(fetchCompUnits(relocationCase.id));
      if (!isCaseOpen && isCaseResolved) {
        setDisableOption(true);
        dispatch(setIsMidStayFM(!isNil(relocationCase.attributes?.mid_stay_forced_move) ? relocationCase.attributes?.mid_stay_forced_move : false));
        dispatch(setGuestSatisfactionNote(relocationCase.attributes?.wrapup_note));
        switch (relocationCase.attributes?.relocation_case_resolution_id) {
          case RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED:
            setSelectedOption(RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED);
            break;
          case RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT:
            const comparableUnit = await fetchComparableUnit(relocationCase.attributes?.selected_relocation_case_comparable_unit_id);
            dispatch(setCompUnit(comparableUnit));
            setSelectedOption(RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT);
            if (comparableUnit?.attributes.relocation_case_comparable_unit_type_id === OptionsAvailable.SPLIT_STAY) {
              const relocationCaseCompUnitSplitStays = await fetchSplitStay([comparableUnit.id]);
              dispatch(setDestinationResData(sortBy(relocationCaseCompUnitSplitStays, ["id"]).map(getReservationDetailsFromComparableUnit)));
            } else {
              dispatch(setDestinationResData([getReservationDetailsFromComparableUnit(comparableUnit)]));
            }

            setMoveToNewUnit();

            break;
          case RelocationService.RESOLUTION_FSC:
            setSelectedOption(RelocationService.RESOLUTION_FSC);
            break;
          case RelocationService.RESOLUTION_STAYED_IN_HOME:
            setSelectedOption(RelocationService.RESOLUTION_STAYED_IN_HOME);
            break;
          case RelocationService.RESOLUTION_CANCELLED_REFUND:
            setSelectedOption(RelocationService.RESOLUTION_CANCELLED_REFUND);
            break;
          default:
            break;
        }
      } else {
        if (compUnit?.id > 0) {
          setSelectedOption(RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT);
          setAllowWrapUp(relocationCase.attributes.relocation_case_status_id !== RelocationService.STATUS_PENDING);
        } else {
          setSelectedOption(0);
        }
      }
    };
    displayWrapUp();
  }, [relocationCase, relocationRequest]);

  /**
   * Set Move to New Unit as the Resolution
   */
  const setMoveToNewUnit = () => {
    setSelectedOption(RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT);
    dispatch(setGuestSatisfactionAmount(relocationCase?.attributes?.guest_refund_amount));
  };

  useEffect(() => {
    if (!isEmpty(relocationCase))
      dispatch(setCleanAfterStay(!isNil(relocationCase.attributes?.clean_after_stay) ? relocationCase.attributes?.clean_after_stay : false));
    if (originalResUnitId === 0 && !isEmpty(relocationRequest.attributes))
      dispatch(setAlertData({originalResUnitId: relocationRequest?.attributes?.legacy_unit_id}));
  }, []);

  // Wrap up store dependencies
  const isValidGuestSatisfactionAmount = useSelector((state: RootState) => state.wrapUp.refund.isValid) as any;
  const ownerAmountBilled = useSelector((state: RootState) => state.wrapUp.billing.ownerAmount) as any;
  const guestAwareness = useSelector((state: RootState) => state.wrapUp.guestAwareness) as any;

  const RESOLUTION_MOVED_TO_NEW_UNIT = "Move to new unit(s)";
  const RESOLUTION_CANCELLED_REFUND = "Cancel and refund";
  const RESOLUTION_FSC = "Cancel and FSC";
  const RESOLUTION_STAYED_IN_HOME = "Stay in home";
  const RESOLUTION_FM_NO_LONGER_NEEDED = "FM no longer needed";

  const WrapUpStatusAlert = [
    RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT,
    RelocationService.RESOLUTION_CANCELLED_REFUND,
    RelocationService.RESOLUTION_FSC,
  ];

  /**
   * Used to display the options available for the user to set a resolution in the case
   */
  const selectValues = [
    {id: 0, name: "Select "},
    {id: RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT, name: RESOLUTION_MOVED_TO_NEW_UNIT, disabled: true},
    {id: RelocationService.RESOLUTION_CANCELLED_REFUND, name: RESOLUTION_CANCELLED_REFUND},
    {id: RelocationService.RESOLUTION_FSC, name: RESOLUTION_FSC},
    {id: RelocationService.RESOLUTION_STAYED_IN_HOME, name: RESOLUTION_STAYED_IN_HOME},
    {id: RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED, name: RESOLUTION_FM_NO_LONGER_NEEDED},
  ];

  function displayWrapUpOption() {
    switch (selectedOption) {
      case WrapUpOptions.STAY_IN_HOME:
        return <WrapUpStayInHome selectedOption={selectedOption} />;
      case WrapUpOptions.FM_NO_LONGER_NEEDED:
        return <WrapUpFMNoLongerNeeded />;
      case WrapUpOptions.CANCEL_AND_REFUND:
        return <WrapUpCancelAndRefund />;
      case WrapUpOptions.CANCEL_AND_FSC:
        return <WrapUpCancelAndFsc />;
      case WrapUpOptions.MOVE_TO_NEW_UNIT:
        return <WrapUpMoveToNewUnit />;
      default:
        break;
    }
  }

  function validateSave() {
    switch (selectedOption) {
      case WrapUpOptions.STAY_IN_HOME:
        return isValidGuestSatisfactionAmount; // Validate if amount is valid
      case WrapUpOptions.FM_NO_LONGER_NEEDED:
        return !isNil(guestAwareness);
      case WrapUpOptions.CANCEL_AND_FSC:
      case WrapUpOptions.CANCEL_AND_REFUND:
      case WrapUpOptions.MOVE_TO_NEW_UNIT:
        return isValidGuestSatisfactionAmount && !isNil(ownerAmountBilled) && (isMidStayFM ? financesVerifiedByUser : true) && allowWrapUp;
      default:
        break;
    }
  }

  async function createWrapUpCompleteHistoryEntry() {
    const relocationCaseHistoryData = {
      relocation_case_id: relocationCase.id,
      relocation_case_history_action_id: CaseHistoryActions.COMPLETE,
      created_by: userEmail,
      meta: {
        relocation_case_resolution_id: getResolutions(selectedOption),
      },
    } as RelocationCaseHistoryAttributesType;
    await dispatch(addRelocationCaseHistory(relocationCaseHistoryData));
    datadogLogs.logger.info("Case Completed", {relocationCaseHistoryData});
  }

  /**
   * Gets unused reservations holds when wrap-up is saved
   */
  async function cancelUnusedReservationHolds(caseResolution: number, compUnitSelectedId: number = 0) {
    try {
      setCancellingReservation(true);

      const comparableUnitsFiltered: RelocationCaseComparableUnit[] = relocationCaseComparableUnits
        // Get Comparable Units that are not selected
        ?.filter((comparableUnit: RelocationCaseComparableUnit) => comparableUnit.id !== compUnitSelectedId)
        //Remove duplicates legacy reservation Id's to prevent error 422 from Reservation Service
        .filter((comparableUnit: RelocationCaseComparableUnit, index: number, self: any) =>
          !isNil(comparableUnit.attributes.legacy_reservation_id)
            ? self.findIndex(
                (compUnit: RelocationCaseComparableUnit) =>
                  comparableUnit.attributes.legacy_reservation_id === compUnit.attributes.legacy_reservation_id
              ) === index
            : true
        )
        //Filter Comparable Units that have different Legacy Reservation Id than Comparable Unit selected and is resolved as MOVED_TO_NEW_UNIT
        .filter((comparableUnit: RelocationCaseComparableUnit) => {
          if (caseResolution === RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT && !isNil(comparableUnit.attributes.legacy_reservation_id))
            return comparableUnit.attributes.legacy_reservation_id !== compUnit?.attributes.legacy_reservation_id;
          return true;
        });

      if (comparableUnitsFiltered?.length === 0) return;

      // Array for collect reservation ids that failed
      let failedCancelledIds: number[] = [];
      const addFailedIds = (ids: number[]) => (failedCancelledIds = [...failedCancelledIds, ...ids]);

      for (const comparableUnit of comparableUnitsFiltered) {
        try {
          if (!isNil(comparableUnit?.attributes?.legacy_reservation_id)) await cancelAndMarkCompUnitWithResAttached(comparableUnit, addFailedIds);
          else if (comparableUnit?.attributes?.relocation_case_comparable_unit_type_id === OptionsAvailable.SPLIT_STAY)
            await cancelAndMarkSplitStayCompUnit(comparableUnit, addFailedIds);
        } catch (error) {}
      }

      if (failedCancelledIds.length !== 0) {
        dispatch(setAlertData({cancellationFailedIds: failedCancelledIds}));
        dispatch(setAlertCancellation(true));
      }
    } catch (error) {
      datadogLogs.logger.error("Comparable Unit Canceled", {error, relocation_case_id: relocationCase.id});
    } finally {
      setCancellingReservation(false);
    }
  }

  /**
   * Handle save the wrap-up information
   * depending on the resolution
   * Trigger on Done Button
   */
  async function handleSave() {
    try {
      if (validateSave()) {
        setSavingWrapUp(true);
        await dispatch(save(selectedOption)).unwrap();
        switch (selectedOption) {
          case WrapUpOptions.STAY_IN_HOME:
            await cancelUnusedReservationHolds(RelocationService.RESOLUTION_STAYED_IN_HOME);
            break;
          case WrapUpOptions.FM_NO_LONGER_NEEDED:
            await cancelUnusedReservationHolds(RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED);
            break;
          case WrapUpOptions.CANCEL_AND_REFUND:
            await cancelUnusedReservationHolds(RelocationService.RESOLUTION_CANCELLED_REFUND);
            break;
          case WrapUpOptions.CANCEL_AND_FSC:
            await cancelUnusedReservationHolds(RelocationService.RESOLUTION_FSC);
            break;
          case WrapUpOptions.MOVE_TO_NEW_UNIT:
            await cancelUnusedReservationHolds(RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT, compUnit?.attributes?.id);
            break;
          default:
            break;
        }
        await createWrapUpCompleteHistoryEntry();
        await dispatch(fetchRelocationCase(relocationCase.id));
        await dispatch(fetchRelocationCaseHistory(relocationCase.id));
        EventService.dispatch(datadogLogs, {
          title: "Case WrapUp Success",
          message: `Case id: ${relocationCase.id} WrapUp Success`,
          type: EventTypes.CASE_WRAPUP,
          source: EventSources.UI,
          level: EventService.INFO_LEVEL,
          data: relocationCase,
        });

        window.scrollTo({top: 0, left: 0});
        dispatch(setAlertWrapUpCompleted(true));
        setDisableOption(true);
      }
    } catch (error) {
      datadogLogs.logger.error("Save WrapUp", {error, relocation_case_id: relocationCase.id});
    } finally {
      setSavingWrapUp(false);
    }
  }

  /**
   * Redirects to Finance Update
   */
  function handleUpdateFinances() {
    navigate({pathname: `/case/${relocationCase.id}/finances`});
  }

  /**
   * Handle Reopen a Case in case the WrapUp is Done
   */
  async function handleReopen() {
    try {
      dispatch(resetAlerts());
      setReopeningWrapUp(true);
      if (relocationCase.attributes.clean_after_stay && !isNil(relocationCase.attributes.clean_after_stay_legacy_reservation_id)) {
        dispatch(setAlertCasHoldExist(true));
        dispatch(setAlertData({casHoldId: relocationCase.attributes.clean_after_stay_legacy_reservation_id}));
      }
      await reOpenCase({id: relocationCase.id, userEmail: userEmail});
      await dispatch(fetchRelocationCase(relocationCase.id));
      await dispatch(fetchRelocationCaseHistory(relocationCase.id));
      dispatch(setAlertWrapUpReversed(true));
      dispatch(setAlertReopenError(false));
      setDisableOption(false);
    } catch (error: any) {
      dispatch(setAlertReopenError(true));
      dispatch(setAlertData({reopenCaseErrorObject: {status: error?.response?.data[0]?.status, error: error?.response?.data[0]?.detail?.error}}));
      datadogLogs.logger.error("Reopen Case", {error, relocation_case_id: relocationCase.id});
      throw error;
    } finally {
      setReopeningWrapUp(false);
    }
  }

  /**
   * Set states when the user selects an option
   * @param e
   */
  function handleShowOption(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOption(parseInt(e.currentTarget.value));
    dispatch(setCompUnit({}));
    if (WrapUpStatusAlert.includes(parseInt(e.currentTarget.value))) {
      setAllowWrapUp(relocationCase.attributes.relocation_case_status_id !== RelocationService.STATUS_PENDING);
    } else setAllowWrapUp(true);
  }

  return (
    <div>
      <WrapUpAlerts />

      {!allowWrapUp && (
        <AlertComponent
          text={<>To Wrap Up this case, it must first be initiated</>}
          className="warning"
          iconSize="25"
          setShowAlert={() => setAllowWrapUp(false)}
        />
      )}

      {/*
       * Main UI section, display a selector with the wrap-up options available,
       * and the corresponding dynamic section will be displayed,
       * based on the selected option
       */}
      <div className={styles.body}>
        <div className={styles.title}>Wrap-up Details</div>
        <div className={styles.container}>
          <div className={styles.containerItem}>
            <div className={styles.divSeparator}>
              <span className={styles.containerItemTitle}>FM Conclusion</span>
            </div>
            <div className={styles.containerItemDetail}>
              <select value={selectedOption} className={styles.selectBox} onChange={handleShowOption} disabled={disableOption}>
                {selectValues.map((option, index) => {
                  return (
                    <option value={option.id} key={index} disabled={option.disabled}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/** How to get to Move to a New Unit */}
            {!isCaseResolved && !compUnit && (
              <div className={styles.selectorInfo}>
                <BsInfoCircle />
                Move to a New Unit can only be accessed when selecting Comparable Unit.
              </div>
            )}
          </div>
        </div>
      </div>

      {displayWrapUpOption()}

      {/*
       * This section display a Done Button
       * will be displayed when the case is not resolved yet
       * and the user has selected an option,
       * allow to perform a wrap-up(meaning that the case is resolved)
       */}
      <div className={styles.doneDiv}>
        {isCaseOpen && (
          <>
            <button
              className={validateSave() && !savingWrapUp && !cancellingReservation && allowWrapUp ? styles.button : styles.buttonDisabled}
              onClick={handleSave}
              disabled={savingWrapUp && !allowWrapUp}
            >
              Done
            </button>
            {savingWrapUp ? (
              <div className={styles.savingProgress}>
                <CircularProgress color="inherit" size={25} />
                {creatingHold && <span className={styles.savingProgressText}>Please wait, we are trying to place a hold.</span>}
              </div>
            ) : null}
          </>
        )}

        {/*
         * This section display a Reopen Case Button,
         * will only be displayed if the case is resolved,
         * allows to reopen the case(meaning that the wrap-up data will be reset)
         */}
        {isCaseResolved && (
          <>
            <button className={styles.buttonLarge} onClick={handleReopen} disabled={savingWrapUp}>
              Re-open Case
            </button>
            {reopeningWrapUp ? (
              <div className={styles.savingProgress}>
                <CircularProgress color="inherit" size={25} />
                {reopeningWrapUp && <span className={styles.savingProgressText}>Please wait, Case Wrap-up is being reverted.</span>}
              </div>
            ) : null}

            {ADMIN_USERS.includes(userEmail) && (
              <button className={styles.buttonLargeSecondary} onClick={handleUpdateFinances} disabled={savingWrapUp} style={{marginLeft: 10}}>
                Update Finances
              </button>
            )}
          </>
        )}
        {cancellingReservation ? (
          <div className={styles.savingProgress}>
            <span className={styles.savingProgressText}>Cancelling unselected reservations...</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
