import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {closeValidateReservationFailedAlert} from "../../../store/formSubmissionSlice";
import {AlertComponent} from "../../alert/AlertComponent";

export const ValidateReservationError: FC = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const {validateReservationErrorMessage} = useSelector((state: RootState) => state.formSubmission);

  return (
    <AlertComponent
      className="error"
      text={<>{validateReservationErrorMessage}</>}
      setShowAlert={() => dispatch(closeValidateReservationFailedAlert())}
    />
  );
};
