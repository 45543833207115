import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {updateTaskFilter} from "../../store/queueSlice";
import styles from "./FilterTask.module.scss";
import dayjs from "dayjs";

interface IFilterTaskProps {
  task: {id: number; text: string; value: string};
  onClose: Function;
}

export const FilterTask: React.FunctionComponent<IFilterTaskProps> = (props) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useAppDispatch();
  const currentTask = useSelector((state: RootState) => state.queue.filters.task.value);
  const selectedTask = props.task.value;

  const handleSelectedTask = () => {
    dispatch(updateTaskFilter(selectedTask));
    setChecked(true);
    props.onClose();
  };

  useEffect(() => {
    if (currentTask === selectedTask) {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    if (currentTask !== selectedTask) {
      setChecked(false);
    }
  }, [currentTask]);

  return (
    <div className={styles.taskGrid} onClick={handleSelectedTask}>
      <div className={styles.dvRadio}>
        <input type="radio" name="rbt_task" value={props.task.value} className={styles.taskRadio} checked={checked} onChange={handleSelectedTask} />
      </div>
      <div>
        <label>{props.task.text}</label>
      </div>
    </div>
  );
};
