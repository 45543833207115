import React, {FC} from "react";
import {RelocationService} from "@common/services";
import {WrapUpFinances, WrapUpGuestSatisfactionRefund, WrapUpOwnerBilling, WrapUpFinalDetails} from "../index";

interface WrapUpCancelAndRefundProps {}

export const WrapUpCancelAndRefund: FC<WrapUpCancelAndRefundProps> = () => {
  return (
    <>
      <WrapUpFinances fmConclusionSelected={RelocationService.RESOLUTION_CANCELLED_REFUND} />
      <WrapUpOwnerBilling fmConclusionSelected={RelocationService.RESOLUTION_CANCELLED_REFUND} />
      <WrapUpGuestSatisfactionRefund selectedOption={RelocationService.RESOLUTION_CANCELLED_REFUND} />
      <WrapUpFinalDetails />
    </>
  );
};
