import React from "react";
import styles from "../../components/wrap-up-component/WrapUp.module.scss";
import cx from "classnames";
import _ from "lodash";
import {FinancesColumn, FinancesColumnProps} from "../../components";
import {FrontendConfiguration} from "@common/configuration";
import {ReservationDetails} from "../../typing";
import {floatFixed} from "../Reservation/reservation";

export type amountInnerType = {
  amount: number | null | "n/a";
  wrapper?: "+/-" | "(+/-)" | "=";
  bold?: boolean;
  highlighted?: boolean;
};

type carouselAmountType = {primaryAmount: amountInnerType; secondaryAmount: amountInnerType};

export type carouselDataType = {
  title: string;
  listingLink: {url?: string; urlText: string};
  resIdLink: {url?: string; urlText: string};
  rent: carouselAmountType;
  fees: carouselAmountType;
  tp: carouselAmountType;
  taxes: carouselAmountType;
  total: carouselAmountType;
};

export const getAmountWrapped = (amountData: amountInnerType) => {
  if (amountData.amount === "n/a") return amountData.wrapper === "(+/-)" ? "(n/a)" : "n/a";
  if (!_.isNil(amountData.amount)) {
    switch (amountData.wrapper) {
      case "=":
        return `=$${Math.abs(amountData.amount).toFixed(2)}`;
      case "+/-":
        return `${amountData.amount >= 0 ? "+" : "-"}$${Math.abs(amountData.amount).toFixed(2)}`;
      case "(+/-)":
        return `(${amountData.amount >= 0 ? "+" : "-"}$${Math.abs(amountData.amount).toFixed(2)})`;
      default:
        return `$${Math.abs(amountData.amount).toFixed(2)}`;
    }
  }
};

export function carouselDataToItems(data: carouselDataType[]): FinancesColumnProps[] {
  return data.map((e, index) => ({
    header: e.title,
    rows: [
      {title: "LISTING", content: urlFinancesContent(e.listingLink.urlText, e.listingLink.url)},
      {title: "RES ID", content: urlFinancesContent(e.resIdLink.urlText, e.resIdLink.url)},
      {title: "RENT", content: amountFinancesContent(e.rent.primaryAmount, e.rent.secondaryAmount)},
      {title: "FEES", content: amountFinancesContent(e.fees.primaryAmount, e.fees.secondaryAmount)},
      {title: "TP", content: amountFinancesContent(e.tp.primaryAmount, e.tp.secondaryAmount)},
      {title: "TAXES", content: amountFinancesContent(e.taxes.primaryAmount, e.taxes.secondaryAmount)},
      {title: "TOTAL", content: amountFinancesContent(e.total.primaryAmount, e.total.secondaryAmount)},
    ],
  }));
}

export const amountFinancesContent = (primaryAmount: amountInnerType, secondaryAmount?: amountInnerType) => (
  <div className={styles.containerAmounts}>
    <div>
      {primaryAmount &&
        (primaryAmount?.highlighted ? (
          <span
            className={cx(
              styles.financesInnerTableText,
              primaryAmount?.bold === true ? styles.bold : "",
              !_.isNil(primaryAmount?.amount) && primaryAmount?.amount > 0
                ? styles.negativeValue
                : !_.isNil(primaryAmount?.amount) && primaryAmount?.amount < 0
                ? styles.positiveValue
                : ""
            )}
          >
            {getAmountWrapped(primaryAmount)}
          </span>
        ) : (
          <span className={cx(styles.financesInnerTableText, primaryAmount?.bold === true ? styles.bold : "")}>
            {getAmountWrapped(primaryAmount)}
          </span>
        ))}
    </div>
    {secondaryAmount && (
      <div className={cx(styles.financesInnerTableTextAlt, secondaryAmount?.bold === true ? styles.bold : "")}>
        {secondaryAmount?.highlighted ? (
          <span
            className={
              !_.isNil(secondaryAmount?.amount) && secondaryAmount?.amount > 0
                ? styles.negativeValue
                : !_.isNil(secondaryAmount?.amount) && secondaryAmount?.amount < 0
                ? styles.positiveValue
                : ""
            }
          >
            {getAmountWrapped(secondaryAmount)}
          </span>
        ) : (
          getAmountWrapped(secondaryAmount)
        )}
      </div>
    )}
  </div>
);

export const urlFinancesContent = (urlText: string, url?: string) => (
  <>
    {!_.isNil(url) ? (
      <a href={url} target="_blank" rel="noopener noreferrer" className={styles.financesInnerTableLink}>
        {urlText}
      </a>
    ) : (
      <>{urlText}</>
    )}
  </>
);

export const originalFinancesTable = (snapshotOriginPreResData: any, wideBox: boolean) => {
  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const {url} = FrontendConfiguration.adminConfig();

  return (
    <FinancesColumn
      rightMarginContent={true}
      header={"Original Res"}
      wideBox={wideBox}
      rows={[
        {
          title: "LISTING",
          content: urlFinancesContent(snapshotOriginPreResData?.unitName, `${urlVacasaCom}/unit/${snapshotOriginPreResData?.unitId}`),
        },
        {
          title: "RES ID",
          content: urlFinancesContent(snapshotOriginPreResData?.resId, `${url}/admin/dashboard/reservation/${snapshotOriginPreResData?.resId}`),
        },
        {title: "RENT", content: amountFinancesContent({amount: snapshotOriginPreResData?.rent})},
        {title: "FEES", content: amountFinancesContent({amount: snapshotOriginPreResData?.fees})},
        {title: "TP", content: amountFinancesContent({amount: snapshotOriginPreResData?.tp})},
        {title: "TAXES", content: amountFinancesContent({amount: snapshotOriginPreResData?.taxes})},
        {title: "TOTAL", content: amountFinancesContent({amount: snapshotOriginPreResData?.total})},
      ]}
    />
  );
};

export const sumFinances = (key: keyof ReservationDetails, finances: ReservationDetails[]) => {
  return finances.reduce((acc, curr) => floatFixed(acc + Number(curr[key])), 0);
};
