import React, {FC} from "react";
import {RelocationService} from "@common/services";
import {WrapUpGuestSatisfactionRefund, WrapUpOwnerBilling, WrapUpFinalDetails, WrapUpFinances} from "../index";

interface WrapUpMoveToNewUnitProps {}

export const WrapUpMoveToNewUnit: FC<WrapUpMoveToNewUnitProps> = () => {
  return (
    <>
      <WrapUpFinances fmConclusionSelected={RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT} />
      <WrapUpOwnerBilling fmConclusionSelected={RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT} />
      <WrapUpGuestSatisfactionRefund selectedOption={RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT} />
      <WrapUpFinalDetails />
    </>
  );
};
