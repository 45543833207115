import React, {useState, useEffect} from "react";
import {BsChevronRight} from "react-icons/bs";
import {SearchBar, CountTimer} from "..";
import {RelocationCase} from "../../typing";
import {
  calculateHoursDaysAndMonthsToCheckin,
  getCheckinInUnitTimeZone,
  CHECKIN_DEFAULT_TZ,
  getDateTimeZone,
  isCaseResolved,
  getResolutionName,
} from "../../utils";
import {Badge, Tooltip} from "@vacasa/react-components-lib";
import {CaseTasks} from "../../constants";
import Skeleton from "../skeleton/Skeleton";
import styles from "./QueueTableItem.module.scss";
import cx from "classnames";
import isNil from "lodash/isNil";
import findIndex from "lodash/findIndex";
import {RelocationService} from "@common/services";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {emitFetchMyCasesCount, postCaseAssignment, postCaseUnassingment} from "../../store/queueSlice";
import {fetchQtyFollowUpsPerCase} from "../../store/caseHistorySlice";
import {addCasesSelection, removeCasesSelection} from "../../store/bulkSelectionSlice";
import {Checkbox} from "@material-ui/core";
import {getPriorityClass} from "../../utils";

interface QueueTableItemProps {
  item: RelocationCase;
  handleUpdate: any;
}
export const QueueTableItem = ({item, handleUpdate}: QueueTableItemProps): JSX.Element => {
  // Component State
  const [followUpCount, setFollowUpCount] = useState<number | unknown>(0);
  const [isPastCheckin, setIsPastCheckin] = useState(false);
  const [value, setValue] = useState<string>("");
  const [isSearchable, setIsSearchable] = useState(false);

  // Redux State
  const dispatch = useAppDispatch();
  const caseResolutions = useSelector((state: RootState) => state.queue.lookups.resolutions);
  const caseTasks = useSelector((state: RootState) => state.queue.lookups.tasks);
  const isBulkResolveAction = useSelector((state: RootState) => state.queue.header.actions.bulkResolve.isVisible);
  const isBulkAssignAction = useSelector((state: RootState) => state.queue.header.actions.bulkAssign.isVisible);
  const isBulkAction = isBulkResolveAction || isBulkAssignAction;
  const isLoadingQtyFollowUps = useSelector((state: RootState) => state.caseHistory.isLoadingQtyFollowUps);
  const casesSelected = useSelector((state: RootState) => state.bulkSelection.casesSelected);

  /** HELPERS */
  const getTaskPrefix = (id: number) => {
    const task: any = caseTasks.find((task: any) => task.id === id);
    if (task?.attributes?.name) return task.attributes.name.split("|")[0];
    return "";
  };
  const calculateTimeToCheckin = (checkin: string, timezone: string) => {
    return calculateHoursDaysAndMonthsToCheckin(checkin, timezone);
  };

  // Non State Flags && Data
  const isCaseFollowUp = item.attributes.relocation_case_task_id === CaseTasks.FOLLOW_UP;
  const taskName = getTaskPrefix(item.attributes.relocation_case_task_id);
  const resolutionName = getResolutionName(item.attributes.relocation_case_resolution_id, caseResolutions);

  // Count the number of followUps
  // TODO: add cancellation
  useEffect(() => {
    (async () => {
      if (!isCaseFollowUp) return;
      const total = await dispatch(fetchQtyFollowUpsPerCase(item.id));
      setFollowUpCount(total.payload);

      // check checkin
      if ([RelocationService.PRIORITY_MSFMS, RelocationService.PRIORITY_LTR_MSFMS].includes(item.attributes.priority.rank)) {
        setIsPastCheckin(true);
      }
    })();
  }, []);

  const handleClickSearchable = async () => {
    setIsSearchable(true);
    if (!item.attributes.assigned_to) {
      setValue("");
    } else {
      setValue(item.attributes.assigned_to);
    }
  };

  /** Assigns User to a Case */
  const handleUpdateCase = async (email: string) => {
    if (!email) return;
    await dispatch(
      postCaseAssignment({
        assignedTo: email,
        relocationCaseId: item.id,
      })
    );
    dispatch(emitFetchMyCasesCount());
    handleUpdate();
  };

  const handleUnassignCase = async () => {
    if (value === "") return;
    await dispatch(postCaseUnassingment({relocationCaseId: item.id}));
    dispatch(emitFetchMyCasesCount());
    handleUpdate();
  };

  /**
   * Check if the case corresponds to an ESp source and its priority is Holiday or +90 days. 
   * In that case it returns true and will display the "ESP" tag along with the priority tag.
   * @returns boolean
   */
  const displayESPBadge= () => {
    // Define constants for badge priorities
    const PRIORITY_HOLIDAY = RelocationService.PRIORITY_HOLIDAY;
    const PRIORITY_MORE_90_DAYS = RelocationService.PRIORITY_MORE_90_DAYS;
  
    // Extract the priority ID from case attributes
    const casePriorityId = item.attributes?.priority?.id;
  
    // Check if because the priority ID of the case is required to display the ESP badge
    const displayBecausePriority = [PRIORITY_HOLIDAY, PRIORITY_MORE_90_DAYS].includes(casePriorityId);
  
    // Check if the item has the is_ltr property
    const hasLTRProperty = item.attributes?.is_ltr;
  
    // Determine if the ESP Badge should be displayed
    const showBadge = displayBecausePriority && hasLTRProperty;
  
    return showBadge;
  };

  const displayHighValueBadge= () => {
    return item.attributes?.tags?.includes("HIGH_VALUE");
  }

  function handleSelectionCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const selectionCase = {
      id: item.id,
      legacy_reservation_id: item.attributes.legacy_reservation_id,
      priority: item.attributes.priority,
    };
    if (e.currentTarget.checked) dispatch(addCasesSelection([selectionCase]));
    else dispatch(removeCasesSelection([selectionCase]));
  }

  function isCaseSelected(id: number) {
    return findIndex(casesSelected, {id}) !== -1;
  }

  return (
    <div className={cx(styles.tableItem)}>
      <div className={styles.inputCheckBox}>
        <Checkbox
          className={styles.inputCheck}
          color={"primary"}
          onChange={handleSelectionCheck}
          checked={isCaseSelected(item.id)}
          style={{visibility: isBulkAction ? "visible" : "hidden"}}
        />
      </div>

      {/** Priority Badge **/}
      <div className={styles.itemTask}>
        <Badge
          text={item.attributes?.priority?.name}
          variant={"error"}
          outline={false}
          disabled={false}
          isPill={true}
          closeButton={false}
          customClass={cx(styles.customClassBadge, styles[getPriorityClass(item.attributes.priority.rank)])}
        />
        {displayESPBadge() && (
          <Badge
            variant={"error"}
            text={"ESP"}
            outline={false}
            disabled={false}
            isPill={true}
            closeButton={false}
            customClass={cx(styles.customClassBadge, styles.isLTR)}
          />
        )}
        {displayHighValueBadge() && (
          <Badge
            variant={"error"}
            text={item.attributes?.reservation_amount ? item.attributes?.reservation_amount : "$$$"}
            outline={false}
            disabled={false}
            isPill={true}
            closeButton={false}
            customClass={cx(styles.customClassBadge, styles.isTAG)}
          />
        )}
        <Tooltip
          message={`Unit tz: ${isNil(item.attributes?.timezone) ? CHECKIN_DEFAULT_TZ : item.attributes?.timezone}, 
            Checkin in tz ${getCheckinInUnitTimeZone(item.attributes?.checkin, item.attributes?.timezone)},
            Checkin: ${item.attributes?.checkin}`}
        >
          <span className={cx(styles.timeDisplay, styles.helpIndicator, isPastCheckin ? styles.timeDisplayWarning : null)}>
            {" " + calculateTimeToCheckin(item.attributes?.checkin, item.attributes?.timezone)}
          </span>
        </Tooltip>
      </div>

      {/** Unit, Reservation, Created At */}
      <div className={styles.item}>{item.attributes?.code.split("-")[0]}</div>
      <div className={styles.item}>{item.attributes?.legacy_reservation_id}</div>
      <div className={styles.item}>{getDateTimeZone(item.attributes?.created_at)}</div>

      {/** Task & Follow Up Count  **/}
      <div className={styles.itemTask}>
        {isCaseResolved(item) ? (
          resolutionName
        ) : isCaseFollowUp ? (
          item.attributes.follow_up_date ? (
            <>
              {taskName}
              {followUpCount ? followUpCount : isLoadingQtyFollowUps && <Skeleton elements={["smallText"]} />} |{" "}
              <CountTimer date={item.attributes?.follow_up_date} />
            </>
          ) : (
            <span className={styles.noDate}> | Without date</span>
          )
        ) : (
          taskName
        )}
      </div>

      {/** Assigned To */}
      <div className={styles.itemWithFilter}>
        <>
          {isSearchable ? (
            <SearchBar
              placeholder="Search Agents"
              setIsSearchable={setIsSearchable}
              handleOnChange={handleUpdateCase}
              handleOnCloseValue={handleUnassignCase}
              value={value}
              onSearchChange={setValue}
            />
          ) : (
            <span className={styles.assignedTo} onClick={handleClickSearchable}>
              {!!item.attributes.assigned_to ? item.attributes.assigned_to : "───"}
            </span>
          )}
        </>
      </div>

      {/** Go To Case Link **/}
      <div className={styles.lastItem}>
        <a onClick={() => window.open(window.location.origin + `/case/${item.id}`, "_blank")} className={styles.arrowLink}>
          <BsChevronRight />
        </a>
      </div>
    </div>
  );
};
