import {useSearchParams} from "react-router-dom";
import {defaultFilters} from "../constants";

export const useQueueSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    // add Query Params based on current filters and drop if they are in the default value
    // we try to keep the urls clean
    const setSearchParamsSync = (value: any, key: any) => {
        // @ts-ignore
        if (value === defaultFilters[key]) {
            searchParams.delete(key);
            setSearchParams(searchParams);
        } else {
            searchParams.set(key, value);
            setSearchParams(searchParams);
        }
    };

    const pageNumber = +(searchParams.get("pageNumber") || 0) || defaultFilters.pageNumber;
    const pageSize = +(searchParams.get("pageSize") || 0) || defaultFilters.pageSize;
    const assignedTo = searchParams.get("assignedTo") || defaultFilters.assignedTo;
    const unitCode = searchParams.get("unitCode") || defaultFilters.unitCode;
    const status = searchParams.get("status") || defaultFilters.status;
    const group = searchParams.get("group") || defaultFilters.group;
    const priority = searchParams.get("priority") || defaultFilters.priority;
    const unassigned = +(searchParams.get("unassigned") || 0) || defaultFilters.unassigned;
    const createdAt = searchParams.get("createdAt") || defaultFilters.createdAt;
    const isLatam = +(searchParams.get("isLatam") || 0) || defaultFilters.isLatam;
    const task = searchParams.get("task") || defaultFilters.task;

    const isQueryFilters = [
        pageNumber !== defaultFilters.pageNumber,
        pageSize !== defaultFilters.pageSize,
        assignedTo !== defaultFilters.assignedTo,
        unitCode !== defaultFilters.unitCode,
        status !== defaultFilters.status,
        priority !== defaultFilters.priority,
        group !== defaultFilters.group,
        unassigned !== defaultFilters.unassigned,
        createdAt !== defaultFilters.createdAt,
        isLatam !== defaultFilters.isLatam,
        task != defaultFilters.task,
    ].some(Boolean);

    return {
        isQueryFilters,
        pageNumber,
        pageSize,
        assignedTo,
        unitCode,
        status,
        priority,
        group,
        unassigned,
        createdAt,
        isLatam,
        task,
        setSearchParamsSync,
    };
};
