import React, {useEffect, useState} from "react";
import styles from "./FilterAssign.module.scss";
import useDebounce from "../../hooks/useDebounce";
import {RootState, useAppDispatch} from "../../store/store";
import {updateAssignedToFilter, updatePaginationFilter} from "../../store/queueSlice";
import {useSelector} from "react-redux";

interface IFilterSearchProps {
  placeholder?: string;
  filterName: string;
}

export const FilterAssign: React.FunctionComponent<IFilterSearchProps> = ({placeholder, filterName}): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentAssignedTo = useSelector((state: RootState) => state.queue.filters.assignedTo.value);
  const [internalSearch, setInternalSearch] = useState<any>(currentAssignedTo);
  const debouncedSearch = useDebounce(internalSearch, 500);

  useEffect(() => {
    if (debouncedSearch === null) return;
    dispatch(updatePaginationFilter({pageNumber: 1}));
    dispatch(updateAssignedToFilter(internalSearch));
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalSearch(e.target.value);
  };

  return (
    <>
      <input type="search" value={internalSearch} className={styles.inputSearch} placeholder={placeholder} onChange={handleChange} />
    </>
  );
};
