import {RelocationRequestReservationType, RelocationRequestUnitType} from "@common/typing";
import {requestType} from "../../constants";
import {FormDataType} from "../../typing/FormSubmission";
import {getUserInfo} from "../../utils";

const userInfo = getUserInfo();

/**
 * Logic to calculate owner billed percentage
 *
 * @param billed
 * @param billedPercentage
 * @return number
 */
export const ownerBilled = (billed: string, billedPercentage = ""): number | null => {
    return billed.includes("vacasa-billed")
        ? 0
        : billed.includes("owner-billed")
        ? 100
        : !isNaN(parseInt(billedPercentage))
        ? parseInt(billedPercentage)
        : null;
};

/**
 * Logic to determinate if clean after stay is needed
 *
 * @param cleanAfterStay
 * @return boolean
 */
export const isCleanAfterStayNeeded = (cleanAfterStay: string): boolean => {
    return cleanAfterStay.includes("owner") || cleanAfterStay.includes("vacasa") ? true : false;
};

/**
 * Get clean after stay owner billed for the relocation case
 *
 * @param cleanAfterStay
 * @return boolean
 */
export const isCleanAfterStayOwnerBilledNeeded = (cleanAfterStay: string): boolean | null => {
    if (cleanAfterStay.trim() === "no" || cleanAfterStay.trim() === "") return null;
    return cleanAfterStay.includes("owner");
};

/**
 * Maps a relocation request
 *
 * @param data
 * @param type Type of Relocation Request 1 = Reservation | 2 = Unit
 */
export const requestMapper = (data: FormDataType, type: string) => {
    return parseInt(type) === requestType.unit
        ? ({
              legacy_unit_code: data?.legacyUnitCode,
              relocation_request_reason_id: parseInt(data?.relocationRequestReasonId),
              ...(data.extraNotes !== "" && {reason_notes: data?.extraNotes}),
              ...(data.unitCandidates !== "" && {unit_candidates: data?.unitCandidates}),
              clean_after_stay: isCleanAfterStayNeeded(data?.cleanAfterStay),
              owner_billed: ownerBilled(data?.billed, data?.billedPercentage),
              start_date: data.startDate,
              end_date: data.endDate,
              ...(data.extendedEndDate !== "" && {extended_end_date: data?.extendedEndDate}),
              ...(data.approvedBy !== "" && {approved_by: data?.approvedBy}),
              created_by: userInfo?.email,
          } as RelocationRequestUnitType)
        : ({
              legacy_reservation_id: parseInt(data?.legacyReservationId),
              relocation_request_reason_id: parseInt(data?.relocationRequestReasonId),
              ...(data.extraNotes !== "" && {reason_notes: data?.extraNotes}),
              ...(data.unitCandidates !== "" && {unit_candidates: data?.unitCandidates}),
              clean_after_stay: isCleanAfterStayNeeded(data?.cleanAfterStay),
              clean_after_stay_owner_billed: isCleanAfterStayOwnerBilledNeeded(data?.cleanAfterStay),
              owner_billed: ownerBilled(data?.billed, data?.billedPercentage),
              ...(data.extendedEndDate !== "" && {extended_end_date: data?.extendedEndDate}),
              ...(data.approvedBy !== "" && {approved_by: data?.approvedBy}),
              created_by: userInfo?.email,
          } as RelocationRequestReservationType);
};
