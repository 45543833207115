import React, {useEffect} from "react";
import {FormSubmissionSteps} from "../../../constants";
import {
  TypeOfFmStep,
  UrgentFmStep,
  NonUrgentFmStep,
  BillingStep,
  ResIdStep,
  UnitCodeStep,
  UnavailableDatesStep,
  OwnerBilledStep,
  VacasaBilledStep,
  SplitBilledStep,
  ResumeStep,
} from "../index";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {fetchRequestReasons} from "../../../store/formSubmissionSlice";

export const FormSubmission = () => {
  const formStep = useSelector((state: RootState) => state.formSubmission.currentStep);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRequestReasons());
  }, []);

  const pageToDisplay = () => {
    switch (formStep) {
      case FormSubmissionSteps.TYPE_OF_FM:
        return <TypeOfFmStep title="Type of FM" readOnly={false} />;
      case FormSubmissionSteps.URGENT_FM:
        return <UrgentFmStep title="Urgent FMs" readOnly={false} />;
      case FormSubmissionSteps.NON_URGENT_FM:
        return <NonUrgentFmStep title="Non-Urgent FM" readOnly={false} />;
      case FormSubmissionSteps.BILLING:
        return <BillingStep title="Billing" readOnly={false} />;
      case FormSubmissionSteps.RES_ID:
        return <ResIdStep title="Res ID" readOnly={false} />;
      case FormSubmissionSteps.UNIT_CODES:
        return <UnitCodeStep title="Unit Codes" readOnly={false} />;
      case FormSubmissionSteps.UNAVAILABLE_DATES:
        return <UnavailableDatesStep title="Unavailable Dates" readOnly={false} />;
      case FormSubmissionSteps.OWNER_BILLED:
        return <OwnerBilledStep title="Complete" />;
      case FormSubmissionSteps.VACASA_BILLED:
        return <VacasaBilledStep title="Approval" readOnly={false} />;
      case FormSubmissionSteps.SPLIT_BILLED:
        return <SplitBilledStep title="Percentage Billed" readOnly={false} />;
      case FormSubmissionSteps.RESUME:
        return <ResumeStep />;
      default:
        break;
    }
  };

  return <>{pageToDisplay()}</>;
};
