import {FrontendConfiguration} from "@common/configuration";
import React, {FC, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {BsPencilSquare, BsTrash, BsCheck, BsInfoCircle} from "react-icons/bs";
import {OptionsAvailable, SPLITSTAY_UNITS_LIMIT, TypesOfBooking} from "../../constants";
import {fetchReservationByLegacyId, fetchUnitByLegacyId, updateSelectedCompUnitOnCase} from "../../store/caseProfileSlice";
import {
  updateCompUnit,
  updateSplitStay,
  createCompUnit,
  deleteCompUnit,
  createCompUnitWithSplitStays,
  updateCompUnitWithSplitStay,
  deleteCompUnitWithSplitStay,
  hideUnitAvailableAlert,
  showUnitAvailableAlert,
} from "../../store/compUnitSlice";
import {setCompUnit, setDestinationResData} from "../../store/wrapUpSlice";
import {RelocationCaseComparableUnitType, ReservationDetails} from "../../typing";
import {Regex, mailToName} from "@common/utils";
import {CircularProgress} from "@material-ui/core";
import {SearchBar, SplitStay, SplitStayItem} from "../index";
import {Spinner} from "..";
import _ from "lodash";
import styles from "./UnitOption.module.scss";
import cx from "classnames";
import {datadogLogs} from "@datadog/browser-logs";
import {
  GetResponse,
  DataType,
  RelocationCaseReservationAttributesType,
  UnitItemAttributes,
  RelocationCaseComparableUnitAttributesType,
} from "@common/typing";
import {
  compUnitToSplitStay,
  getReservationDetails,
  splitStaysFilter,
  getUnitNameFromReservation,
  getUnitNameFromUnit,
  standardUrl,
  getUnitUrlFromReservation,
  floatFixed,
  getResDetailsOutsideBooking,
  isCaseResolved,
  unitOptionToCompUnit,
  unitOptionToCompUnitUpdate,
  splitStayToCompUnit,
  splitStayToCompUnitUpdate,
} from "../../utils";
import * as dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {RootState, useAppDispatch} from "../../store/store";

export type UnitOptionItem = {
  id: number;
  type: string;
  completed: boolean;
  selectedOption: number;
  unitUrl: string;
  unitName: string;
  resId: number | null;
  agentNote: string;
  authorizedBy: string | null;
  updatedAt?: string;
  unitId?: number;
  reservation_cancelled: boolean;
};

interface UnitOptionProps {
  item: UnitOptionItem;
  index: number;
}

export const UnitOption: FC<UnitOptionProps> = (props) => {
  const relocationCaseComparableUnitTypes = useSelector((state: RootState) => state.compUnit.relocationCaseComparableUnitTypes) as any;
  const relocationCaseComparableUnits = useSelector((state: RootState) => state.compUnit.relocationCaseComparableUnits) as any;
  const relocationCaseComparableUnitSplitStays = useSelector((state: RootState) => state.compUnit.relocationCaseComparableUnitSplitStays) as any;
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const moreHomesOptionsDisabled = useSelector((state: RootState) => state.compUnit.restrictions.moreHomesOptionsDisabled) as any;
  const moreOtherOptionsDisabled = useSelector((state: RootState) => state.compUnit.restrictions.moreOtherOptionsDisabled) as any;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const {url} = FrontendConfiguration.adminConfig();
  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const [selectedOption, setSelectedOption] = useState(props.item.selectedOption);
  const [agentNote, setAgentNote] = useState(props.item.agentNote);
  const [unitUrl, setUnitUrl] = useState(props.item.unitUrl);
  const [resId, setResId] = useState<number | null>(props.item.resId);
  const [authorizedBy, setAuthorizedBy] = useState<string | null>(props.item.authorizedBy);
  const [authorizedBySearch, setAuthorizedBySearch] = useState(props.item.authorizedBy || "");
  const [externalAmount, setExternalAmount] = useState<string>("");
  const [externalConfirmationCode, setExternalConfirmationCode] = useState<string>("");
  const [wrapUp, setWrapUp] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectValues, setSelectValues] = useState([{id: 0, name: "Select ", isDisabled: false}]);
  const [deletingCompUnit, setDeletingCompUnit] = useState(false);
  const [creatingCompUnit, setCreatingCompUnit] = useState(false);
  const [updatingCompUnit, setUpdatingCompUnit] = useState(false);
  const [selectingCompUnit, setSelectingCompUnit] = useState(false);
  const [validNote, setValidNote] = useState(props.item.agentNote !== "");
  const [validUrl, setValidUrl] = useState(false);
  const [validResId, setValidResId] = useState(false);
  const [validAuthorizedBy, setValidAuthorizedBy] = useState(false);
  const [validInputs, setValidInputs] = useState(false);
  const [splitStays, setSplitStays] = useState<SplitStayItem[]>([]);
  const readOnly = isCaseResolved(relocationCase);
  const [negativeKeys, setNegativeKeys] = useState(-1);

  const getKey = (i: number) => {
    setNegativeKeys(negativeKeys - i);
    return negativeKeys - i;
  };

  const templateSplitStay = {
    compUnitId: props.item.id,
    type: 1,
    authorizedBy: "",
    unitUrl: "",
    resId: null,
    checkin: dayjs().format("YYYY-MM-DD"),
    checkout: dayjs().format("YYYY-MM-DD"),
    unitName: "",
    validInputs: false,
    externalAmount: 0,
    confirmationCode: "",
    reservationCancelled: props.item.reservation_cancelled,
  };

  const generateTemplateSplitStay = (i: number) => {
    return {id: getKey(i), ...templateSplitStay};
  };

  useEffect(() => {
    if (!_.isEmpty(relocationCaseComparableUnitTypes)) {
      const optionsFiltered = relocationCaseComparableUnitTypes.map((e: RelocationCaseComparableUnitType) => {
        let isDisabled;
        if (props.index === 1) isDisabled = false;
        else if (![OptionsAvailable.HOMES_AVAILABLE, OptionsAvailable.NO_HOMES_AVAILABLE].includes(e.id)) isDisabled = moreOtherOptionsDisabled;
        else if (e.id === OptionsAvailable.HOMES_AVAILABLE) isDisabled = moreHomesOptionsDisabled;
        else isDisabled = [OptionsAvailable.NO_HOMES_AVAILABLE].includes(e.id);
        return {
          id: e.attributes?.id,
          name: e.attributes?.name,
          isDisabled,
        };
      });
      setSelectValues([{id: 0, name: "Select ", isDisabled: false}, ...optionsFiltered]);
    }
  }, [relocationCaseComparableUnitTypes]);

  useEffect(() => {
    if (selectedOption === OptionsAvailable.SPLIT_STAY) generateSplitStaysOptions();
  }, [relocationCaseComparableUnitSplitStays, selectedOption]);

  const generateSplitStaysOptions = () => {
    const splitStaysList = splitStaysFilter(props.item.id, relocationCaseComparableUnitSplitStays);
    if (_.isEmpty(splitStaysList)) {
      setSplitStays([generateTemplateSplitStay(1), generateTemplateSplitStay(2)]);
    } else {
      setSplitStays(splitStaysList.map(compUnitToSplitStay));
    }
  };

  const getRegExString = () => {
    const domainRegex = "^(https?:\\/\\/(?:www\\.|(?!www))|www\\.|https?:\\/\\/)?vacasa\\.com";
    switch (selectedOption) {
      case OptionsAvailable.HOMES_AVAILABLE:
        return `${domainRegex}\\/search\\?[${Regex.alnum}|${Regex.punct}]+$`;
      case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
        return `${domainRegex}\\/unit\\/${Regex.digit}{1,8}?[\\&a-zA-Z0-9_-]*`;
      case OptionsAvailable.OUTSIDE_BOOKING:
        return `.+`;
      default:
        return "";
    }
  };

  useEffect(() => {
    switch (selectedOption) {
      case OptionsAvailable.HOMES_AVAILABLE:
        if (props.item.completed) {
          editMode ? setValidInputs(validUrl) : setValidInputs(validResId);
        } else setValidInputs(validUrl);
        break;
      case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
        if (props.item.completed) {
          editMode ? setValidInputs(validUrl) : setValidInputs(validResId);
        } else setValidInputs(validUrl);
        break;
      case OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD:
        setValidInputs(validResId);
        break;
      case OptionsAvailable.SPLIT_STAY:
        setValidInputs(splitStays.filter((_) => !_.validInputs).length === 0);
        break;
      case OptionsAvailable.LTR_CREATING_VACASA_HOLD:
        setValidInputs(validResId);
        break;
      case OptionsAvailable.OUTSIDE_BOOKING:
        setValidInputs(validNote && validUrl && validAuthorizedBy);
        break;
      default:
        break;
    }
  }, [validUrl, validResId, validNote, validAuthorizedBy, splitStays]);

  const isValidUrl = (url: string) => {
    const urlRegEx = new RegExp(getRegExString());
    if (urlRegEx.test(url)) setValidUrl(true);
    else setValidUrl(false);
    if (editMode) setValidInputs(true);
  };

  const isValidAuthorizedBy = (email: string) => setValidAuthorizedBy(!_.isEmpty(email));

  useEffect(() => {
    isValidAuthorizedBy(authorizedBy || "");
  }, [authorizedBy]);

  useEffect(() => {
    if (props.item.unitUrl !== "") {
      isValidUrl(props.item.unitUrl);
    }
    isValidResId(props.item.resId ? props.item.resId.toString() : "");
    isValidAuthorizedBy(props.item.authorizedBy || "");
  }, [selectedOption]);

  const selectWrapUp = () => {
    if (wrapUp)
      if (props.item.selectedOption === OptionsAvailable.OUTSIDE_BOOKING) return wrapUpSectionOutsideBooking();
      else return wrapUpSection();
    else if (editMode) return selectorShowOptionsSave();
    else return selectorShowOptionsPlain();
  };

  const selectorShowOptionsSave = () => {
    switch (selectedOption) {
      case OptionsAvailable.HOMES_AVAILABLE:
        return ComparableUnitSectionSave(true);
      case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
        return ComparableUnitSectionSave(true);
      case OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD:
        return ComparableUnitSectionSave(false);
      case OptionsAvailable.SPLIT_STAY:
        return ComparableUnitSectionSplitSave();
      case OptionsAvailable.LTR_CREATING_VACASA_HOLD:
        return ComparableUnitSectionSave(false);
      case OptionsAvailable.OUTSIDE_BOOKING:
        return ComparableUnitSectionSaveOutsideBooking();
      case OptionsAvailable.NO_HOMES_AVAILABLE:
        return NoHomesAvailableSectionSave();
      default:
        return null;
    }
  };

  const selectorShowOptionsPlain = () => {
    switch (selectedOption) {
      case OptionsAvailable.HOMES_AVAILABLE:
        return ComparableUnitSectionPlain();
      case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
        return ComparableUnitSectionPlain(props.item.unitName);
      case OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD:
        return ComparableUnitSectionPlain(props.item.unitName);
      case OptionsAvailable.SPLIT_STAY:
        return ComparableUnitSectionSplitPlain();
      case OptionsAvailable.LTR_CREATING_VACASA_HOLD:
        return ComparableUnitSectionPlain(props.item.unitName);
      case OptionsAvailable.OUTSIDE_BOOKING:
        return ComparableUnitSectionOutsideBookingPlain(props.item.unitUrl || "");
      case OptionsAvailable.NO_HOMES_AVAILABLE:
        return ComparableUnitSectionNoHomesAvailablePlain();
      default:
        return null;
    }
  };

  const handleWrapUp = () => {
    setWrapUp(!wrapUp);
    if (selectedOption === OptionsAvailable.SPLIT_STAY) setValidInputs(true);
    else {
      if (selectedOption === OptionsAvailable.VACASA_UNIT_NO_RES_HOLD) setValidUrl(true);
      else if (selectedOption === OptionsAvailable.HOMES_AVAILABLE) setValidUrl(true);
      else {
        setValidUrl(false);
        setValidInputs(false);
        setExternalAmount("");
      }
    }
  };

  const handleEditMode = () => {
    setWrapUp(false);
    setEditMode(true);
    if (selectedOption === OptionsAvailable.SPLIT_STAY) setValidInputs(true);
    else setValidInputs(false);
  };

  const handleNote = (e: any) => {
    setAgentNote(e.target.value);
    if (editMode) setValidInputs(true);
  };

  const handleNoteRequired = (e: any) => {
    setAgentNote(e.target.value);
    setValidNote(e.target.value !== "");
  };

  const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = e.target.value.replace(/\s/g, "");
    setUnitUrl(fixedValue);
    isValidUrl(fixedValue);
  };

  const isValidResId = (resId: string) => {
    const fixedValue = resId.replace(/\s/g, "");
    const urlRegEx = new RegExp(`^${Regex.digit}+$`);
    const valid = urlRegEx.test(fixedValue);
    setValidResId(valid);
    return valid;
  };

  const handleResId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fixedValue = e.target.value.replace(/\s/g, "");
    if (isValidResId(fixedValue)) setResId(parseInt(fixedValue));
    if (fixedValue === "") setResId(null);
    if (editMode) setValidInputs(true);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleTotalCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: {value: externalAmount},
    } = e;
    setExternalAmount(externalAmount);

    if (externalAmount === "") {
      setValidUrl(false);
      return;
    }
    setValidUrl(true);
  };

  const handleShowOption = (e: any) => {
    setSelectedOption(parseInt(e.target.value));
    setUnitUrl("");
    setAgentNote("");
    setResId(null);
    setValidInputs(false);
    if (parseInt(e.target.value) === OptionsAvailable.SPLIT_STAY) {
      setAgentNote(""); //TODO: Set agent note from new input
      setValidUrl(true);
      setValidInputs(true);
    }
    if (parseInt(e.target.value) === OptionsAvailable.NO_HOMES_AVAILABLE) {
      setValidInputs(true);
    }
  };

  const handleSave = async () => {
    if (validInputs) props.item.id > 0 ? await updateUnitComp() : await createUnitComp();
  };

  const completeUnitDataOnSplitStays = async (splitStaysToComplete: SplitStayItem[]) => {
    let splitStaysCompleted = [];
    for (let i = 0; i < splitStaysToComplete.length; i++) {
      let splitStay = splitStaysToComplete[i];
      if (splitStay.type === TypesOfBooking.VACASA_UNIT) {
        try {
          const reservation = await fetchReservationByLegacyId(splitStay.resId as number);
          splitStay.unitName = getUnitNameFromReservation(reservation as any);
          splitStay.unitUrl = getUnitUrlFromReservation(reservation as any);
        } catch (error) {
          console.error(`Reservation with ID ${splitStay.resId} not found.`);
          splitStay.unitName = "Url Not Found";
          splitStay.unitUrl = "#";
        }
      }
      splitStaysCompleted.push(splitStay);
    }
    return splitStaysCompleted;
  };

  const updateUnitComp = async () => {
    try {
      setUpdatingCompUnit(true);
      let dataToUpdate = {} as UnitOptionItem,
        standardizedUrl,
        unitId,
        unitName;
      switch (selectedOption) {
        case OptionsAvailable.LTR_CREATING_VACASA_HOLD:
        case OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD:
          if (props.item.resId !== resId) {
            // @ts-ignore
            const reservation: GetResponse<RelocationCaseReservationAttributesType> = await fetchReservationByLegacyId(resId as any);
            unitName = getUnitNameFromReservation(reservation);
            dataToUpdate.resId = resId;
            dataToUpdate.unitUrl = getUnitUrlFromReservation(reservation);
            dataToUpdate.unitName = unitName;
            dataToUpdate.unitId = reservation.data?.attributes?.data?.attributes?.unit_id;
          }
          break;
        case OptionsAvailable.HOMES_AVAILABLE:
          standardizedUrl = standardUrl(unitUrl);
          if (props.item.unitUrl !== standardizedUrl) dataToUpdate.unitUrl = standardizedUrl;
          break;
        case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
          standardizedUrl = standardUrl(unitUrl);
          if (props.item.unitUrl !== standardizedUrl) {
            dataToUpdate.unitUrl = standardizedUrl;
            const regex = new RegExp("unit/([0-9]*)\\??");
            const match = regex.exec(unitUrl);
            unitId = match ? match[1] : null;
            if (!unitId) throw new Error("Invalid unitId");
            if (parseInt(unitId) !== props.item.unitId) {
              // @ts-ignore
              const unit: DataType<UnitItemAttributes> = await fetchUnitByLegacyId(parseInt(unitId));
              unitAvailableAlert(unit.attributes.display);
              unitName = getUnitNameFromUnit(unit);
              dataToUpdate.unitId = parseInt(unitId);
              dataToUpdate.unitName = unitName;
            }
          }
          break;
        case OptionsAvailable.OUTSIDE_BOOKING:
          if (props.item.authorizedBy !== authorizedBy) dataToUpdate.authorizedBy = authorizedBy;
          if (props.item.unitUrl !== unitUrl) dataToUpdate.unitUrl = standardUrl(unitUrl);
          break;
        default:
          break;
      }
      if (props.item.agentNote !== agentNote) dataToUpdate.agentNote = agentNote;
      if (_.isEmpty(dataToUpdate) && selectedOption !== OptionsAvailable.SPLIT_STAY) setEditMode(false);
      else {
        const toUpdate = unitOptionToCompUnitUpdate(dataToUpdate);
        if (selectedOption !== OptionsAvailable.SPLIT_STAY) {
          await dispatch(updateCompUnit({id: props.item.id, data: toUpdate}));
        } else {
          const originalSplitStays = splitStaysFilter(props.item.id, relocationCaseComparableUnitSplitStays).map(compUnitToSplitStay);
          if (!_.isEmpty(originalSplitStays) || !_.isEmpty(splitStays)) {
            const tempSplitStaysToCreate = splitStays.filter((e) => (!_.isNil(e.id) ? e.id < 0 : false));
            const splitStaysToCreate = (await completeUnitDataOnSplitStays(tempSplitStaysToCreate)).map(splitStayToCompUnit);
            const tempSplitStaysToUpdate = splitStays.filter((e) => _.findIndex(originalSplitStays, ["id", e.id]) !== -1);
            const splitStaysToUpdate = (await completeUnitDataOnSplitStays(tempSplitStaysToUpdate)).map(splitStayToCompUnitUpdate);
            const splitStaysToDelete = originalSplitStays.filter((e) => _.findIndex(splitStays, ["id", e.id]) === -1).map(splitStayToCompUnitUpdate);
            dispatch(
              updateCompUnitWithSplitStay({
                id: props.item.id,
                compUnit: toUpdate,
                splitStays: {
                  create: splitStaysToCreate,
                  update: splitStaysToUpdate,
                  delete: splitStaysToDelete,
                },
              })
            );
          }
        }
      }
      setUpdatingCompUnit(false);
    } catch (error) {
      setUpdatingCompUnit(false);
    } finally {
      setEditMode(false);
    }
  };

  const createUnitComp = async () => {
    try {
      setCreatingCompUnit(true);
      let dataToCreate, unitId, unitName;
      switch (selectedOption) {
        case OptionsAvailable.LTR_CREATING_VACASA_HOLD:
        case OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD:
          // @ts-ignore
          const reservation: GetResponse<RelocationCaseReservationAttributesType> = await fetchReservationByLegacyId(resId as any);
          unitId = reservation.data?.attributes?.data?.attributes?.unit_id;
          unitName = getUnitNameFromReservation(reservation);
          const newUnitUrl = `vacasa.com/unit/${unitId}`;
          dataToCreate = {
            selectedOption,
            unitUrl: standardUrl(newUnitUrl),
            unitName,
            unitId,
            resId,
            agentNote,
            authorizedBy,
          } as any;
          break;
        case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
          const regex = new RegExp("unit/([0-9]*)\\??");
          const match = regex.exec(unitUrl);
          unitId = match ? match[1] : null;
          if (!unitId) throw new Error("Invalid unitId");
          // @ts-ignore
          const unit: DataType<UnitItemAttributes> = await fetchUnitByLegacyId(parseInt(unitId));
          unitAvailableAlert(unit.attributes.display);
          unitName = getUnitNameFromUnit(unit);
          dataToCreate = {
            selectedOption,
            unitUrl: standardUrl(unitUrl),
            unitName,
            unitId: parseInt(unitId),
            agentNote,
          };
          break;
        case OptionsAvailable.SPLIT_STAY:
          dataToCreate = {selectedOption, agentNote};
          break;
        case OptionsAvailable.OUTSIDE_BOOKING:
          dataToCreate = {selectedOption, authorizedBy, unitUrl, agentNote};
          break;
        default:
          dataToCreate = {selectedOption, unitUrl: standardUrl(unitUrl), resId, agentNote, authorizedBy};
          break;
      }

      const toCreate = unitOptionToCompUnit(relocationCase.id.toString(), dataToCreate);
      if (selectedOption === OptionsAvailable.SPLIT_STAY) {
        const splitStaysToCreate = (await completeUnitDataOnSplitStays(splitStays)).map(splitStayToCompUnit);
        dispatch(createCompUnitWithSplitStays({compUnit: toCreate, splitStays: splitStaysToCreate}));
      } else {
        dispatch(createCompUnit(toCreate)).then((result: {payload: any}) => {
          if (selectedOption === OptionsAvailable.NO_HOMES_AVAILABLE) {
            dispatch(updateSelectedCompUnitOnCase({id: relocationCase.id, selected: result.payload.id}));
          }
        });
      }
    } catch (error) {
      setValidResId(false);
    } finally {
      setCreatingCompUnit(false);
    }
  };

  const deleteUnitComp = async () => {
    try {
      setDeletingCompUnit(true);
      if (relocationCase.attributes.selected_relocation_case_comparable_unit_id === props.item.id) {
        dispatch(updateSelectedCompUnitOnCase({id: relocationCase.id, selected: null}));
      }
      if (props.item.selectedOption === OptionsAvailable.SPLIT_STAY) {
        const splitStaysIds = splitStays.map((e) => e.id) as any;
        dispatch(deleteCompUnitWithSplitStay({id: props.item.id, splitStays: splitStaysIds}));
      } else {
        dispatch(deleteCompUnit(props.item.id));
      }
      setDeletingCompUnit(false);
    } catch (error) {
      setDeletingCompUnit(false);
      console.error(error);
    }
  };

  const updateSplitStayFromList = (index: number, state: SplitStayItem) => {
    const temp = [...splitStays];
    temp[index - 1] = state;
    setSplitStays(temp);
  };

  const deleteSplitStayFromList = (index: number) => {
    if (splitStays.length === 1) setSplitStays([generateTemplateSplitStay(1)]);
    else setSplitStays(splitStays.filter((_, i) => i !== index - 1));
  };

  const redirectToWrapUpSection = async () => {
    try {
      setSelectingCompUnit(true);
      const compUnit = relocationCaseComparableUnits[_.findIndex(relocationCaseComparableUnits, {id: props.item.id})];
      let relocationCaseComparableUnitData: RelocationCaseComparableUnitAttributesType = {} as RelocationCaseComparableUnitAttributesType;
      let reservationDetails = [] as ReservationDetails[];

      // Outside Booking
      if (compUnit.attributes?.relocation_case_comparable_unit_type_id === OptionsAvailable.OUTSIDE_BOOKING) {
        relocationCaseComparableUnitData = {
          external_amount: floatFixed(parseFloat(externalAmount)),
          external_confirmation_code: externalConfirmationCode,
        } as RelocationCaseComparableUnitAttributesType;
        dispatch(
          setCompUnit({
            ...compUnit,
            attributes: {
              ...compUnit.attributes,
              external_amount: floatFixed(parseFloat(externalAmount)),
              external_confirmation_code: externalConfirmationCode,
            },
          })
        );
        dispatch(updateCompUnit({id: compUnit.id, data: relocationCaseComparableUnitData}));

        //No Res Hold && Homes Available && Vacasa Res Hold && LTR Hold
      } else if (
        [
          OptionsAvailable.VACASA_UNIT_NO_RES_HOLD,
          OptionsAvailable.HOMES_AVAILABLE,
          OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD,
          OptionsAvailable.LTR_CREATING_VACASA_HOLD,
        ].includes(compUnit.attributes?.relocation_case_comparable_unit_type_id)
      ) {
        reservationDetails = await getResDetailsByResIdList([resId]);
        dispatch(setDestinationResData(reservationDetails));

        relocationCaseComparableUnitData = setCompUnitFinancesFromResDetails(reservationDetails);

        if (
          [OptionsAvailable.VACASA_UNIT_NO_RES_HOLD, OptionsAvailable.HOMES_AVAILABLE].includes(
            compUnit.attributes?.relocation_case_comparable_unit_type_id
          )
        ) {
          relocationCaseComparableUnitData.legacy_reservation_id = resId;
          relocationCaseComparableUnitData.legacy_unit_id = reservationDetails[0].unitId as any;
          relocationCaseComparableUnitData.public_url_name = `${reservationDetails[0].unitName}`;
          dispatch(
            setCompUnit({
              ...compUnit,
              attributes: {
                ...compUnit.attributes,
                legacy_reservation_id: resId,
                legacy_unit_id: reservationDetails[0].unitId,
                public_url_name: `${reservationDetails[0].unitName}`,
              },
            })
          );
        } else {
          dispatch(setCompUnit(compUnit));
        }
        dispatch(updateCompUnit({id: compUnit.id, data: relocationCaseComparableUnitData}));
      } else if (compUnit.attributes?.relocation_case_comparable_unit_type_id === OptionsAvailable.SPLIT_STAY) {
        // Collecting splitStay finances
        for (let s of splitStays) {
          if (_.isNil(s.resId)) reservationDetails.push(getResDetailsOutsideBooking(s.externalAmount || 0, s.confirmationCode || "", s.unitUrl));
          else reservationDetails.push((await getResDetailsByResIdList([s.resId]))[0]);
        }

        dispatch(setDestinationResData(reservationDetails));

        // Update comp unit finances on DB
        relocationCaseComparableUnitData = setCompUnitFinancesFromResDetails(reservationDetails);
        dispatch(updateCompUnit({id: compUnit.id, data: relocationCaseComparableUnitData}));

        // Update each splitStay finances on DB
        for (let i = 0; i < reservationDetails.length; i++) {
          let splitStayData;
          if (_.isNil(reservationDetails[i].resId)) splitStayData = setCompUnitFinancesOutsideBooking(reservationDetails[i]);
          else splitStayData = setCompUnitFinancesFromResDetails([reservationDetails[i]]);
          dispatch(updateSplitStay({id: splitStays[i].id as number, data: splitStayData as any}));
        }
        dispatch(setCompUnit(compUnit));
      } else {
        dispatch(setCompUnit(compUnit));
      }
      datadogLogs.logger.info("Comparable Unit Selected", {id: compUnit.id, relocationCaseComparableUnitData});
      window.scrollTo({top: 0, left: 0});
      navigate(`/case/${relocationCase.id}/wrapup`);
    } catch (e) {
      console.error(e);
    } finally {
      setSelectingCompUnit(false);
    }
  };

  const unitAvailableAlert = (display: boolean) => {
    if (display) dispatch(hideUnitAvailableAlert());
    else {
      dispatch(showUnitAvailableAlert());
      throw new Error("This unit's display is not active");
    }
  };

  const cancelledReservationMessage = () => {
    return (
      <div className={styles.selectorInfo}>
        <BsInfoCircle />
        <span>
          The Reservation{" "}
          <a href={url + "/admin/dashboard/reservation/" + resId} className={styles.link} target="_blank" rel="noopener noreferrer">
            {resId}
          </a>{" "}
          associated with this Comparable Unit has been cancelled.
        </span>
      </div>
    );
  };

  const cancelledReservationSplitStayMessage = () => {
    return (
      <div className={styles.selectorInfo}>
        <BsInfoCircle />
        <span>
          <>
            The Reservation{splitStays.filter((e) => e.reservationCancelled).length > 1 ? "s" : ""}{" "}
            {splitStays
              .filter((e) => e.reservationCancelled)
              .map((e, index, list) => (
                <>
                  <a href={url + "/admin/dashboard/reservation/" + e.resId} className={styles.link} target="_blank" rel="noopener noreferrer">
                    {e.resId}
                  </a>
                  {index < list.length - 2 ? ", " : index === list.length - 2 ? " and " : ""}
                </>
              ))}{" "}
            associated with this Comparable Unit has been cancelled.
          </>
        </span>
      </div>
    );
  };

  const wrapUpSection = () => {
    return (
      <div>
        {selectedOption === OptionsAvailable.SPLIT_STAY ? (
          ComparableUnitSectionSplitPlain()
        ) : (
          <div className={styles.section}>
            <span className={styles.subTitle}>Res ID</span>
            {selectedOption === OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD || selectedOption === OptionsAvailable.LTR_CREATING_VACASA_HOLD ? (
              <div>
                <div className={styles.section}>
                  <span className={styles.noEditText}>
                    <a href={props.item.unitUrl} className={styles.link} target="_blank" rel="noopener noreferrer">
                      {props.item.resId}
                    </a>
                  </span>
                </div>
                <div className={styles.section}>
                  <span className={styles.subTitle}>URL for Vacasa.com</span>
                  <div>
                    <span className={styles.noEditText}>
                      <a href={props.item.unitUrl} className={styles.link} target="_blank" rel="noopener noreferrer">
                        {props.item.unitName}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="text"
                  id={"resId" + props.index}
                  className={styles.textBoxWrapUp}
                  value={resId ? resId.toString() : ""}
                  onChange={handleResId}
                  onKeyPress={handleInputKeyPress}
                />
              </div>
            )}
          </div>
        )}
        {!readOnly && (
          <div className={styles.wrapUpDivButton}>
            <button
              className={validInputs && !selectingCompUnit ? styles.button : styles.buttonDisabled}
              disabled={!validInputs || selectingCompUnit}
              onClick={redirectToWrapUpSection}
            >
              Wrap-up
            </button>
            {selectingCompUnit ? (
              <div className={styles.savingProgress}>
                <CircularProgress color="inherit" size={25} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const wrapUpSectionOutsideBooking = (urlName?: string) => {
    return (
      <div>
        <div className={styles.section}>
          <span className={styles.subTitle}>Authorized by</span>
          <div>
            <span className={styles.noEditText}>{props.item.authorizedBy}</span>
          </div>
        </div>
        <div className={styles.section}>
          <span className={styles.subTitle}>URL for Outside Booking unit</span>
          <div>
            <span className={styles.noEditText}>
              <a href={props.item.unitUrl} className={styles.link} target="_blank" rel="noopener noreferrer">
                {urlName ? urlName : "Comp units"}
              </a>
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <div>
            <span className={styles.subTitle}>Confirmation # (optional)</span>
          </div>
          <div>
            <input
              type="text"
              className={styles.textBoxWrapUp}
              value={externalConfirmationCode}
              onChange={(e) => setExternalConfirmationCode(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div>
            <span className={styles.subTitle}>Total Cost (required)</span>
          </div>
          <div>
            <input
              type="number"
              value={externalAmount}
              placeholder="0.00"
              min="0"
              className={styles.textBoxWrapUp}
              onChange={(e) => handleTotalCost(e)}
              onKeyPress={handleInputKeyPress}
            />
          </div>
        </div>
        <div className={styles.section}>
          {!readOnly && (
            <button className={validUrl ? styles.button : styles.buttonDisabled} onClick={redirectToWrapUpSection}>
              Wrap-up
            </button>
          )}
        </div>
      </div>
    );
  };

  const lblUrlComparableUnit = () => {
    if (selectedOption === OptionsAvailable.HOMES_AVAILABLE) {
      return (
        <div>
          <span className={styles.textLabel}>
            Head to{" "}
            <a href={urlVacasaCom} target="_blank" rel="noopener noreferrer" className={styles.link}>
              Vacasa.com
            </a>
            , apply filters, and copy that URL to paste here.
          </span>
        </div>
      );
    } else if (selectedOption === OptionsAvailable.VACASA_UNIT_NO_RES_HOLD) {
      return (
        <div>
          <span className={styles.textLabel}>Just an option that might be a good fit, but not on hold.</span>
        </div>
      );
    }
  };

  const ComparableUnitSectionSaveUrl = () => {
    return (
      <div className={styles.section}>
        <div className={styles.divSeparator}>
          <span className={styles.subTitle}>URL for Vacasa.com</span>
        </div>
        <div>
          <input
            type="text"
            id={"url" + props.index}
            className={styles.textBox + " " + (!validUrl ? styles.textBoxWarning : "")}
            value={unitUrl ?? ""}
            onChange={handleUrl}
          />
          {lblUrlComparableUnit()}
        </div>
      </div>
    );
  };

  const ComparableUnitSectionSaveRes = () => {
    return (
      <div className={styles.section}>
        <div className={styles.divSeparator}>
          <span className={styles.subTitle}>Res ID</span>
        </div>
        <div className={styles.divSeparator}>
          <input
            type="text"
            id={"resId" + props.index}
            className={styles.textBoxWrapUp + " " + (!validResId ? styles.textBoxWarning : "")}
            value={resId ? resId.toString() : ""}
            onChange={handleResId}
          />
          <div>
            {selectedOption === OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD && (
              <span className={styles.textLabel}>Create a res to hold this unit as option for Guest and paste here.</span>
            )}
            {selectedOption === OptionsAvailable.LTR_CREATING_VACASA_HOLD && (
              <span className={styles.textLabel}>Create Vacasa hold on ResGrid and paste Res ID here.</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ComparableUnitSectionSaveButton = () => {
    return (
      <div className={styles.btnBox}>
        {!readOnly && (
          <button
            className={creatingCompUnit || updatingCompUnit || !validInputs ? styles.buttonDisabled : styles.button}
            onClick={handleSave}
            disabled={creatingCompUnit || updatingCompUnit || !validInputs}
          >
            Save
          </button>
        )}
        {selectedOption === OptionsAvailable.SPLIT_STAY && !readOnly && (
          <button
            className={cx(styles.addUnitsButton, splitStays.length >= SPLITSTAY_UNITS_LIMIT && styles.disabledClass)}
            disabled={splitStays.length >= SPLITSTAY_UNITS_LIMIT}
            onClick={() => setSplitStays([...splitStays, generateTemplateSplitStay(1)])}
          >
            + Add another unit{" "}
            {splitStays.length >= SPLITSTAY_UNITS_LIMIT && (
              <span className={styles.unitsLimit}>(You can add only {SPLITSTAY_UNITS_LIMIT} units)</span>
            )}
          </button>
        )}
        {creatingCompUnit || updatingCompUnit ? (
          <div className={styles.savingProgress}>
            <CircularProgress color="inherit" size={25} />
          </div>
        ) : null}
      </div>
    );
  };

  const ComparableUnitSectionSave = (isUrl: any) => {
    return (
      <div>
        {isUrl ? ComparableUnitSectionSaveUrl() : ComparableUnitSectionSaveRes()}
        <div className={styles.section}>
          <div className={styles.divSeparator}>
            <span className={styles.subTitle}>Optional Agent note</span>
          </div>
          <div>
            <textarea id={"agentNote" + props.index} className={styles.textArea} value={agentNote ?? ""} onChange={(e) => handleNote(e)} />
          </div>
        </div>
        {ComparableUnitSectionSaveButton()}
      </div>
    );
  };

  const NoHomesAvailableSectionSave = () => {
    return (
      <div>
        <div className={styles.section}>
          <div className={styles.divSeparator}>
            <span className={styles.subTitle}>No Homes Available</span>
          </div>
          <div>
            <div className={styles.noEditText}>
              This option is being selected to indicate that alternative accommodations for Guest were unable to be identified.
            </div>
          </div>
        </div>
        {ComparableUnitSectionSaveButton()}
      </div>
    );
  };

  const handleOnChangeAuthorizedBy = (email: string) => {
    setAuthorizedBySearch(email || "");
    setAuthorizedBy(email || null);
  };

  const getResDetailsByResIdList = async (ids: (number | null)[]): Promise<ReservationDetails[]> => {
    let list = [];
    for (let i = 0; i < ids.length; i++) {
      if (_.isNil(ids[i])) throw new Error(`ResID provided is null. The reservation details can't be formed.`);
      const reservation = await fetchReservationByLegacyId(ids[i] as number);
      list.push(getReservationDetails(reservation));
    }
    return list;
  };

  const setCompUnitFinancesFromResDetails = (resDetails: ReservationDetails[]) => {
    return {
      finance_taxes: resDetails.reduce((acc, curr) => floatFixed(acc + curr.taxes), 0),
      finance_fees: resDetails.reduce((acc, curr) => floatFixed(acc + curr.fees), 0),
      finance_rent: resDetails.reduce((acc, curr) => floatFixed(acc + curr.rent), 0),
      finance_tp: resDetails.reduce((acc, curr) => floatFixed(acc + curr.tp), 0),
      external_amount: resDetails.reduce((acc, curr) => {
        return _.isNil(curr.resId) ? floatFixed(acc + curr.total) : acc;
      }, 0),
    } as RelocationCaseComparableUnitAttributesType;
  };
  const setCompUnitFinancesOutsideBooking = (resDetail: ReservationDetails) => {
    return {
      external_amount: floatFixed(resDetail.total),
      external_confirmation_code: resDetail.confirmationCode,
    } as RelocationCaseComparableUnitAttributesType;
  };

  const ComparableUnitSectionSaveOutsideBooking = () => {
    return (
      <div>
        <div className={styles.section}>
          <div className={styles.divSeparator}>
            <span className={styles.subTitle}>Authorized by</span>
          </div>
          <div className={styles.divSeparator}>
            <SearchBar
              placeholder="Search Agents"
              value={authorizedBySearch}
              onSearchChange={setAuthorizedBySearch}
              handleOnChange={handleOnChangeAuthorizedBy}
            />
          </div>
          {!validAuthorizedBy && <div className={styles.textBoxWarningText}>Invalid Authorized By</div>}
        </div>
        <div className={styles.section}>
          <div className={styles.divSeparator}>
            <span className={styles.subTitle}>URL for Outside Booking unit</span>
          </div>
          <div className={styles.divSeparator}>
            <input
              type="text"
              id={"url" + props.index}
              className={styles.textBox + " " + (!validUrl ? styles.textBoxWarning : "")}
              value={unitUrl}
              onChange={handleUrl}
            />
            {!validUrl && <div className={styles.textBoxWarningText}>Invalid Url</div>}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.divSeparator}>
            <span className={styles.subTitle}>Note (required)</span>
          </div>
          <div>
            <textarea
              id={"agentNote" + props.index}
              className={styles.textArea + " " + (!validNote ? styles.textBoxWarning : "")}
              value={agentNote}
              onChange={(e) => handleNoteRequired(e)}
            />
          </div>
        </div>
        {ComparableUnitSectionSaveButton()}
      </div>
    );
  };

  const ComparableUnitSectionSplitSave = () => {
    return (
      <>
        <div className={styles.section}>
          {splitStays.map((element, index) => (
            <SplitStay
              key={element.id}
              isSelected={wrapUp}
              editMode={true}
              item={element}
              index={index + 1}
              onChange={updateSplitStayFromList}
              onDelete={deleteSplitStayFromList}
            />
          ))}

          {/* Agent Note */}
          <div className={styles.agentNoteContainer}>
            <span className={styles.subTitle}>Optional Agent note</span>
            {!editMode && props?.item?.agentNote}
            <textarea className={styles.textAreaSplitStay} value={agentNote} spellCheck={false} onChange={(e) => setAgentNote(e.target.value)} />
          </div>
        </div>
        {ComparableUnitSectionSaveButton()}
      </>
    );
  };

  const ComparableUnitSectionPlain = (urlName?: string) => {
    return (
      <div>
        {(selectedOption === OptionsAvailable.VACASA_UNIT_CREATING_RES_HOLD || selectedOption === OptionsAvailable.LTR_CREATING_VACASA_HOLD) && (
          <div className={styles.section}>
            <span className={styles.subTitle}>Res ID</span>
            <div>
              <span className={styles.noEditText}>
                <a href={url + "/admin/dashboard/reservation/" + props.item.resId} target="_blank" rel="noopener noreferrer" className={styles.link}>
                  {props.item.resId}
                </a>
              </span>
            </div>
          </div>
        )}
        <div className={styles.section}>
          <span className={styles.subTitle}>URL for Vacasa.com</span>
          <div>
            <span className={styles.noEditText}>
              <a href={props.item.unitUrl} className={styles.link} target="_blank" rel="noopener noreferrer">
                {urlName ? urlName : "Comp units"}
              </a>
            </span>
          </div>
        </div>
        <div>
          <div>
            <span className={styles.subTitle}>Optional Agent Note</span>
          </div>
          <div>
            <div className={styles.noEditText}>{props.item.agentNote}</div>
          </div>
        </div>
        {props.item.reservation_cancelled && cancelledReservationMessage()}
      </div>
    );
  };

  const ComparableUnitSectionSplitPlain = () => {
    return (
      <div>
        <div className={styles.section}>
          {splitStays.map((element, index) => (
            <SplitStay key={element.id} isSelected={wrapUp} editMode={false} item={element} index={index + 1} onChange={updateSplitStayFromList} />
          ))}

          <span className={styles.subTitle}>Agent Note</span>
          <div className={styles.noEditText}>{props.item.agentNote}</div>
        </div>
        {props.item.reservation_cancelled && cancelledReservationSplitStayMessage()}
      </div>
    );
  };

  const ComparableUnitSectionOutsideBookingPlain = (urlName?: string) => {
    return (
      <div>
        <div className={styles.section}>
          <span className={styles.subTitle}>Authorized by</span>
          <div>
            <span className={styles.noEditText}>{mailToName(props.item.authorizedBy || "")}</span>
          </div>
        </div>
        <div className={styles.section}>
          <span className={styles.subTitle}>URL for Outside Booking unit</span>
          <div>
            <span className={styles.noEditText}>
              <a href={props.item.unitUrl} className={styles.link} target="_blank" rel="noopener noreferrer">
                {urlName ? urlName : "Comp units"}
              </a>
            </span>
          </div>
        </div>
        <div className={styles.section}>
          <span className={styles.subTitle}>Agent Note</span>
          <div className={styles.noEditText}>{props.item.agentNote}</div>
        </div>
      </div>
    );
  };

  const ComparableUnitSectionNoHomesAvailablePlain = () => {
    return (
      <div>
        <div className={styles.section}>
          <span className={styles.subTitle}>No Homes Available</span>
          <div>
            <span className={styles.noEditText}>
              An effort was made to find alternative accommodations for this Guest, but were not able to be identified.
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.body}>
      {_.isEmpty(relocationCaseComparableUnitTypes) ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.subTitleGrid}>
            <div className={styles.section}>
              <span className={styles.title}>Option {props.index}</span>

              {wrapUp && <BsCheck style={{verticalAlign: "middle"}} className={styles.editBtnSuccess} />}

              {props.item.completed && !editMode && !wrapUp && !readOnly && (
                <BsPencilSquare style={{verticalAlign: "middle"}} className={styles.editBtn} onClick={handleEditMode} />
              )}
            </div>
            {props.item.completed &&
              (editMode ? (
                <div className={styles.alignRight}>
                  {deletingCompUnit ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <BsTrash style={{verticalAlign: "middle"}} className={styles.editBtn} onClick={deleteUnitComp} />
                  )}
                </div>
              ) : (
                !readOnly &&
                !props.item.reservation_cancelled &&
                selectedOption !== OptionsAvailable.NO_HOMES_AVAILABLE && (
                  <div className={styles.alignRight}>
                    <label className={styles.textLabelGuest}>
                      {!wrapUp ? "Select for Guest " : "Selected "}

                      <input
                        type="checkbox"
                        name="chkInputGuest"
                        id="chk_inputGuest"
                        style={{verticalAlign: "middle"}}
                        className={styles.checkboxGuest}
                        onChange={handleWrapUp}
                      />
                    </label>
                  </div>
                )
              ))}
          </div>
          {!props.item.completed ? (
            <div>
              <div className={styles.section}>
                <div className={styles.divSeparator}>
                  <span className={styles.subTitle}>Type of Unit Option</span>
                </div>
                <div>
                  <select
                    className={styles.selectBox}
                    value={selectedOption}
                    onChange={(e) => {
                      handleShowOption(e);
                    }}
                  >
                    {selectValues.map((option, index) => {
                      return option.isDisabled ? (
                        <option value={option.id} key={index} disabled>
                          {option.name}
                        </option>
                      ) : (
                        <option value={option.id} key={index}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {selectorShowOptionsSave()}
            </div>
          ) : (
            <div>
              <div className={styles.section}>
                <span className={styles.subTitle}>Type of Unit Option</span>
                <div>
                  <span className={styles.noEditText}>{selectValues.find((x) => x.id === props.item.selectedOption)?.name}</span>
                </div>
              </div>
              {selectWrapUp()}
            </div>
          )}
        </>
      )}
    </div>
  );
};
