import React, {useEffect, useState} from "react";
import styles from "./FilterSearch.module.scss";
import useDebounce from "../../hooks/useDebounce";
import {RootState, useAppDispatch} from "../../store/store";
import {updateCodeFilter} from "../../store/queueSlice";
import {useSelector} from "react-redux";

interface IFilterSearchProps {
  placeholder?: string;
  filterName: "unitCode";
}

export const FilterSearch: React.FunctionComponent<IFilterSearchProps> = ({placeholder, filterName}): JSX.Element => {
  const defaultOption = "";
  const [internalSearch, setInternalSearch] = useState<any>(defaultOption);
  const debouncedSearch = useDebounce(internalSearch, 500);
  const dispatch = useAppDispatch();
  const clearTrigger = useSelector((state: RootState) => state.queue.triggers.clearFilters);
  const currentFilterValue = useSelector((state: RootState) => state.queue.filters[filterName].value);

  useEffect(() => {
    if (currentFilterValue === internalSearch) return;
    setInternalSearch(currentFilterValue);
  }, [currentFilterValue]);

  // Clear Filter
  useEffect(() => {
    setInternalSearch(defaultOption);
  }, [clearTrigger]);

  useEffect(() => {
    if (debouncedSearch === null) return;
    dispatch(updateCodeFilter(internalSearch));
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalSearch(e.target.value);
  };

  return (
    <>
      <input type="search" value={internalSearch} className={styles.inputSearch} placeholder={placeholder} onChange={handleChange} />
    </>
  );
};
