import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {closeValidateUnitCodeFailedAlert} from "../../../store/formSubmissionSlice";
import {AlertComponent} from "../../alert/AlertComponent";

export const ValidateUnitError: FC = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const {validateUnitErrorMessage} = useSelector((state: RootState) => state.formSubmission);

  return (
    <AlertComponent className="error" text={<>{validateUnitErrorMessage}</>} setShowAlert={() => dispatch(closeValidateUnitCodeFailedAlert())} />
  );
};
