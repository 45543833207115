import React, {FC, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {fetchCompUnits, hideUnitAvailableAlert, hideUnitCountAlert} from "../../store/compUnitSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {UnitOption, UnitOptionItem, AlertComponent} from "../index";

import {Spinner} from "..";
import _ from "lodash";
import styles from "./CaseAlternativeUnits.module.scss";
import {RelocationCaseComparableUnit} from "../../typing";
import {HOMES_UNIT_OPTION_LIMIT, OTHER_UNIT_OPTION_LIMIT} from "../../constants";
import {compUnitToUnitOption} from "../../utils";
import {useParams} from "react-router-dom";

interface CaseAlternativeUnitsProps {}

export const CaseAlternativeUnits: FC<CaseAlternativeUnitsProps> = () => {
  const {case_id: id} = useParams<{case_id: string}>();
  const relocationCaseId = parseInt(id || "");
  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: RootState) => state.compUnit.isLoading) as any;
  const relocationCaseComparableUnits = useSelector((state: RootState) => state.compUnit.relocationCaseComparableUnits) as any;
  const relocationRequest = useSelector((state: RootState) => state.caseProfile.relocationRequest) as any;
  const moreHomesOptionsDisabled = useSelector((state: RootState) => state.compUnit.restrictions.moreHomesOptionsDisabled) as any;
  const moreOtherOptionsDisabled = useSelector((state: RootState) => state.compUnit.restrictions.moreOtherOptionsDisabled) as any;
  const moreUnitOptionsDisabled = useSelector((state: RootState) => state.compUnit.restrictions.moreUnitOptionsDisabled) as any;
  const showInactiveUnitOptionSelected = useSelector((state: RootState) => state.compUnit.showInactiveUnitOptionSelected) as any;

  const templateUnitOption = [
    {
      id: 0,
      type: "0",
      completed: false,
      selectedOption: 0,
      unitUrl: "",
      unitName: "",
      resId: null,
      agentNote: "",
      authorizedBy: "",
      reservation_cancelled: false,
    },
  ];

  const generateOptions = (comparableUnits: RelocationCaseComparableUnit[]): UnitOptionItem[] => [
    ..._.sortBy(comparableUnits, ["id"])?.map(compUnitToUnitOption),
    ...templateUnitOption,
  ];

  const generateOptionsLimit = (comparableUnits: RelocationCaseComparableUnit[]): UnitOptionItem[] => [
    ..._.sortBy(comparableUnits, ["id"])?.map(compUnitToUnitOption),
  ];

  const [options, setOptions] = useState(generateOptions(relocationCaseComparableUnits));
  const [fmSuggestion, setFmSuggestion] = useState("");

  useEffect(() => {
    dispatch(fetchCompUnits(relocationCaseId));
    setFmSuggestion("A note would appear here from the Google Form submission with any helpful suggestions from the submitter.");
  }, []);

  useEffect(() => {
    if (moreUnitOptionsDisabled) {
      setOptions(generateOptionsLimit(relocationCaseComparableUnits));
    } else {
      setOptions(generateOptions(relocationCaseComparableUnits));
    }
  }, [relocationCaseComparableUnits]);

  useEffect(() => {
    if (relocationRequest?.attributes) setFmSuggestion(relocationRequest?.attributes.unit_candidates);
  }, [relocationRequest]);

  return (
    <div className={styles.body}>
      <div className={styles.Title}>Unit Options</div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.SubTitle1}>Suggestion from FM submission form</div>
          <div className={styles.SubTextGray}>{fmSuggestion !== "" ? fmSuggestion : "N/A"}</div>
          <div className={styles.SubTitle2}>Select Options for Guest</div>
          <div className={styles.SubTextGray}>
            {(moreOtherOptionsDisabled || moreHomesOptionsDisabled) && (
              <AlertComponent
                text={
                  <>
                    You cannot have more than {moreOtherOptionsDisabled ? `${OTHER_UNIT_OPTION_LIMIT} comparable units options` : ""}
                    {moreHomesOptionsDisabled && moreOtherOptionsDisabled ? " and " : ""}
                    {moreHomesOptionsDisabled ? `${HOMES_UNIT_OPTION_LIMIT} home available options` : ""} per case.
                  </>
                }
                className="error"
                iconSize="30"
                setShowAlert={() => dispatch(hideUnitCountAlert())}
              />
            )}
            {showInactiveUnitOptionSelected && (
              <AlertComponent
                text={<>This unit's display is not set to be active, please select another unit</>}
                className="error"
                iconSize="30"
                setShowAlert={() => dispatch(hideUnitAvailableAlert())}
              />
            )}
          </div>
          {options.map((option, index) => {
            return <UnitOption item={option} index={index + 1} key={`${option.id}${index}-${option.updatedAt}`} />;
          })}
        </>
      )}
    </div>
  );
};
