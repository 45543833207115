import React, {FC} from "react";
import {useSelector} from "react-redux";
import localStyles from "../WrapUpFinances.module.scss";
import {FinancesColumn} from "../../finances-column/FinancesColumn";
import {amountFinancesContent, originalFinancesTable, urlFinancesContent} from "../../../utils";
import {FrontendConfiguration} from "@common/configuration";
import {RootState} from "../../../store/store";

interface RegularFinancesProps {
  twoColumns: boolean;
}

export const RegularFinances: FC<RegularFinancesProps> = ({twoColumns}) => {
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;

  // finances
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const snapshotOriginPreResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPre) as any;
  const snapshotOriginPostResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPost) as any;

  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const {url} = FrontendConfiguration.adminConfig();

  const financeCategories = ["rent", "fees", "tp", "taxes", "total"];

  const getPrimaryAmount = (category: string) => {
    if (isCaseOpen) {
      return currentOriginResData[category];
    } else {
      return snapshotOriginPostResData[category];
    }
  };

  const getSecondaryAmount = (category: string) => {
    if (isCaseOpen) {
      return currentOriginResData[category] - snapshotOriginPreResData?.[category];
    } else {
      return snapshotOriginPostResData[category] - snapshotOriginPreResData?.[category];
    }
  };

  const unusedFinancesReference = {} as any;
  financeCategories.forEach((category) => {
    // @ts-ignore
    unusedFinancesReference[category] = {
      primary: {amount: getPrimaryAmount(category)},
      secondary: {amount: getSecondaryAmount(category), wrapper: "(+/-)"},
    };
  });
  unusedFinancesReference.total.secondary.bold = true;

  return (
    <div className={twoColumns ? localStyles.financesTableTwoColumns : ""}>
      {originalFinancesTable(snapshotOriginPreResData, !twoColumns)}

      {twoColumns ? (
        <FinancesColumn
          showTitles={false}
          leftMarginContent={true}
          header={"Unused Nights Removed"}
          rows={[
            {title: "LISTING", content: urlFinancesContent(currentOriginResData.unitName, `${urlVacasaCom}/unit/${currentOriginResData.unitId}`)},
            {
              title: "RES ID",
              content: urlFinancesContent(currentOriginResData.resId, `${url}/admin/dashboard/reservation/${currentOriginResData.resId}`),
            },
            {title: "RENT", content: amountFinancesContent(unusedFinancesReference.rent.primary, unusedFinancesReference.rent.secondary)},
            {title: "FEES", content: amountFinancesContent(unusedFinancesReference.fees.primary, unusedFinancesReference.fees.secondary)},
            {title: "TP", content: amountFinancesContent(unusedFinancesReference.tp.primary, unusedFinancesReference.tp.secondary)},
            {title: "TAXES", content: amountFinancesContent(unusedFinancesReference.taxes.primary, unusedFinancesReference.taxes.secondary)},
            {title: "TOTAL", content: amountFinancesContent(unusedFinancesReference.total.primary, unusedFinancesReference.total.secondary)},
          ]}
        />
      ) : null}
    </div>
  );
};
