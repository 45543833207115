import React, {FC} from "react";
import {BsExclamationTriangle} from "react-icons/bs";
import styles from "./CaseGuestDetails.module.scss";
import cx from "classnames";

interface CaseGuestDetailsProps {
  name: string;
  email: string;
  phone: string;
  checkingInTime: string;
  checkingInWarning: boolean;
}

export const CaseGuestDetails: FC<CaseGuestDetailsProps> = ({name, email, phone, checkingInTime, checkingInWarning}) => {
  return (
    <div className={styles.caseGuestDetails}>
      <div className={styles.caseGuestDetailsTitle}>Guest Details</div>
      <div className={styles.caseGuestDetailsContainer}>
        <div className={styles.caseGuestDetailsContainerItem}>
          <div className={styles.caseGuestDetailsContainerItemTitle}>Name</div>
          <div className={styles.caseGuestDetailsContainerItemDetail}>{name}</div>
        </div>

        <div className={styles.caseGuestDetailsContainerItem}>
          <div className={styles.caseGuestDetailsContainerItemTitle}>Checking in</div>

          <div
            className={cx(styles.caseGuestDetailsContainerItemDetail, checkingInWarning ? styles.caseGuestDetailsContainerItemDetailWarning : null)}
          >
            <span style={{verticalAlign: "middle"}}>
              {checkingInWarning && <BsExclamationTriangle style={{verticalAlign: "middle"}} />}
              {checkingInTime}
            </span>
          </div>
        </div>

        <div className={styles.caseGuestDetailsContainerItem}>
          <div className={styles.caseGuestDetailsContainerItemTitle}>Phone</div>
          <div className={styles.caseGuestDetailsContainerItemDetail}>{phone}</div>
        </div>

        <div className={styles.caseGuestDetailsContainerItem}>
          <div className={styles.caseGuestDetailsContainerItemTitle}>Email</div>
          <div className={styles.caseGuestDetailsContainerItemDetail}>{email}</div>
        </div>
      </div>
    </div>
  );
};
