import React from "react";
import {FormSubmissionContainer} from "../../components/index";
import {AlertComponent} from "../../components";
import styles from "./FormSubmissionView.module.scss";
import {BackSectionV2} from "../../components/back-section-v2/BackSectionV2";
type FormSubmissionProps = {
  title: string;
};

export const FormSubmissionView = ({title}: FormSubmissionProps) => {
  return (
    <>
      <BackSectionV2 text="Submission Form" />
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.titleSection}>
            <div className={styles.innerContainerTitle}>{title}</div>
          </div>
          <div className={styles.formView}>
            <AlertComponent
                text={<>
                    Ensure you have reviewed this <a href='https://compass.vacasa.com/page/5716' target="_blank" rel="noopener noreferrer"> compass documentation </a> before submitting any relocation requests for Airbnb or VRBO reservations.
                </>}
                className="warning"
                showCloseIcon = {false}
                setShowAlert={() => {}}
            />
            <FormSubmissionContainer />
          </div>
        </div>
      </div>
    </>
  );
};
