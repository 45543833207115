import React, {FC} from "react";
import {RelocationService} from "@common/services";
import {WrapUpFinances, WrapUpGuestSatisfactionRefund, WrapUpOwnerBilling, WrapUpFinalDetails} from "../index";

interface WrapUpCancelAndFscProps {}

export const WrapUpCancelAndFsc: FC<WrapUpCancelAndFscProps> = () => {
  return (
    <>
      <WrapUpFinances fmConclusionSelected={RelocationService.RESOLUTION_FSC} />
      <WrapUpOwnerBilling fmConclusionSelected={RelocationService.RESOLUTION_FSC} />
      <WrapUpGuestSatisfactionRefund selectedOption={RelocationService.RESOLUTION_FSC} />
      <WrapUpFinalDetails />
    </>
  );
};
