import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RelocationService} from "@common/services";
import {datadogLogs} from "@datadog/browser-logs";

// Service Singletons
const relocationService = RelocationService.getInstance();

export const fetchRelocationCaseFinances = createAsyncThunk("caseFinance/fetchRelocationCaseFinances", async (id: number) => {
    const relocationCaseResponse = await relocationService.getRelocationCaseById(id);
    const relocationCaseComparableUnitResponse = await relocationService.getRelocationCaseComparableUnitById(
        relocationCaseResponse.data.attributes.selected_relocation_case_comparable_unit_id || 0
    );
    const relocationCaseComparableUnitSplitStayResponse = await relocationService.getRelocationCaseComparableUnitSplitStays([
        relocationCaseComparableUnitResponse?.data?.id | 0,
    ]);

    return {
        relocationCase: relocationCaseResponse.data,
        comparableUnit: relocationCaseComparableUnitResponse?.data,
        splitStays: relocationCaseComparableUnitSplitStayResponse?.data,
    };
});

export const updateRelocationCaseFinances = createAsyncThunk("caseFinance/updateRelocationCaseFinances", async (payload: {id: number; data: any}) => {
    return await relocationService.updateRelocationCaseFinances(payload.id, payload.data);
});

const caseFinanceSlice = createSlice({
    name: "caseFinanceSlice",
    initialState: {
        relocationCase: null,
        comparableUnit: null,
        splitStays: [],
        isLoading: true,
        isUpdateLoading: false,
        isUpdateSuccess: false,
        isUpdateError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRelocationCaseFinances.fulfilled, (state: any, {payload}) => {
                state.relocationCase = payload.relocationCase;
                state.comparableUnit = payload.comparableUnit;
                state.splitStays = payload.splitStays;
                state.isLoading = false;
            })
            .addCase(updateRelocationCaseFinances.pending, (state: any, {payload}) => {
                state.isUpdateLoading = true;
            })
            .addCase(updateRelocationCaseFinances.rejected, (state: any, {payload}) => {
                state.isUpdateError = true;
                state.isUpdateLoading = false;
            })
            .addCase(updateRelocationCaseFinances.fulfilled, (state: any, {payload}) => {
                state.isUpdateLoading = false;
                state.isUpdateSuccess = true;
            });
    },
});

export default caseFinanceSlice.reducer;
