import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit, ValidateUnitError} from "../index";
import {FormSubmissionSteps, REDUX_ACTIONS} from "../../../constants";
import {closeValidateUnitCodeFailedAlert, refreshStepPath, updateFormData, updateStep, validatesUnitCode} from "../../../store/formSubmissionSlice";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {UnitCodeStepSchema} from "../schemas/formSchemas";
import {CircularProgress} from "@material-ui/core";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./UnitCodeStep.module.scss";
import cx from "classnames";

type UnitCodeStepProps = {
  title: string;
  readOnly: boolean;
};

export const UnitCodeStep = ({title, readOnly}: UnitCodeStepProps) => {
  //Redux Stat
  const dispatch = useAppDispatch();
  const {isValidatingUnit, validateUnitFailed} = useSelector((state: RootState) => state.formSubmission);
  const {legacyUnitCode, relocationRequestReasonId, extraNotes, unitCandidates} = useSelector((state: RootState) => state.formSubmission.formData);
  const relocationRequestReasons = useSelector((state: RootState) => state.formSubmission.relocationRequestReasons);

  //Helpers
  const relocationRequestReasonName =
    relocationRequestReasonId && relocationRequestReasons.find((reason) => reason.id === parseInt(relocationRequestReasonId))?.attributes?.name;

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.UNIT_CODES} previousStep={FormSubmissionSteps.RESUME} />
      {!readOnly && (
        <p>In order to properly prioritize this move, each Last Minute FM will need to be reported separately, and the Reservation ID is required.</p>
      )}
      <Formik
        initialValues={{
          legacyUnitCode,
          relocationRequestReasonId,
          extraNotes,
          unitCandidates,
        }}
        onSubmit={async (values) => {
          const response = await dispatch(validatesUnitCode(values.legacyUnitCode));
          if (response.meta.requestStatus === REDUX_ACTIONS.REJECTED) {
            return;
          }
          dispatch(closeValidateUnitCodeFailedAlert());
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.UNAVAILABLE_DATES, previousStep: FormSubmissionSteps.UNIT_CODES}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.UNAVAILABLE_DATES, buttonAction: "next"}));
        }}
        validationSchema={UnitCodeStepSchema}
      >
        {({values, errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {/* Legacy Unit Code Section */}
              <label htmlFor="legacyUnitCode">What is the Unit Code? {!readOnly && <span className={generalStyles.obligatory}>*</span>}</label>
              {!readOnly ? (
                <>
                  <Field type="text" name="legacyUnitCode" value={values.legacyUnitCode} onChange={handleChange} />
                  {touched.legacyUnitCode && errors.legacyUnitCode && <p className={generalStyles.validationError}>{errors.legacyUnitCode}</p>}
                  <p className={styles.noteText}>
                    <strong>NOTE:</strong> Please enter the Vacasa unit code. If you are reporting moves for multiple units, please submit each unit
                    separately.
                  </p>
                </>
              ) : (
                <span className={generalStyles.response}>{legacyUnitCode}</span>
              )}

              {/* Request Reason Section */}
              <label htmlFor="relocationRequestReasonId">
                Please select the reason for the move {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="select" name="relocationRequestReasonId" onChange={handleChange}>
                    <option value="">--Select--</option>
                    {relocationRequestReasons.map((reason) => (
                      <option key={reason.id} value={reason.id}>
                        {reason.attributes.name}
                      </option>
                    ))}
                  </Field>
                  {touched.relocationRequestReasonId && errors.relocationRequestReasonId && (
                    <p className={generalStyles.validationError}>{errors.relocationRequestReasonId}</p>
                  )}
                </>
              ) : (
                <span className={generalStyles.response}>{relocationRequestReasonName}</span>
              )}

              {/* Reason Extra Notes Section */}
              <label htmlFor="extraNotes">
                Provide any additional notes regarding the reason for the move here. {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="textarea" name="extraNotes" value={values.extraNotes} onChange={handleChange} />
                  {touched.extraNotes && errors.extraNotes && <p className={generalStyles.validationError}>{errors.extraNotes}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{extraNotes}</span>
              )}

              {/* Unit Candidates Section */}
              <label htmlFor="unitCandidates">
                You know your region and homes better than anyone. Are there good comps that we should look at moving this Guest to?
              </label>
              {!readOnly ? (
                <Field as="textarea" name="unitCandidates" value={values.unitCandidates} onChange={handleChange} />
              ) : (
                <span className={generalStyles.response}>{unitCandidates}</span>
              )}

              {
                /* Unit Validation Section */
                validateUnitFailed && <ValidateUnitError />
              }

              {/* Buttons Section */}
              {!readOnly && (
                <div className={generalStyles.formButtons}>
                  <BackButton
                    isProcessing={isValidatingUnit}
                    step={FormSubmissionSteps.NON_URGENT_FM}
                    previousStep={FormSubmissionSteps.UNIT_CODES}
                  />
                  <button
                    disabled={isValidatingUnit}
                    type="submit"
                    className={cx(generalStyles.stepButton, generalStyles.nextButton, isValidatingUnit && generalStyles.disabledButton)}
                  >
                    Next
                  </button>
                  {isValidatingUnit && (
                    <div className={generalStyles.spinnerValidation}>
                      <CircularProgress color="inherit" size={25} />
                      <span className={generalStyles.validatingMessage}>Please wait, we are validating the Unit Code.</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
