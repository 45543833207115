import React from "react";
import {Button} from "@vacasa/react-components-lib";
import {useAppDispatch, RootState} from "../../../store/store";
import {updateStep} from "../../../store/formSubmissionSlice";
import {useSelector} from "react-redux";
import {FormSubmissionSteps} from "../../../constants";
import {CircularProgress} from "@material-ui/core";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";

type BackButtonProps = {
  step: number;
  previousStep: number;
  onProcessing?: boolean;
  onSubmit?: () => void;
  alternativeText?: string;
};

export const SubmitButton = ({step, previousStep, onSubmit, onProcessing = false, alternativeText = "Submit"}: BackButtonProps) => {
  const dispatch = useAppDispatch();
  const {currentStep} = useSelector((state: RootState) => state.formSubmission);

  const handleSubmit = async () => {
    if (currentStep === FormSubmissionSteps.RESUME) {
      onSubmit?.();
      return;
    }
    step && dispatch(updateStep({currentStep: step, previousStep}));
    onSubmit?.();
  };

  return (
    <>
      <Button
        disabled={onProcessing}
        type="button"
        customClass={cx(generalStyles.stepButton, generalStyles.nextButton, onProcessing && generalStyles.disabledButton)}
        onClick={handleSubmit}
      >
        {alternativeText}
      </Button>
      {onProcessing && (
        <div className={generalStyles.spinnerValidation}>
          <CircularProgress color="inherit" size={25} />
          <span className={generalStyles.validatingMessage}>Please wait, we are submitting the form.</span>
        </div>
      )}
    </>
  );
};
