import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit} from "../index";
import {FormSubmissionSteps} from "../../../constants";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {UnavailableDatesStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./UnavailableDatesStep.module.scss";
import cx from "classnames";

type UnavailableDatesStepProps = {
  title: string;
  readOnly: boolean;
};

export const UnavailableDatesStep = ({title, readOnly}: UnavailableDatesStepProps) => {
  const dispatch = useAppDispatch();
  const {startDate, moveGuest, endDate} = useSelector((state: RootState) => state.formSubmission.formData);

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.UNAVAILABLE_DATES} previousStep={FormSubmissionSteps.RESUME} />
      <p className={styles.datesQuestion}>
        Starting on what date should reservations be moved? {!readOnly && <span className={generalStyles.obligatory}>*</span>}
      </p>
      <Formik
        initialValues={{
          startDate: '',
          moveGuest,
          endDate: '',
        }}
        onSubmit={(values) => {
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.BILLING, previousStep: FormSubmissionSteps.UNAVAILABLE_DATES}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.BILLING, buttonAction: "next"}));
        }}
        validationSchema={UnavailableDatesStepSchema}
      >
        {({values, errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {!readOnly && (
                <span>
                  <p className={styles.noteText}>
                    <strong>NOTE:</strong> If we have a res checking in on this date, we will move that Guest to a new home.
                  </p>
                </span>
              )}

              {/* Start Date Section */}
              {!readOnly ? (
                <>
                  <input type="date" name="startDate" value={values.startDate} onChange={handleChange} onKeyDown={(e) => e.preventDefault()} />
                  {touched.startDate && errors.startDate && <p className={generalStyles.validationError}>{errors.startDate}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{startDate}</span>
              )}

              {/* Move Guest Section */}
              <label className={styles.moveGuestQuestion} htmlFor="moveGuest">
                If a Guest is in-home on this date, will they need to be moved, or will we only need to move the Guest after?{" "}
                {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly && (
                <span>
                  <p className={styles.noteText}>
                    <strong>Example:</strong> You just entered “Aug 15” as the start date, but we have a Guest in home from Aug 13-17. If you mark
                    “Yes” to this question, then we will move the Guest. If you mark “No” to this question, we will only move reservations on Aug 18.
                  </p>
                </span>
              )}
              {!readOnly ? (
                <>
                  <div className={generalStyles.radioOptions} role="group" aria-labelledby="my-radio-group">
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="moveGuest" value="yes" onChange={handleChange} />
                      <span>Yes - move the in-home Guest</span>
                    </div>
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="moveGuest" value="no" onChange={handleChange} />
                      <span>No - move the following Guests, but these Guests are OK to stay in home.</span>
                    </div>
                  </div>
                  {touched.moveGuest && errors.moveGuest && <p className={generalStyles.validationError}>{errors.moveGuest}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{moveGuest}</span>
              )}

              {/* End Date Section */}
              <p className={styles.datesQuestion}>
                On what date are moves no longer required? {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </p>
              {!readOnly && (
                <p>
                  <strong>NOTE:</strong>
                  <span className={generalStyles.note}>
                    {" "}
                    If we have a reservation checking in on this date, we will NOT move that Guest to a new home.
                  </span>
                </p>
              )}
              {!readOnly ? (
                <>
                  <input type="date" name="endDate" value={values.endDate} onChange={handleChange} onKeyDown={(e) => e.preventDefault()} />
                  {touched.endDate && errors.endDate && <p className={generalStyles.validationError}>{errors.endDate}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{endDate}</span>
              )}
            </div>

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton step={FormSubmissionSteps.UNIT_CODES} previousStep={FormSubmissionSteps.UNAVAILABLE_DATES} />
                <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                  Next
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
