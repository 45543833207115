import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HolidaysType, HolidaysAttributesType} from "@common/typing";
import {RelocationService} from "@common/services";

export const fetchHolidays = createAsyncThunk("holidays/fetchHolidays", async () => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.getHolidays();
    return response.data;
});

export const addHoliday = createAsyncThunk("holidays/addHoliday", async (data: HolidaysType) => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.createHoliday(data);
    return response.data;
});

export const deleteHoliday = createAsyncThunk("holidays/deleteHoliday", async (id: number) => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.deleteHoliday(id);
    return response.data;
});

export interface HolidaysSliceState {
    list: Array<HolidaysAttributesType>;
    isLoading: boolean;
    isLoadingAddHoliday: boolean;
    isModalVisible: boolean;
}

const initialState: HolidaysSliceState = {
    list: [],
    isLoading: false,
    isLoadingAddHoliday: false,
    isModalVisible: false,
};

const holidaysSlice = createSlice({
    name: "holidays",
    initialState,
    reducers: {
        showAddHolidayModal: (state) => {
            state.isModalVisible = true;
        },
        hideAddHolidayModal: (state) => {
            state.isModalVisible = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHolidays.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(fetchHolidays.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.list = payload;
                state.isLoading = false;
            })
            .addCase(addHoliday.pending, (state, action) => {
                state.isLoading = true;
                state.isLoadingAddHoliday = true;
            })
            .addCase(addHoliday.fulfilled, (state, action) => {
                state.isLoadingAddHoliday = false;
                state.isLoading = false;
                state.isModalVisible = false;
            })
            .addCase(deleteHoliday.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(deleteHoliday.fulfilled, (state, action) => {
                state.isLoading = false;
            });
    },
});

export const {showAddHolidayModal, hideAddHolidayModal} = holidaysSlice.actions;

export default holidaysSlice.reducer;
