import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {urlFinancesContent, amountFinancesContent, carouselDataType, carouselDataToItems, originalFinancesTable} from "../../../utils";
import {FrontendConfiguration} from "@common/configuration";
import {FinancesCarousel, FinancesColumn} from "../../index";
import localStyles from "../WrapUpFinances.module.scss";

interface NewUnitFinancesProps {}

export const NewUnitFinances: FC<NewUnitFinancesProps> = () => {
  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const {url} = FrontendConfiguration.adminConfig();

  // finances
  const snapshotOriginPreResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPre) as any;
  const snapshotOriginPostResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPost) as any;
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const midStayTotals = useSelector((state: RootState) => state.wrapUp.finances.midStayFMTotals) as any;

  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;

  const originResFinanceReference = isMidStayFM && !isCaseOpen ? snapshotOriginPostResData : currentOriginResData;
  const midStayCarousel: carouselDataType[] = [
    {
      title: "MSFM Totals (Single Unit)",
      listingLink: {url: `${urlVacasaCom}/unit/${destinationResData[0]?.unitId}`, urlText: destinationResData[0]?.unitName},
      resIdLink: {url: `${url}/admin/dashboard/reservation/${destinationResData[0]?.resId}`, urlText: destinationResData[0]?.resId},
      rent: {
        primaryAmount: {amount: midStayTotals?.rent - snapshotOriginPreResData?.rent, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.rent, wrapper: "="},
      },
      fees: {
        primaryAmount: {amount: midStayTotals?.fees - snapshotOriginPreResData?.fees, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.fees, wrapper: "="},
      },
      tp: {
        primaryAmount: {amount: midStayTotals?.tp - snapshotOriginPreResData?.tp, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.tp, wrapper: "="},
      },
      taxes: {
        primaryAmount: {amount: midStayTotals?.taxes - snapshotOriginPreResData?.taxes, wrapper: "+/-"},
        secondaryAmount: {amount: midStayTotals?.taxes, wrapper: "="},
      },
      total: {
        primaryAmount: {amount: midStayTotals?.total - snapshotOriginPreResData?.total, wrapper: "+/-", bold: true},
        secondaryAmount: {amount: midStayTotals?.total, wrapper: "="},
      },
    },
    {
      title: "Unused Nights Removed",
      listingLink: {url: `${urlVacasaCom}/unit/${snapshotOriginPreResData?.unitId}`, urlText: snapshotOriginPreResData?.unitName},
      resIdLink: {url: `${url}/admin/dashboard/reservation/${snapshotOriginPreResData?.resId}`, urlText: snapshotOriginPreResData?.resId},
      rent: {
        primaryAmount: {amount: snapshotOriginPreResData?.rent - originResFinanceReference?.rent},
        secondaryAmount: {amount: -originResFinanceReference?.rent, wrapper: "(+/-)"},
      },
      fees: {
        primaryAmount: {amount: snapshotOriginPreResData?.fees - originResFinanceReference?.fees},
        secondaryAmount: {amount: -originResFinanceReference?.fees, wrapper: "(+/-)"},
      },
      tp: {
        primaryAmount: {amount: snapshotOriginPreResData?.tp - originResFinanceReference?.tp},
        secondaryAmount: {amount: -originResFinanceReference?.tp, wrapper: "(+/-)"},
      },
      taxes: {
        primaryAmount: {amount: snapshotOriginPreResData?.taxes - originResFinanceReference?.taxes},
        secondaryAmount: {amount: -originResFinanceReference?.taxes, wrapper: "(+/-)"},
      },
      total: {
        primaryAmount: {amount: snapshotOriginPreResData?.total - originResFinanceReference?.total},
        secondaryAmount: {amount: -originResFinanceReference?.total, wrapper: "(+/-)", bold: true},
      },
    },
    {
      title: "New Res",
      listingLink: {url: `${urlVacasaCom}/unit/${destinationResData[0]?.unitId}`, urlText: destinationResData[0]?.unitName},
      resIdLink: {url: `${url}/admin/dashboard/reservation/${destinationResData[0]?.resId}`, urlText: destinationResData[0]?.resId},
      rent: {primaryAmount: {amount: destinationResData[0]?.rent}, secondaryAmount: {amount: null}},
      fees: {primaryAmount: {amount: destinationResData[0]?.fees}, secondaryAmount: {amount: null}},
      tp: {primaryAmount: {amount: destinationResData[0]?.tp}, secondaryAmount: {amount: null}},
      taxes: {primaryAmount: {amount: destinationResData[0]?.taxes}, secondaryAmount: {amount: null}},
      total: {primaryAmount: {amount: destinationResData[0]?.total}, secondaryAmount: {amount: null}},
    },
  ];

  return (
    <div className={localStyles.financesTableTwoColumns}>
      {originalFinancesTable(snapshotOriginPreResData, false)}

      {!isMidStayFM ? (
        <FinancesColumn
          leftMarginContent={true}
          header={"New Res"}
          wideBox={false}
          showTitles={false}
          rows={[
            {
              title: "LISTING",
              content: urlFinancesContent(destinationResData[0]?.unitName, `${urlVacasaCom}/unit/${destinationResData[0]?.unitId}`),
            },
            {
              title: "RES ID",
              content: urlFinancesContent(destinationResData[0]?.resId, `${url}/admin/dashboard/reservation/${destinationResData[0]?.resId}`),
            },
            {
              title: "RENT",
              content: amountFinancesContent(
                {amount: destinationResData[0]?.rent},
                {amount: destinationResData[0]?.rent - snapshotOriginPreResData?.rent, wrapper: "(+/-)"}
              ),
            },
            {
              title: "FEES",
              content: amountFinancesContent(
                {amount: destinationResData[0]?.fees},
                {amount: destinationResData[0]?.fees - snapshotOriginPreResData?.fees, wrapper: "(+/-)"}
              ),
            },
            {
              title: "TP",
              content: amountFinancesContent(
                {amount: destinationResData[0]?.tp},
                {amount: destinationResData[0]?.tp - snapshotOriginPreResData?.tp, wrapper: "(+/-)"}
              ),
            },
            {
              title: "TAXES",
              content: amountFinancesContent(
                {amount: destinationResData[0]?.taxes},
                {amount: destinationResData[0]?.taxes - snapshotOriginPreResData?.taxes, wrapper: "(+/-)"}
              ),
            },
            {
              title: "TOTAL",
              content: amountFinancesContent(
                {amount: destinationResData[0]?.total},
                {amount: destinationResData[0]?.total - snapshotOriginPreResData?.total, wrapper: "(+/-)", bold: true}
              ),
            },
          ]}
        />
      ) : (
        <FinancesCarousel items={carouselDataToItems(midStayCarousel)} showTitles={false} />
      )}
    </div>
  );
};
