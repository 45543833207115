import React from "react";
import {FormSubmissionSteps} from "../../../constants";
import {BackButton, SubmitButton} from "../index";
import generalStyles from "../form-submission/FormSubmission.module.scss";

type CompleteOwnerBilledStepProps = {
  title: string;
};

export const OwnerBilledStep = ({title}: CompleteOwnerBilledStepProps) => {
  return (
    <div className={generalStyles.stepContainer}>
      <h3>{title}</h3>
      <p>If you are ready to submit, please continue to review step and click “Submit.”</p>
      <div className={generalStyles.formButtons}>
        <BackButton step={FormSubmissionSteps.BILLING} previousStep={FormSubmissionSteps.OWNER_BILLED} />
        <SubmitButton step={FormSubmissionSteps.RESUME} previousStep={FormSubmissionSteps.OWNER_BILLED} alternativeText="Next" />
      </div>
    </div>
  );
};
