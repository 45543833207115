import React, {FC} from "react";
import {Spinner} from "..";
import {QueueTableItem} from "../queue-table-item/QueueTableItem";
import TablePagination from "@material-ui/core/TablePagination";
import styles from "./QueueTable.module.scss";
import {BsArrowUp} from "react-icons/bs";
import {defaultFilters, relocationCreatedAtDesc, relocationPriorityDesc, relocationTaskFilter} from "../../constants";
import {FloatSearch} from "../float-search/FloatSearch";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {Tooltip} from "@vacasa/react-components-lib";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {togglePriorityFilter, toggleCreatedAtFilter, toggleAssignedToFilter, updatePaginationFilter, toggleTaskFilter} from "../../store/queueSlice";
import {FilterPriority} from "../filter-priority/FilterPriority";
import {FilterCreatedAt} from "../filter-created-at/FilterCreatedAt";
import {FilterAssign} from "../filter-assign/FilterAssign";
import {FilterTask} from "../filter-task/FilterTask";

interface QueueTableProps {
  relocationCases: Array<any>;
  isLoading: boolean;
  isEmpty: boolean;
  isOwnershipSearchAllowed?: boolean;
  handleUpdate: any;
}

// Pagination Styling
const useStyles = makeStyles(() => ({
  pagination: {
    "& .MuiSelect-select.MuiSelect-select": {
      backgroundColor: "#f5f7f8",
      borderRadius: "6px",
      border: "1px solid #B2C2C8",
      width: "60px",
      padding: "9px",
    },
    "& .MuiTablePagination-selectRoot": {
      marginLeft: "8px",
      marginRight: "23px",
    },
    "& .MuiTablePagination-actions": {
      flexShrink: 0,
      marginLeft: "7px",
    },
    "& .MuiTablePagination-select": {
      textAlign: "right",
      paddingLeft: "8px",
      paddingRight: "24px",
      textAlignLast: "left",
    },
    "& .MuiTablePagination-toolbar": {
      paddingRight: "28px",
    },
  },
}));

export const QueueTable: FC<QueueTableProps> = ({
  relocationCases = [],
  isLoading = false,
  isEmpty = false,
  isOwnershipSearchAllowed = false,
  handleUpdate = () => {},
}) => {
  // state
  const dispatch = useAppDispatch();
  const showPriorityFilter = useSelector((state: RootState) => state.queue.filters.priority.isVisible);
  const showCreatedAtFilter = useSelector((state: RootState) => state.queue.filters.createdAt.isVisible);
  const showAssignedToFilter = useSelector((state: RootState) => state.queue.filters.assignedTo.isVisible);
  const showTaskFilter = useSelector((state: RootState) => state.queue.filters.task.isVisible);
  const currentPriorityFilter = useSelector((state: RootState) => state.queue.filters.priority.value);
  const currentCreatedAtFilter = useSelector((state: RootState) => state.queue.filters.createdAt.value);
  const currentAssignedToFilter = useSelector((state: RootState) => state.queue.filters.assignedTo.value);
  const currentTaskFilter = useSelector((state: RootState) => state.queue.filters.task.value);
  const pageCount = useSelector((state: RootState) => state.queue.filters.pagination.count);
  const pageNumber = useSelector((state: RootState) => state.queue.filters.pagination.number);
  const pageSize = useSelector((state: RootState) => state.queue.filters.pagination.size);

  const classes = useStyles();

  // Event generators
  const getPriorityEvents = () =>
    relocationPriorityDesc.map((priority) => ({
      children: <FilterPriority priority={priority} onClose={() => dispatch(togglePriorityFilter())} />,
    }));
  const getCreatedAtEvents = () =>
    relocationCreatedAtDesc.map((createdAt) => ({
      children: <FilterCreatedAt createdAt={createdAt} onClose={() => dispatch(toggleCreatedAtFilter())} />,
    }));
  const getAssignedToEvents = () => {
    return [{children: <FilterAssign placeholder={"Assigned to"} filterName={"assignedTo"} />}];
  };
  const getTaskEvents = () =>
    relocationTaskFilter.map((task) => ({
      children: <FilterTask task={task} onClose={() => dispatch(toggleTaskFilter())} />,
    }));

  // Handlers
  const handleChangePage = (event: any, newPage: number) => {
    const pageNumber = newPage + 1;
    dispatch(updatePaginationFilter({pageNumber}));
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const pageSize = event.target.value;
    dispatch(updatePaginationFilter({pageNumber: defaultFilters.pageNumber, pageSize}));
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/** FILTERS */}
      <div className={cx(styles.headerCases)}>
        {/** Checkbox Placeholders ** */}
        <div className={styles.headerItemSearchable}></div>

        {/** PRIORITY FILTER **/}
        <div className={styles.headerItemSearchable}>
          <Tooltip message="Click here to filter by priority">
            <div onClick={() => dispatch(togglePriorityFilter())}>
              <span className={styles.adjustPriorityLeft}>Priority {currentPriorityFilter && "*"}</span>{" "}
              <BsArrowUp size={20} style={{margin: "2px 2px -5px 18px", cursor: "pointer"}} />
            </div>
          </Tooltip>
          {showPriorityFilter && <FloatSearch events={getPriorityEvents()} invoker="priority" onClose={() => dispatch(togglePriorityFilter())} />}
        </div>

        {/** Unit, Reservation **/}
        <div className={styles.headerItem}>Unit Code</div>
        <div className={styles.headerItem}>Res ID</div>

        {/** Create At Filter **/}
        <div className={styles.headerItemSearchable}>
          <Tooltip message="Click here to filter by Date">
            <div onClick={() => dispatch(toggleCreatedAtFilter())}>
              <span> Case Created {currentCreatedAtFilter && "*"}</span>{" "}
            </div>
          </Tooltip>
          {showCreatedAtFilter && <FloatSearch events={getCreatedAtEvents()} invoker="createdAt" onClose={() => dispatch(toggleCreatedAtFilter())} />}
        </div>

        {/** Task Filter **/}
        <div className={styles.headerItemSearchable}>
          <Tooltip message="Click here to filter by Task">
            <div onClick={() => dispatch(toggleTaskFilter())}>
              <span> Task {currentTaskFilter && "*"}</span>{" "}
            </div>
          </Tooltip>
          {showTaskFilter && (
            <FloatSearch events={getTaskEvents()} invoker="task" onClose={() => dispatch(toggleTaskFilter())} customStyle={{left: 0}} />
          )}
        </div>

        {/** Assigned To Filter **/}
        {isOwnershipSearchAllowed ? (
          <div className={styles.headerItemSearchable}>
            <Tooltip message="Click here to filter by Assignee">
              <div onClick={() => dispatch(toggleAssignedToFilter())}>
                <span>Ownership {currentAssignedToFilter && "*"}</span>
              </div>
            </Tooltip>
            {showAssignedToFilter && (
              <FloatSearch events={getAssignedToEvents()} invoker="assignedTo" onClose={() => dispatch(toggleAssignedToFilter())} />
            )}
          </div>
        ) : (
          <div className={styles.headerItem}>Ownership</div>
        )}

        <div className={styles.headerItem}></div>
      </div>

      {/** Render Relocation Cases */}
      {isLoading ? (
        <Spinner />
      ) : isEmpty ? (
        <h3 className={styles.noResults}>No results</h3>
      ) : (
        relocationCases.map((relocationCase, index: number) => <QueueTableItem key={index} item={relocationCase} handleUpdate={handleUpdate} />)
      )}

      {/** Pagination */}
      {pageCount > 1 && (
        <div className={styles.paginationContainer}>
          <TablePagination
            component="div"
            count={pageCount}
            page={pageNumber - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[10, 20]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </div>
      )}
    </>
  );
};
