import React from "react";
import styles from "./BackSectionV3.module.scss";
import {BsArrowLeftShort, BsFillHouseFill} from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";
import {ArrowLeftOutlined, HomeOutlined} from "@ant-design/icons";

interface BackSectionProps {
  text: string;
  status?: number;
}

export const BackSectionV3: React.FC<BackSectionProps> = ({text, status}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.menuContainer}>
        <Button size={"large"} icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button size={"large"} icon={<HomeOutlined />} onClick={() => navigate("queue")}>
          Home
        </Button>
      </div>
    </>
  );
};
