import React, {FC, useState} from "react";
import {useSelector} from "react-redux";
import styles from "../wrap-up-component/WrapUp.module.scss";
import {AlertComponent, CurrencyComponentV2} from "..";
import {RelocationService} from "@common/services";
import {setGuestSatisfactionAmount, setGuestSatisfactionReason, updateGuestSatisfaction} from "../../store/wrapUpSlice";
import {fetchRelocationCaseHistory} from "../../store/caseHistorySlice";
import {fetchRelocationCase} from "../../store/caseProfileSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {guestSatisfactionRefundReasons, DEFAULT_REFUND_REASON, WrapUpOptions} from "../../constants";
import {datadogLogs} from "@datadog/browser-logs";
import {getUserInfo} from "../../utils";
import {CircularProgress} from "@material-ui/core";

interface WrapUpGuestSatisfactionRefundProps {
  selectedOption: number;
}

export const WrapUpGuestSatisfactionRefund: FC<WrapUpGuestSatisfactionRefundProps> = (props) => {
  const {selectedOption} = props;
  const dispatch = useAppDispatch();
  const {email: userEmail} = getUserInfo();

  // Case profiler store dependencies
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;

  // Wrap up store dependencies
  const caseGuestSatisfactionAmount = (useSelector((state: RootState) => state.caseProfile.relocationCase) as any).attributes.guest_refund_amount;
  const guestSatisfactionAmount = useSelector((state: RootState) => state.wrapUp.refund.amount) as any;
  const guestSatisfactionReason = useSelector((state: RootState) => state.wrapUp.refund.reason) as any;
  const isValidGuestRefundAmount = useSelector((state: RootState) => state.wrapUp.refund.isValid) as any;

  const [updatingGuestSatisfactionRefund, setUpdatingGuestSatisfactionRefund] = useState<boolean>(false);

  const reasonId = guestSatisfactionRefundReasons.find((x: any) => x.text === guestSatisfactionReason)?.id;
  const reasonSelected = reasonId ? reasonId : DEFAULT_REFUND_REASON;

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleAmount = (newAmount: number | null) => {
    dispatch(setGuestSatisfactionAmount(newAmount));
  };

  const handleReason = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setGuestSatisfactionReason(guestSatisfactionRefundReasons.find((x: any) => x.id == parseInt(e.currentTarget.value))?.text));
  };

  const saveGuestSatisfactionRefund = async () => {
    setShowAlert(false);
    if (isValidGuestRefundAmount) {
      try {
        setUpdatingGuestSatisfactionRefund(true);
        await updateGuestSatisfaction({id: relocationCase.id, userEmail: userEmail, guestRefundAmount: guestSatisfactionAmount});
        await dispatch(fetchRelocationCase(relocationCase.id));
        await dispatch(fetchRelocationCaseHistory(relocationCase.id));
        setShowAlert(true);
        setAlertMessage("Success! Guest Satisfaction Refund updated.");
        setAlertType("success");
      } catch (error) {
        datadogLogs.logger.error("Update Guest Satisfaction Refund", {error, relocation_case_id: relocationCase.id});
        setAlertMessage("Error! Guest Satisfaction Refund cannot be updated.");
        setAlertType("error");
        setShowAlert(true);
        throw error;
      } finally {
        setUpdatingGuestSatisfactionRefund(false);
      }
    }
  };

  const title = selectedOption === RelocationService.RESOLUTION_FSC ? "Guest Satisfaction Refund or FSC" : "Guest Satisfaction Refund";
  const subTitle =
    selectedOption === RelocationService.RESOLUTION_FSC
      ? "For FSC, please make sure to input the amount the guest has paid towards the reservation +10%"
      : "If a Guest satisfaction refund was issued, enter above.";

  return (
    <div className={selectedOption !== RelocationService.RESOLUTION_STAYED_IN_HOME ? styles.body : ""}>
      <div className={styles.title}>{title}</div>
      {showAlert && <AlertComponent text={<>{alertMessage}</>} className={alertType} iconSize="25" setShowAlert={setShowAlert} />}
      <div className={styles.containerItem}>
        <div>
          <div className={styles.divSeparator}>
            <span className={styles.containerItemTitle}>Amount</span>
          </div>
          <div>
            <div className={styles.containerItemDetail}>
              <CurrencyComponentV2 initialValue={caseGuestSatisfactionAmount} onChange={handleAmount} readOnly={false} />
              {!isValidGuestRefundAmount && <span className={styles.nonValid}> *required</span>}
              {!isCaseOpen && !updatingGuestSatisfactionRefund && (
                <span className={styles.contentSaveBtn} onClick={saveGuestSatisfactionRefund}>
                  Save
                </span>
              )}
              {updatingGuestSatisfactionRefund ? <CircularProgress color="inherit" size={25} className={styles.savingProgressText} /> : null}
            </div>
            <div className={styles.containerItemDetailLabel}>
              <label>{subTitle}</label>
            </div>
          </div>
        </div>
      </div>
      {[WrapUpOptions.CANCEL_AND_REFUND, WrapUpOptions.CANCEL_AND_FSC].includes(selectedOption) ? (
        <div className={styles.containerItem}>
          <div>
            <div className={styles.divSeparator}>
              <span className={styles.containerItemTitle}>Reason</span>
            </div>
            {!isCaseOpen ? (
              <div className={styles.containerItemDetail}>{guestSatisfactionReason}</div>
            ) : (
              <div>
                <div className={styles.containerItemDetail}>
                  <select value={reasonSelected} className={styles.selectBoxInternal} onChange={handleReason}>
                    {guestSatisfactionRefundReasons.map((option, index) => {
                      return (
                        <option value={option.id} key={index}>
                          {option.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
