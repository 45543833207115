import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RelocationService} from "@common/services";
import {
    RelocationCaseAttributesType,
    RelocationCaseHistoryAttributesType,
    RelocationCaseInitializationType,
    RelocationCaseFollowupType,
} from "@common/typing";
import {RelocationCaseHistory} from "../typing";
import {CaseTasks} from "../constants";
const relocationService = RelocationService.getInstance();

export interface CaseHistorySliceState {
    relocationCaseHistory: RelocationCaseHistory[];
    isLoading: boolean;
    isCreatingCaseHistory: boolean;
    isLoadingQtyFollowUps: boolean;
}

const initialState: CaseHistorySliceState = {
    relocationCaseHistory: [],
    isLoading: false,
    isCreatingCaseHistory: false,
    isLoadingQtyFollowUps: false,
};

export const fetchRelocationCaseHistory = createAsyncThunk("caseHistory/fetchRelocationCaseHistory", async (id: number) => {
    const response = await relocationService.getRelocationCaseHistoryByCaseId(id);
    return response.data;
});

export const addRelocationCaseHistory = createAsyncThunk(
    "caseHistory/addRelocationCaseHistory",
    async (data: RelocationCaseHistoryAttributesType) => {
        const response = await relocationService.createRelocationCaseHistory(data);
        return response;
    }
);

export const initializeRelocationCase = createAsyncThunk("caseHistory/initializeRelocationCase", async (data: RelocationCaseInitializationType) => {
    const response = await relocationService.initiateRelocationCase(data.id, data.payload);
    return response;
});

/**
 * thunk to be able to make a followup call
 * @param data
 * @returns
 */
export const followupRelocationCase = createAsyncThunk("caseHistory/followupRelocationCase", async (data: RelocationCaseFollowupType) => {
    const response = await relocationService.followupRelocationCase(data.id, data.payload);
    return response;
});

export const fetchQtyFollowUpsPerCase = createAsyncThunk("caseHistory/fetchQtyFollowUpsPerCase", async (id: number) => {
    const response = await relocationService.getQtyFollowUpsPerCase(id);
    return response;
});

/**
 * This Thunk is used to update the follow up date of a relocation case.
 * We need to improve the way we handle the update of a relocation case, in order
 * to avoid having to create a new Thunk in all the slices for each field we want to update.
 * This Thunk is a temporary solution to update the follow up date of a relocation case.
 * TODO: Create a generic Thunk to update a relocation case and add a type for data parameter.
 */
export const patchFollowUpDate = createAsyncThunk("caseHistory/patchFollowUpDate", async (data: RelocationCaseAttributesType) => {
    const {email} = RelocationService.getAuthenticatedUser();

    //@ts-ignore
    return await relocationService.updateRelocationCase(data.id, {
        updated_by: email,
        follow_up_date: data.follow_up_date,
        relocation_case_task_id: CaseTasks.FOLLOW_UP,
    });
});

const caseHistorySlice = createSlice({
    name: "caseHistory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRelocationCaseHistory.pending, (state: CaseHistorySliceState) => {
                state.isLoading = true;
            })
            .addCase(fetchRelocationCaseHistory.fulfilled, (state: CaseHistorySliceState, action) => {
                state.isLoading = false;
                state.relocationCaseHistory = action.payload as any;
            })
            .addCase(fetchRelocationCaseHistory.rejected, (state: CaseHistorySliceState) => {
                state.isLoading = false;
            })
            .addCase(fetchQtyFollowUpsPerCase.pending, (state: CaseHistorySliceState) => {
                state.isLoadingQtyFollowUps = true;
            })
            .addCase(fetchQtyFollowUpsPerCase.fulfilled, (state: CaseHistorySliceState) => {
                state.isLoadingQtyFollowUps = false;
            })
            .addCase(addRelocationCaseHistory.pending, (state: CaseHistorySliceState) => {
                state.isCreatingCaseHistory = true;
            })
            .addCase(addRelocationCaseHistory.fulfilled, (state: CaseHistorySliceState) => {
                state.isCreatingCaseHistory = false;
            })
            .addCase(addRelocationCaseHistory.rejected, (state: CaseHistorySliceState) => {
                state.isCreatingCaseHistory = false;
            })
            .addCase(initializeRelocationCase.pending, (state: CaseHistorySliceState) => {
                state.isLoading = true;
            })
            .addCase(initializeRelocationCase.fulfilled, (state: CaseHistorySliceState) => {
                state.isLoading = false;
            })
            .addCase(initializeRelocationCase.rejected, (state: CaseHistorySliceState) => {
                state.isLoading = false;
            });
    },
});

export default caseHistorySlice.reducer;
