import React from "react";
import {Button} from "@vacasa/react-components-lib";
import {useAppDispatch} from "../../../store/store";
import {updateStep} from "../../../store/formSubmissionSlice";
import styles from "./FormButtons.module.scss";

type NextButtonProps = {
  step: number;
  previousStep: number;
  onSubmit?: () => void;
};

export const NextButton = ({step, previousStep, onSubmit}: NextButtonProps) => {
  const dispatch = useAppDispatch();
  const handleClickNext = () => {
    step && dispatch(updateStep({currentStep: step, previousStep}));
    onSubmit?.();
  };

  return (
    <>
      <div className={styles.stepButtons}>
        <Button type="submit" onClick={handleClickNext} variant="secondary">
          Next
        </Button>
      </div>
    </>
  );
};
