import React, {useState, useEffect} from "react";
import moment from "moment";
import styles from "./HoursMinutes.module.scss";
import type {DatePickerProps} from "antd/es/date-picker";
const {DatePicker}: any = require("antd");

interface HourMinutesInput {
  value: Date;
  onChange: Function;
  readOnly?: boolean;
}

export const HoursMinutesComponent: React.FunctionComponent<HourMinutesInput> = (props) => {
  const {value, onChange, readOnly} = props;
  const [isValid, setIsValid] = useState(false);
  const [fullTime, setFullTime] = useState<string>("");
  const [formattedTime, setFormattedTime] = useState<string>("YYYY-MM-DD hh:mm A");
  useEffect(() => {
    if (isValid) {
      // Create a Moment object from the date string
      var dateObj = moment(fullTime, formattedTime);
      // Get the date string in YYYY-MM-DD HH:mm format
      var formattedDate = dateObj.format(formattedTime);
      onChange(formattedDate, isValid);
    } else {
      onChange("", isValid);
    }
  }, [fullTime]);

  const handleTime = (value: DatePickerProps["value"], dateString: string) => {
    const date = value?.format("hh:mmA");
    date?.match("^((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))") ? setIsValid(true) : setIsValid(false);
    setFullTime(dateString);
  };

  return (
    <>
      <DatePicker
        showTime
        onChange={handleTime}
        format={formattedTime}
        className={`${styles.timeBox} ${isValid ? styles.valid : styles.invalid}`}
        readOnly={readOnly ? readOnly : false}
      />
    </>
  );
};
