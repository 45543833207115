import {
    GetResponse,
    RelocationCaseComparableUnitAttributesType,
    RelocationCaseComparableUnitSplitStaysAttributesType,
    RelocationCaseReservationAttributesType,
    DataType,
    UnitItemAttributes,
} from "@common/typing";
import {RelocationCaseComparableUnit, RelocationCaseComparableUnitSplitStays, ReservationDetails} from "../../typing";
import _ from "lodash";
import {UnitOptionItem, SplitStayItem} from "../../components";
import {typesOfBooking} from "../../constants";

export const compUnitToUnitOption = (obj: RelocationCaseComparableUnit): UnitOptionItem => ({
    id: obj.attributes.id,
    type: "0",
    completed: true,
    selectedOption: obj.attributes?.relocation_case_comparable_unit_type_id,
    unitUrl: obj.attributes?.public_url,
    unitName: obj.attributes?.public_url_name,
    unitId: obj.attributes?.legacy_unit_id,
    resId: obj.attributes?.legacy_reservation_id,
    agentNote: obj.attributes?.notes,
    authorizedBy: obj.attributes?.authorized_by,
    updatedAt: obj.attributes?.updated_at,
    reservation_cancelled: obj.attributes?.reservation_cancelled,
});

export const unitOptionToCompUnit = (id: string, obj: UnitOptionItem): RelocationCaseComparableUnitAttributesType =>
    ({
        relocation_case_id: parseInt(id),
        relocation_case_comparable_unit_type_id: obj.selectedOption,
        public_url: obj.unitUrl,
        public_url_name: obj.unitName,
        legacy_reservation_id: obj.resId,
        legacy_unit_id: obj.unitId,
        notes: obj.agentNote,
        authorized_by: obj.authorizedBy,
        reservation_cancelled: obj.reservation_cancelled,
    } as RelocationCaseComparableUnitAttributesType);

export const unitOptionToCompUnitUpdate = (obj: UnitOptionItem): RelocationCaseComparableUnitAttributesType => {
    const dataToUpdate = {} as RelocationCaseComparableUnitAttributesType;
    if (!_.isNil(obj.selectedOption)) dataToUpdate.relocation_case_comparable_unit_type_id = obj.selectedOption;
    if (!_.isNil(obj.unitUrl)) dataToUpdate.public_url = obj.unitUrl;
    if (!_.isNil(obj.unitName)) dataToUpdate.public_url_name = obj.unitName;
    if (!_.isNil(obj.resId)) dataToUpdate.legacy_reservation_id = obj.resId;
    if (!_.isNil(obj.agentNote)) dataToUpdate.notes = obj.agentNote;
    if (!_.isNil(obj.unitId)) dataToUpdate.legacy_unit_id = obj.unitId;
    if (_.has(obj, "authorizedBy")) dataToUpdate.authorized_by = obj.authorizedBy;
    return dataToUpdate;
};

export const compUnitToSplitStay = (obj: RelocationCaseComparableUnitSplitStays): SplitStayItem => ({
    id: obj.attributes.id,
    compUnitId: obj.attributes.relocation_case_comparable_unit_id,
    type: obj.attributes.type,
    checkin: obj.attributes.checkin,
    checkout: obj.attributes.checkout,
    unitUrl: obj.attributes?.public_url,
    unitName: obj.attributes?.public_url_name,
    resId: obj.attributes?.legacy_reservation_id,
    authorizedBy: obj.attributes?.authorized_by,
    validInputs: true,
    externalAmount: obj.attributes?.external_amount,
    confirmationCode: obj.attributes?.external_confirmation_code,
    reservationCancelled: obj.attributes?.reservation_cancelled,
});

export const splitStayToCompUnit = (obj: SplitStayItem): RelocationCaseComparableUnitSplitStaysAttributesType =>
    ({
        type: obj.type,
        relocation_case_comparable_unit_id: obj.compUnitId,
        checkin: obj.checkin,
        checkout: obj.checkout,
        public_url: standardUrl(obj.unitUrl),
        public_url_name: obj.unitName,
        legacy_reservation_id: obj.resId,
        authorized_by: obj.authorizedBy,
    } as RelocationCaseComparableUnitSplitStaysAttributesType);

export const splitStayToCompUnitUpdate = (obj: SplitStayItem): RelocationCaseComparableUnitSplitStaysAttributesType => {
    return {
        id: obj.id,
        type: obj.type,
        checkin: obj.checkin,
        checkout: obj.checkout,
        public_url: standardUrl(obj.unitUrl),
        public_url_name: obj.unitName,
        legacy_reservation_id: obj.resId,
        authorized_by: obj.authorizedBy,
    } as RelocationCaseComparableUnitSplitStaysAttributesType;
};

export const splitStaysFilter = (id: number, splitStays: RelocationCaseComparableUnitSplitStays[]) => {
    return _.sortBy(splitStays, ["id"]).filter((e) => e.attributes.relocation_case_comparable_unit_id === id);
};

export const typeBookingToText = (type: number) => typesOfBooking.filter((_) => _.id === type)[0].name;
export const textToTypeBooking = (name: string) => typesOfBooking.filter((_) => _.name === name)[0].id;

export const getUnitNameFromReservation = (reservation: GetResponse<RelocationCaseReservationAttributesType>) => {
    const res = reservation.data?.attributes?.data?.attributes;
    return `${res?.unit_name} - ${res?.unit_address?.city}, ${res?.unit_address?.state}`;
};

export const getUnitNameFromUnit = (unit: DataType<UnitItemAttributes>) => {
    return `${unit.attributes?.name} - ${unit.attributes?.address?.city}, ${unit.attributes?.address?.state}`;
};

export const getUnitUrlFromReservation = (reservation: GetResponse<RelocationCaseReservationAttributesType>) => {
    const unitId = reservation.data?.attributes?.data?.attributes?.unit_id;
    return standardUrl(`vacasa.com/unit/${unitId}`);
};

export const standardUrl = (url: string) => {
    const urlRegex = new RegExp("^(https?:\\/\\/(?:www.|(?!www))|www.|https?:\\/\\/)?(.*)");
    return url.replace(urlRegex, "https://www.$2");
};

export const getResDetailsOutsideBooking = (total: number, code: string, url: string): ReservationDetails => ({
    resId: null,
    unitUrl: url,
    unitId: null,
    unitName: null,
    rent: 0,
    fees: 0,
    tp: 0,
    taxes: 0,
    total,
    confirmationCode: code,
});
