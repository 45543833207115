import React, {FC, useState} from "react";
import styles from "./FinancesCarousel.module.scss";
import {FinancesColumn, FinancesColumnProps} from "..";
import cx from "classnames";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";

export interface FinancesCarouselProps {
  items: Array<FinancesColumnProps>;
  showTitles?: boolean;
  leftMarginContent?: boolean;
  rightMarginContent?: boolean;
  wideBox?: boolean;
}

export const FinancesCarousel: FC<FinancesCarouselProps> = ({
  showTitles = true,
  leftMarginContent = false,
  rightMarginContent = false,
  wideBox = false,
  ...rest
}) => {
  const {items} = rest;

  const [index, setIndex] = useState<number>(0);
  const increaseIndex = () => setIndex(index + 1);
  const decreaseIndex = () => setIndex(index - 1);
  const isMinEdge = () => index === 0;
  const isMaxEdge = () => index === items.length - 1;

  return (
    <div>
      <div className={showTitles ? styles.headerRowWithTitles : ""}>
        {showTitles && <div className={styles.headerBox} />}
        <div className={cx(styles.headerBox, styles.flexTitle)}>
          {!isMinEdge() && (
            <span className={cx(styles.arrowCircle, styles.leftArrow)} onClick={decreaseIndex}>
              <HiOutlineChevronLeft className={styles.arrowLink} />
            </span>
          )}
          <span className={styles.header}>{items[index].header}</span>
          {!isMaxEdge() && (
            <div className={cx(styles.arrowCircle, styles.rightArrow)} onClick={increaseIndex}>
              <HiOutlineChevronRight className={styles.arrowLink} />
            </div>
          )}
        </div>
      </div>
      <FinancesColumn
        {...items[index]}
        showTitles={showTitles}
        header={undefined}
        leftMarginContent={leftMarginContent}
        rightMarginContent={rightMarginContent}
        wideBox={wideBox}
      />
    </div>
  );
};
