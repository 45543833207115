import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit, ValidateReservationError} from "../index";
import {FormSubmissionSteps, REDUX_ACTIONS} from "../../../constants";
import {RootState, useAppDispatch} from "../../../store/store";
import {
  refreshStepPath,
  updateFormData,
  updateStep,
  validatesReservation,
  closeValidateReservationFailedAlert,
} from "../../../store/formSubmissionSlice";
import {useSelector} from "react-redux";
import {UrgentFmStepSchema} from "../schemas/formSchemas";
import {CircularProgress} from "@material-ui/core";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";
import styles from "../urgent-fm-step/UrgentFmStep.module.scss";

type UrgentFmStepProps = {
  title: string;
  readOnly: boolean;
};

export const UrgentFmStep = ({title, readOnly}: UrgentFmStepProps) => {
  //Redux State
  const dispatch = useAppDispatch();
  const {legacyReservationId, relocationRequestReasonId, unitCandidates, extraNotes, extendedEndDate, cleanAfterStay} = useSelector(
    (state: RootState) => state.formSubmission.formData
  );
  const {isValidatingReservation, validateReservationFailed} = useSelector((state: RootState) => state.formSubmission);
  const relocationRequestReasons = useSelector((state: RootState) => state.formSubmission.relocationRequestReasons);

  //Helpers
  const relocationRequestReasonName =
    relocationRequestReasonId && relocationRequestReasons.find((reason) => reason.id === parseInt(relocationRequestReasonId))?.attributes?.name;

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.URGENT_FM} previousStep={FormSubmissionSteps.RESUME} />
      {!readOnly && (
        <p>
          <strong>NOTE:</strong>
          <span className={generalStyles.note}>
            {" "}
            In order to properly prioritize this move, each Last Minute FM will need to be reported separately, and the Reservation ID is required.
          </span>
        </p>
      )}
      <Formik
        initialValues={{
          legacyReservationId,
          relocationRequestReasonId,
          unitCandidates,
          extraNotes,
          extendedEndDate,
          cleanAfterStay,
        }}
        onSubmit={async (values) => {
          const response = await dispatch(validatesReservation({reservationId: parseInt(values.legacyReservationId), extendedEndDate: values.extendedEndDate}));

          if (response.meta.requestStatus === REDUX_ACTIONS.REJECTED) {
            return;
          }
          dispatch(closeValidateReservationFailedAlert());
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.BILLING, previousStep: FormSubmissionSteps.URGENT_FM}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.BILLING, buttonAction: "next"}));
        }}
        validationSchema={UrgentFmStepSchema}
      >
        {({values, errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {/* Res ID Section */}
              <label htmlFor="legacyReservationId">Res ID {!readOnly && <span className={generalStyles.obligatory}>*</span>}</label>
              {!readOnly ? (
                <>
                  <Field type="text" name="legacyReservationId" value={values.legacyReservationId} onChange={handleChange} />
                  {touched.legacyReservationId && errors.legacyReservationId && (
                    <p className={generalStyles.validationError}>{errors.legacyReservationId}</p>
                  )}
                </>
              ) : (
                <p className={generalStyles.response}>{legacyReservationId}</p>
              )}

              {/* Request Reason Section */}
              <label htmlFor="relocationRequestReasonId">
                Please select the reason for the move {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="select" name="relocationRequestReasonId" onChange={handleChange}>
                    <option value="">--Select--</option>
                    {relocationRequestReasons.map((reason) => (
                      <option key={reason.id} value={reason.id}>
                        {reason.attributes.name}
                      </option>
                    ))}
                  </Field>
                  {touched.relocationRequestReasonId && errors.relocationRequestReasonId && (
                    <p className={generalStyles.validationError}>{errors.relocationRequestReasonId}</p>
                  )}
                </>
              ) : (
                <p className={generalStyles.response}>{relocationRequestReasonName}</p>
              )}

              {/* Unit Candidates Section */}
              <label htmlFor="unitCandidates">
                What comparable homes are available in the area? {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="textarea" name="unitCandidates" value={values.unitCandidates} onChange={handleChange} />
                  {touched.unitCandidates && errors.unitCandidates && <p className={generalStyles.validationError}>{errors.unitCandidates}</p>}
                </>
              ) : (
                <p className={generalStyles.response}>{unitCandidates}</p>
              )}

              {/* Reason Extra Notes Section */}
              <label htmlFor="extraNotes">
                Provide any additional notes regarding the reason for the move here. {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="textarea" name="extraNotes" value={values.extraNotes} onChange={handleChange} />
                  {touched.extraNotes && errors.extraNotes && <p className={generalStyles.validationError}>{errors.extraNotes}</p>}
                </>
              ) : (
                <p className={generalStyles.response}>{extraNotes}</p>
              )}

              {/* Extended End Date Section */}
              <p className={styles.optionalText}>
                <strong>*OPTIONAL* If the hold on ResGrid should extend beyond the last night, when should it end?</strong>
              </p>
              {!readOnly && (
                <p>
                  <strong>NOTE:</strong>
                  <span className={generalStyles.note}>
                    {" "}
                    If we have a reservation checking in on this date, we will NOT move that guest to a new home.
                  </span>
                </p>
              )}

              {!readOnly ? (
                <>
                  <input type="date" name="extendedEndDate" value={values.extendedEndDate} onChange={handleChange} />
                  {touched.extendedEndDate && errors.extendedEndDate && <p className={generalStyles.validationError}>{errors.extendedEndDate}</p>}
                </>
              ) : extendedEndDate ? (
                <p className={generalStyles.response}>{extendedEndDate}</p>
              ) : (
                <span className={generalStyles.response}>Extended End Date not selected</span>
              )}

              {/* Clean After Stay Section */}
              <label htmlFor="cleanAfterStay">
                Does there need to be a clean after stay on the block we add to ResGrid?{" "}
                {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <div className={generalStyles.radioOptions} role="group" aria-labelledby="my-radio-group">
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="cleanAfterStay" value="owner" onChange={handleChange} />
                      <span>Yes - Add clean to be charged to the owner</span>
                    </div>
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="cleanAfterStay" value="vacasa" onChange={handleChange} />
                      <span>Yes - Add clean to be charged to Vacasa</span>
                    </div>
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="cleanAfterStay" value="no" onChange={handleChange} />
                      <span>No - No Clean</span>
                    </div>
                  </div>
                  {touched.cleanAfterStay && errors.cleanAfterStay && <p className={generalStyles.validationError}>{errors.cleanAfterStay}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{cleanAfterStay}</span>
              )}
            </div>

            {
              /* Reservation Validation Section */
              validateReservationFailed && <ValidateReservationError />
            }

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton
                  isProcessing={isValidatingReservation}
                  step={FormSubmissionSteps.TYPE_OF_FM}
                  previousStep={FormSubmissionSteps.URGENT_FM}
                />
                <button
                  disabled={isValidatingReservation}
                  type="submit"
                  className={cx(generalStyles.stepButton, generalStyles.nextButton, isValidatingReservation && generalStyles.disabledButton)}
                >
                  Next
                </button>
                {isValidatingReservation && (
                  <div className={generalStyles.spinnerValidation}>
                    <CircularProgress color="inherit" size={25} />
                    <span className={generalStyles.validatingMessage}>Please wait, we are validating the reservation.</span>
                  </div>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
