import React, {useRef} from "react";
import {updatePriorityFilter, updateCreatedAtFilter, updateAssignedToFilter, updateTaskFilter} from "../../store/queueSlice";
import {useAppDispatch} from "../../store/store";
import styles from "./FloatSearch.module.scss";
import {useSearchParams} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";

export interface IFloatSearchEvent {
  children?: JSX.Element | JSX.Element[];
}

interface IFloatSearchProps {
  events: IFloatSearchEvent[];
  invoker: string;
  onClose: any;
  customStyle?: any;
}

export const FloatSearch: React.FunctionComponent<IFloatSearchProps> = (props) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // TODO: remove this switch and find a less manual way to do it
  const handleClear = (invoker: string) => {
    switch (invoker) {
      case "priority":
        dispatch(updatePriorityFilter(""));
        break;
      case "createdAt":
        dispatch(updateCreatedAtFilter(""));
        break;
      case "assignedTo":
        dispatch(updateAssignedToFilter(""));
        searchParams.delete("assignedTo");
        setSearchParams(searchParams);
        break;
      case "task":
        dispatch(updateTaskFilter(""));
        break;
    }
    props.onClose();
  };

  return (
    <>
      <div className={styles.floatSearch} style={props.customStyle} ref={ref}>
        <div className={styles.close} onClick={() => props.onClose()}>
          <CloseOutlined />
        </div>
        {props.events.map((event, index) => {
          return (
            <div className={styles.innerDiv} key={index}>
              {event.children}
            </div>
          );
        })}
        <br />
        <div className={styles.contentLink} onClick={() => handleClear(props.invoker)}>
          Clear all
        </div>
      </div>
    </>
  );
};
