import React, {FC, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {setAlertUnSyncFinances, setIsMidStayFM, setFinancesVerifiedByUser, updateMidStayTotals} from "../../store/wrapUpSlice";
import styles from "../wrap-up-component/WrapUp.module.scss";
import localStyles from "./WrapUpFinances.module.scss";
import {
  AlertComponent,
  SyncFinances,
  NewUnitFinances,
  RegularFinances,
  SplitStayFinances,
  OutsideBookingFinances,
  WrapUpMoveNewUnitAlert,
} from "../../components";
import {relocationPriorities, OptionsAvailable} from "../../constants";
import {RelocationService} from "@common/services";

interface WrapUpFinancesProps {
  fmConclusionSelected: number;
}

export const WrapUpFinances: FC<WrapUpFinancesProps> = ({fmConclusionSelected}) => {
  const dispatch = useAppDispatch();
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const unSyncFinancesAlert = useSelector((state: RootState) => state.wrapUp.alerts.unSyncFinances) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;
  const verifiedFinances = useSelector((state: RootState) => state.wrapUp.financesVerifiedByUser) as any;
  const compUnit = useSelector((state: RootState) => state.wrapUp.compUnit) as any;

  // finances
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;

  const [didGuestStay, setDidGuestStay] = useState(0);
  const [guestStayOptionDisable, setGuestStayOptionDisable] = useState(false);
  const selectValues = [
    {id: 0, name: "No "},
    {id: 1, name: "Yes "},
  ];

  //This type of wrapping dont use guest billing
  useEffect(() => {
    displayOnSaved();
  }, []);

  useEffect(() => {
    if (!isCaseOpen) {
      setGuestStayOptionDisable(true);
    }
  }, [isCaseOpen]);

  useEffect(() => {
    dispatch(setIsMidStayFM(didGuestStay === 1));
  }, [didGuestStay]);

  useEffect(() => {
    dispatch(updateMidStayTotals());
  }, [currentOriginResData, destinationResData, isMidStayFM, isCaseOpen]);

  // Manage cmp ui when case is saved
  async function displayOnSaved() {
    if (!isCaseOpen && isMidStayFM) {
      setDidGuestStay(1);
      setGuestStayOptionDisable(true);
    }
  }

  function handleGuestStay(e: React.ChangeEvent<HTMLSelectElement>) {
    setDidGuestStay(Number(e.currentTarget.value));
  }

  function handleVerifiedFinances(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setFinancesVerifiedByUser(e.currentTarget.checked));
  }

  const didTheGuestStayOverNightSelect = (
    <div className={styles.containerItem}>
      <div className={styles.divSeparator}>
        <span className={styles.containerItemTitle}>Did the guest stay overnight in home?</span>
      </div>
      <div className={styles.containerItemDetail}>
        <select value={didGuestStay} className={styles.selectBox} onChange={handleGuestStay} disabled={guestStayOptionDisable}>
          {selectValues.map((option, index) => {
            return (
              <option value={option.id} key={index}>
                {option.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );

  const isTodayOrMSFM =
    relocationCase?.attributes?.priority?.id === relocationPriorities["MSFM"] ||
    relocationCase?.attributes?.priority?.id === relocationPriorities["TODAY"] ||
    relocationCase?.attributes?.priority?.id === relocationPriorities["ESP-TODAY"] ||
    relocationCase?.attributes?.priority?.id === relocationPriorities["ESP-MSFM"];

  function financesToDisplay() {
    switch (fmConclusionSelected) {
      case RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT:
        if (compUnit?.id > 0) {
          switch (compUnit.attributes?.relocation_case_comparable_unit_type_id) {
            case OptionsAvailable.SPLIT_STAY:
              return <SplitStayFinances />;
            case OptionsAvailable.OUTSIDE_BOOKING:
              return <OutsideBookingFinances />;
            case OptionsAvailable.VACASA_UNIT_NO_RES_HOLD:
              return <NewUnitFinances />;
            default:
              return <NewUnitFinances />;
          }
        } else {
          return <WrapUpMoveNewUnitAlert />;
        }
      default:
        return <RegularFinances twoColumns={didGuestStay === 1} />;
    }
  }

  return (
    <div className={localStyles.body}>
      <div className={styles.containerItem}>
        {isTodayOrMSFM ? didTheGuestStayOverNightSelect : null}

        <div className={styles.titleNoTop}>Finances</div>

        {didGuestStay ? (
          <SyncFinances />
        ) : (
          <>
            {unSyncFinancesAlert && (
              <AlertComponent
                text={
                  <>
                    Warning! We couldn't find finances on the Original Reservation. This can happen if the case has not been initialized or if the
                    Reservation was cancelled before the case was initialized.
                  </>
                }
                className="warning"
                iconSize="30"
                setShowAlert={() => dispatch(setAlertUnSyncFinances(false))}
              />
            )}
          </>
        )}

        {financesToDisplay()}

        {didGuestStay && isCaseOpen ? (
          <div style={{marginTop: 25}}>
            <label>
              <input
                type="checkbox"
                style={{verticalAlign: "middle"}}
                className={styles.contentCheckbox}
                checked={verifiedFinances}
                onChange={handleVerifiedFinances}
              />
              I certify that the above finance information is updated and correct.
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};
