import React, {FC, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {setGuestAwareness} from "../../store/wrapUpSlice";
import styles from "../wrap-up-component/WrapUp.module.scss";
import localStyles from "./WrapUpFMNoLongerNeeded.module.scss";

interface WrapUpFMNoLongerNeededProps {}

export const WrapUpFMNoLongerNeeded: FC<WrapUpFMNoLongerNeededProps> = () => {
  const dispatch = useAppDispatch();
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const guestAwareness = useSelector((state: RootState) => state.wrapUp.guestAwareness) as any;

  const [confirmationCallNotification, setConfirmationCallNotification] = useState<boolean>(false);
  const [guestNotified, setGuestNotified] = useState<boolean>(false);

  const handleGuestNotified = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestNotified(e.currentTarget.checked);
  };

  useEffect(() => {
    if (relocationCase.attributes?.id !== null && !isCaseOpen && guestAwareness) setConfirmationCallNotification(true);
    if (!guestAwareness) setConfirmationCallNotification(false);
  }, [relocationCase]);

  const handleChecked = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.id === "rbtn_guestAwareYes") {
      setConfirmationCallNotification(true);
      setGuestNotified(false);
      dispatch(setGuestAwareness(true));
    } else {
      setConfirmationCallNotification(false);
      dispatch(setGuestAwareness(false));
    }
  };

  return (
    <div className={localStyles.body}>
      <div className={styles.contentDiv}>
        <div className={styles.contentSeparatorDiv}></div>
        <div className={styles.contentSeparatorDiv}>
          <div className={styles.divSeparator}>
            <span className={styles.containerItemTitle}>Was the Guest aware of the Forced Move at any point in time?</span>
          </div>
          {!isCaseOpen ? (
            <div className={styles.containerItemDetail}>
              <span>{guestAwareness ? "Yes" : "No"}</span>
            </div>
          ) : (
            <div>
              <div className={styles.containerItemDetail}>
                <label className={styles.contentRadioButton}>
                  {" "}
                  <input type="radio" name="rbtn_guestAware" id="rbtn_guestAwareYes" className={styles.radioButton} onChange={handleChecked} />
                  Yes
                </label>
              </div>
              <div className={styles.containerItemDetail}>
                <label className={styles.contentRadioButton}>
                  {" "}
                  <input type="radio" name="rbtn_guestAware" id="rbtn_guestAwareNo" className={styles.radioButton} onChange={handleChecked} />
                  No
                </label>
              </div>
            </div>
          )}
        </div>

        {confirmationCallNotification &&
          (!isCaseOpen ? (
            <div className={styles.contentSeparatorDiv}>
              <div className={styles.divSeparator}>
                <span className={styles.containerItemTitle}>Contacted by</span>
              </div>
              <div className={styles.containerItemDetail}>
                <span>
                  {relocationCase.attributes?.assigned_to ? relocationCase.attributes?.assigned_to : "No agent has been assigned to this case"}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.containerItem}>
                <span className={styles.containerItemTitle}>Notify Guest by phone</span>
              </div>
              <div className={styles.containerItem}>
                <span className={styles.containerItemDetail}>
                  It’s important that we a call our Guest to let them know when there’s been a false alarm and apologize for any confusion.
                </span>
              </div>
              <div className={styles.verificationDetailsText}>
                <label>
                  <input
                    type="checkbox"
                    className={styles.contentCheckbox}
                    style={{verticalAlign: "middle"}}
                    checked={guestNotified}
                    onChange={handleGuestNotified}
                  />
                  <span style={{verticalAlign: "middle"}}>The Guest was notified by phone</span>
                </label>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
