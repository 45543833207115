import {configureStore} from "@reduxjs/toolkit";
import holidaysReducer from "./holidaysSlice";
import queueReducer from "./queueSlice";
import caseProfileReducer from "./caseProfileSlice";
import formSubmissionReducer from "./formSubmissionSlice";
import navigationTabsReducer from "./navigationTabSlice";
import fileUploadReducer from "./fileUploadSlice";
import caseHistoryReducer from "./caseHistorySlice";
import compUnitReducer from "./compUnitSlice";
import wrapUpReducer from "./wrapUpSlice";
import caseFinanceReducer from "./caseFinanceSlice";
import bulkSelectionReducer from "./bulkSelectionSlice";
import {useDispatch} from "react-redux";

export const store = configureStore({
    reducer: {
        holidays: holidaysReducer,
        queue: queueReducer,
        caseFinance: caseFinanceReducer,
        caseProfile: caseProfileReducer,
        formSubmission: formSubmissionReducer,
        navigationTabs: navigationTabsReducer,
        fileUploader: fileUploadReducer,
        caseHistory: caseHistoryReducer,
        compUnit: compUnitReducer,
        wrapUp: wrapUpReducer,
        bulkSelection: bulkSelectionReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ["fileUploader/uploadFile/fulfilled"],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
