import React from "react";
import {Button} from "@vacasa/react-components-lib";
import {RootState, useAppDispatch} from "../../../store/store";
import {refreshStepPath, updateStep, closeValidateUnitCodeFailedAlert, closeValidateReservationFailedAlert} from "../../../store/formSubmissionSlice";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";
import {useSelector} from "react-redux";

type BackButtonProps = {
  step: number;
  previousStep: number;
  isProcessing?: boolean;
};

export const BackButton = ({step, previousStep, isProcessing = false}: BackButtonProps) => {
  const dispatch = useAppDispatch();
  const {validateReservationFailed, validateUnitFailed} = useSelector((state: RootState) => state.formSubmission);

  const handleClick = () => {
    validateReservationFailed && dispatch(closeValidateReservationFailedAlert());
    validateUnitFailed && dispatch(closeValidateUnitCodeFailedAlert());
    dispatch(updateStep({currentStep: step, previousStep}));
    dispatch(refreshStepPath({currentStep: step, buttonAction: "back"}));
  };

  return (
    <Button
      disabled={isProcessing}
      type="button"
      customClass={cx(generalStyles.stepButton, generalStyles.backButton, isProcessing && generalStyles.disabledButton)}
      onClick={handleClick}
    >
      Back
    </Button>
  );
};
