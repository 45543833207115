import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../store/store";
import {fetchCases, resetFilters, updateQueryFilters} from "../../store/queueSlice";
import {QueueTable} from "../../components/queue-table/QueueTable";
import {useQueueSearchParams} from "../../hooks/useQueueSearchParams";

export const CasesAll: React.FC = () => {
  const dispatch = useAppDispatch();
  const relocationCases = useSelector((state: RootState) => state.queue.casesAll.list);
  const isLoading = useSelector((state: RootState) => state.queue.casesAll.isLoading);
  const isEmpty = useSelector((state: RootState) => state.queue.casesAll.isEmpty);

  // filters TODO: mapStateToProps
  const filterByStatus = useSelector((state: RootState) => state.queue.filters.status.value);
  const filterByPriority = useSelector((state: RootState) => state.queue.filters.priority.value);
  const filterByCreatedAt = useSelector((state: RootState) => state.queue.filters.createdAt.value);
  const filterByGroup = useSelector((state: RootState) => state.queue.filters.group.value);
  const filterByCode = useSelector((state: RootState) => state.queue.filters.unitCode.value);
  const filterByUnassigned = useSelector((state: RootState) => state.queue.filters.unassigned.value);
  const filterByAssignedTo = useSelector((state: RootState) => state.queue.filters.assignedTo.value);
  const filterByPageNumber = useSelector((state: RootState) => state.queue.filters.pagination.number);
  const filterByPageSize = useSelector((state: RootState) => state.queue.filters.pagination.size);
  const filterClearTrigger = useSelector((state: RootState) => state.queue.triggers.clearFilters);
  const filterByIsLatam = useSelector((state: RootState) => state.queue.filters.isLatam.value);
  const filterByTask = useSelector((state: RootState) => state.queue.filters.task.value);
  const {
    isQueryFilters,
    pageNumber,
    pageSize,
    assignedTo,
    unitCode,
    status,
    group,
    priority,
    unassigned,
    createdAt,
    isLatam,
    task,
    setSearchParamsSync,
  } = useQueueSearchParams();

  // on initial load compare QueryFilters with Default Filters
  useEffect(() => {
    // if no query param filters fetch default data
    if (!isQueryFilters) {
      dispatch(fetchCases());
      return;
    }

    // if Query Param Filters update filters to trigger useEffect. TODO: DRY
    dispatch(
      updateQueryFilters({
        pageNumber,
        pageSize,
        assignedTo,
        unitCode,
        status,
        group,
        priority,
        unassigned,
        createdAt,
        isLatam,
        task,
      })
    );
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // clear search params when default state
  useEffect(() => {
    setSearchParamsSync(filterByPageNumber, "pageNumber");
    setSearchParamsSync(filterByPageSize, "pageSize");
    setSearchParamsSync(filterByAssignedTo, "assignedTo");
    setSearchParamsSync(filterByCode, "unitCode");
    setSearchParamsSync(filterByStatus, "status");
    setSearchParamsSync(filterByGroup, "group");
    setSearchParamsSync(filterByPriority, "priority");
    setSearchParamsSync(filterByUnassigned, "unassigned");
    setSearchParamsSync(filterByCreatedAt, "createdAt");
    setSearchParamsSync(filterByIsLatam, "isLatam");
    setSearchParamsSync(filterByTask, "task");
  }, [
    filterByPageNumber,
    filterByPageSize,
    filterByAssignedTo,
    filterByCode,
    filterByStatus,
    filterByPriority,
    filterByGroup,
    filterByUnassigned,
    filterByCreatedAt,
    filterByIsLatam,
    filterByTask,
  ]);

  // on filters update
  useEffect(() => {
    dispatch(fetchCases());
  }, [
    filterByPriority,
    filterByCreatedAt,
    filterByGroup,
    filterByStatus,
    filterByCode,
    filterByPageNumber,
    filterByPageSize,
    filterByUnassigned,
    filterByAssignedTo,
    filterClearTrigger,
    filterByIsLatam,
    filterByTask,
  ]);

  return (
    <QueueTable
      relocationCases={relocationCases}
      isLoading={isLoading}
      isEmpty={isEmpty}
      isOwnershipSearchAllowed={true}
      handleUpdate={() => {
        dispatch(fetchCases());
      }}
    />
  );
};
