import React, {useEffect, useState} from "react";
import {ADMIN_USERS, FilterActionOptions, FilterActionTexts} from "../../constants";
import {FilterActionsText, RelocationCase} from "../../typing";
import styles from "./FilterActions.module.scss";
import {RootState, useAppDispatch} from "../../store/store";
import {toggleBulkAssignAction, toggleBulkResolveAction, updateFilter} from "../../store/queueSlice";
import {ImplicitFlowService, RelocationService} from "@common/services";
import {useSelector} from "react-redux";
import {Checkbox} from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import {addCasesSelection, removeCasesSelection, resetCasesSelection, selectedCaseType} from "../../store/bulkSelectionSlice";

interface FilterActionsProps {
  isBulkAllowed: boolean;
}

export const FilterActions = (props: FilterActionsProps) => {
  const dispatch = useAppDispatch();
  const defaultOption = 1;
  const clearTrigger = useSelector((state: RootState) => state.queue.triggers.clearFilters);
  const currentStatusFilter = useSelector((state: RootState) => state.queue.filters.status.value);
  const currentGroupFilter = useSelector((state: RootState) => state.queue.filters.group.value);
  const currentIsLatamFilter = useSelector((state: RootState) => state.queue.filters.isLatam.value);
  const isBulkResolveAction = useSelector((state: RootState) => state.queue.header.actions.bulkResolve.isVisible);
  const isBulkAssignAction = useSelector((state: RootState) => state.queue.header.actions.bulkAssign.isVisible);
  const isBulkAction = isBulkResolveAction || isBulkAssignAction;
  const [selectedOption, setSelectedOption] = useState(1);
  const {email} = ImplicitFlowService.getAuthenticatedUser();
  const relocationCases: RelocationCase[] = useSelector((state: RootState) => state.queue.casesAll.list);
  const casesSelected = useSelector((state: RootState) => state.bulkSelection.casesSelected);

  const atLeastOneCaseSelected = !isEmpty(relocationCases) && relocationCases.some((c) => casesSelected.map((_) => _.id).includes(c.id));
  const allCasesSelected = !isEmpty(relocationCases) && relocationCases.every((c) => casesSelected.map((_) => _.id).includes(c.id));

  // Clear Filter
  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [clearTrigger]);

  // Clear Filter
  useEffect(() => {
    const currentOption = FilterActionTexts.find(
      (option) => currentStatusFilter === option.status && currentGroupFilter === option.group && currentIsLatamFilter === option.isLatam
    );
    if (!currentOption || currentOption.id === selectedOption) return;
    setSelectedOption(currentOption.id);
  }, [currentStatusFilter, currentGroupFilter, currentIsLatamFilter]);

  // Statuses
  const filters = {
    status: {
      active: [RelocationService.STATUS_PENDING, RelocationService.STATUS_IN_PROGRESS].join(","),
      resolved: RelocationService.STATUS_DONE.toString(),
    },
    group: {
      active: "1",
      inactive: "0",
    },
    isLatam: {
      in: 1,
      notIn: 0,
      notDefined: -1,
    },
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const option = Number(event.target.value);

    setSelectedOption(option);

    // Clear bulk selection if the new option is not a bulk action
    if (isBulkAction && ![FilterActionOptions.RESOLVE, FilterActionOptions.ASSIGN].includes(option)) dispatch(resetCasesSelection());

    switch (option) {
      case FilterActionOptions.ACTIVE_CASES:
        dispatch(updateFilter({status: filters.status.active, group: filters.group.inactive, isLatam: filters.isLatam.notDefined}));
        break;
      case FilterActionOptions.RESOLVED_CASES:
        dispatch(updateFilter({status: filters.status.resolved, group: filters.group.inactive, isLatam: filters.isLatam.notDefined}));
        break;
      case FilterActionOptions.GROUP_ACTIVE_CASES:
        dispatch(updateFilter({status: filters.status.active, group: filters.group.active, isLatam: filters.isLatam.notDefined}));
        break;
      case FilterActionOptions.GROUP_RESOLVED_CASES:
        dispatch(updateFilter({status: filters.status.resolved, group: filters.group.active, isLatam: filters.isLatam.notDefined}));
        break;
      case FilterActionOptions.RESOLVE:
        dispatch(toggleBulkResolveAction());
        break;
      case FilterActionOptions.ASSIGN:
        dispatch(toggleBulkAssignAction());
        break;
      case FilterActionOptions.LATAM_ACTIVE_CASES:
        dispatch(updateFilter({status: filters.status.active, group: filters.group.inactive, isLatam: filters.isLatam.in}));
        break;
      case FilterActionOptions.LATAM_RESOLVED_CASES:
        dispatch(updateFilter({status: filters.status.resolved, group: filters.group.inactive, isLatam: filters.isLatam.in}));
        break;
    }
  };

  /**
   * Toogle All checkboxes as selected or unselected
   */
  function handleToggleSelectAll(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isEmpty(relocationCases)) {
      const casesToAction: selectedCaseType[] = relocationCases.map((r) => ({
        id: r.id,
        legacy_reservation_id: r.attributes.legacy_reservation_id,
        priority: r.attributes.priority,
      }));
      if (e.target.checked && !atLeastOneCaseSelected) dispatch(addCasesSelection(casesToAction));
      else dispatch(removeCasesSelection(casesToAction));
    }
  }

  return (
    <>
      {/** Bulk Selector */}
      {isBulkAction ? (
        <div className={styles.selectAllCases}>
          <Checkbox
            className={styles.inputCheck}
            color={"primary"}
            checked={allCasesSelected}
            indeterminate={atLeastOneCaseSelected && !allCasesSelected}
            onChange={handleToggleSelectAll}
          />
        </div>
      ) : null}

      {/** Combobox for Status Filters */}
      <select value={selectedOption} className={styles.filterActionSelect} onChange={(e) => handleSelectChange(e)}>
        {FilterActionTexts.filter((filterAction: FilterActionsText) =>
          props.isBulkAllowed
            ? filterAction.id > 0
            : filterAction.id !== FilterActionOptions.RESOLVE && filterAction.id !== FilterActionOptions.ASSIGN
        )
          .filter((filterAction: FilterActionsText) => {
            // Bulk Resolve Users filter
            if (filterAction.id === FilterActionOptions.RESOLVE) return ADMIN_USERS.includes(email);
            return true;
          })
          .map((filterAction: FilterActionsText) => (
            <option key={filterAction.id} value={filterAction.id}>
              {filterAction.text}
            </option>
          ))}
      </select>
    </>
  );
};
