import {Badge} from "@vacasa/react-components-lib";
import React, {useEffect, useState} from "react";
import styles from "./FilterPriority.module.scss";
import cx from "classnames";
import {updatePriorityFilter} from "../../store/queueSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {getPriorityClass} from "../../utils";

interface IFilterPriorityProps {
  priority: {id: number; rank: number; name: string; desc: string};
  onClose: Function;
}

export const FilterPriority: React.FunctionComponent<IFilterPriorityProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentPriority = useSelector((state: RootState) => state.queue.filters.priority.value);
  const [checked, setChecked] = useState(false);

  const handleSelectedPriority = () => {
    dispatch(updatePriorityFilter(props.priority.id));
    setChecked(true);
    props.onClose();
  };

  // prepopulate check based on default filter
  useEffect(() => {
    if (+currentPriority === props.priority.id) {
      setChecked(true);
    }
  }, []);

  // prepopulate check if priority is updated somewhere else, like queryParams
  useEffect(() => {
    if (+currentPriority !== props.priority.id) {
      setChecked(false);
    }
  }, [currentPriority]);

  return (
    <div className={styles.priorityGrid} onClick={handleSelectedPriority}>
      <div className={styles.dvRadio}>
        <input
          type="radio"
          name="rbt_priority"
          value={props.priority.id}
          className={styles.priorityRadio}
          checked={checked}
          onChange={handleSelectedPriority}
        />
      </div>
      <div>
        <Badge
          text={props.priority.name}
          variant={"error"}
          outline={false}
          disabled={false}
          isPill={true}
          closeButton={false}
          customClass={cx(styles.customClassBadge, styles[getPriorityClass(props.priority.rank)])}
        />
      </div>
    </div>
  );
};
