import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {closeCreateFormFailedAlert} from "../../../store/formSubmissionSlice";
import {AlertComponent} from "../../alert/AlertComponent";

export const CreateFormError: FC = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const {createFormErrorMessage} = useSelector((state: RootState) => state.formSubmission);

  return <AlertComponent className="error" text={<>{createFormErrorMessage}</>} setShowAlert={() => dispatch(closeCreateFormFailedAlert())} />;
};
