import React, {useState} from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit, ValidateReservationError} from "../index";
import {FormSubmissionSteps, REDUX_ACTIONS} from "../../../constants";
import {
  closeValidateReservationFailedAlert,
  refreshStepPath,
  updateFormData,
  updateStep,
  validatesReservation,
} from "../../../store/formSubmissionSlice";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {ResIdStepSchema} from "../schemas/formSchemas";
import {CircularProgress} from "@material-ui/core";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";

type ResIdStepProps = {
  title: string;
  readOnly: boolean;
};

export const ResIdStep = ({title, readOnly}: ResIdStepProps) => {
  //Redux State
  const dispatch = useAppDispatch();
  const {isValidatingReservation, validateReservationFailed} = useSelector((state: RootState) => state.formSubmission);
  const {legacyReservationId, relocationRequestReasonId, extraNotes, startDate, unitCandidates} = useSelector(
    (state: RootState) => state.formSubmission.formData
  );
  const relocationRequestReasons = useSelector((state: RootState) => state.formSubmission.relocationRequestReasons);

  //Helpers
  const relocationRequestReasonName =
    relocationRequestReasonId && relocationRequestReasons.find((reason) => reason.id === parseInt(relocationRequestReasonId))?.attributes?.name;

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.RES_ID} previousStep={FormSubmissionSteps.RESUME} />
      {!readOnly && (
        <p>In order to properly prioritize this move, each Last Minute FM will need to be reported separately, and the Reservation ID is required.</p>
      )}
      <Formik
        initialValues={{
          legacyReservationId,
          relocationRequestReasonId,
          extraNotes,
          startDate,
          unitCandidates,
        }}
        onSubmit={async (values) => {
          const response = await dispatch(validatesReservation({reservationId: parseInt(values.legacyReservationId)}));
          if (response.meta.requestStatus === REDUX_ACTIONS.REJECTED) {
            return;
          }
          dispatch(closeValidateReservationFailedAlert());
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.BILLING, previousStep: FormSubmissionSteps.RES_ID}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.BILLING, buttonAction: "next"}));
        }}
        validationSchema={ResIdStepSchema}
      >
        {({values, errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {/* Res ID Section */}
              <label htmlFor="legacyReservationId">Res ID {!readOnly && <span className={generalStyles.obligatory}>*</span>}</label>
              {!readOnly ? (
                <>
                  <Field type="text" name="legacyReservationId" value={values.legacyReservationId} onChange={handleChange} />
                  {touched.legacyReservationId && errors.legacyReservationId && (
                    <p className={generalStyles.validationError}>{errors.legacyReservationId}</p>
                  )}
                </>
              ) : (
                <span className={generalStyles.response}>{legacyReservationId}</span>
              )}

              {/* Request Reason Section */}
              <label htmlFor="relocationRequestReasonId">
                Please select the reason for the move {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="select" name="relocationRequestReasonId" onChange={handleChange}>
                    <option value="">--Select--</option>
                    {relocationRequestReasons.map((reason) => (
                      <option key={reason.id} value={reason.id}>
                        {reason.attributes.name}
                      </option>
                    ))}
                  </Field>
                  {touched.relocationRequestReasonId && errors.relocationRequestReasonId && (
                    <p className={generalStyles.validationError}>{errors.relocationRequestReasonId}</p>
                  )}
                </>
              ) : (
                <span className={generalStyles.response}>{relocationRequestReasonName}</span>
              )}

              {/* Reason Extra Notes Section */}
              <label htmlFor="extraNotes">
                Provide any additional notes regarding the reason for the move here. {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="textarea" name="extraNotes" value={values.extraNotes} onChange={handleChange} />
                  {touched.extraNotes && errors.extraNotes && <p className={generalStyles.validationError}>{errors.extraNotes}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{extraNotes}</span>
              )}

              {/* Unit Candidates Section */}
              <label htmlFor="unitCandidates">
                You know your region and homes better than anyone. Are there good comps that we should look at moving this Guest to?{" "}
              </label>
              {!readOnly ? (
                <Field as="textarea" name="unitCandidates" value={values.unitCandidates} onChange={handleChange} />
              ) : (
                <span className={generalStyles.response}>{unitCandidates}</span>
              )}
            </div>

            {
              /* Reservation Validation Section */
              validateReservationFailed && <ValidateReservationError />
            }

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton
                  isProcessing={isValidatingReservation}
                  step={FormSubmissionSteps.NON_URGENT_FM}
                  previousStep={FormSubmissionSteps.RES_ID}
                />
                <button
                  disabled={isValidatingReservation}
                  type="submit"
                  className={cx(generalStyles.stepButton, generalStyles.nextButton, isValidatingReservation && generalStyles.disabledButton)}
                >
                  Next
                </button>
                {isValidatingReservation && (
                  <div className={generalStyles.spinnerValidation}>
                    <CircularProgress color="inherit" size={25} />
                    <span className={generalStyles.validatingMessage}>Please wait, we are validating the reservation.</span>
                  </div>
                )}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
