import React, {useState, useEffect} from "react";
import {CountTimerType} from "../../typing";
import {getRemainingOrOverdueTime} from "../../utils";
import Skeleton from "../skeleton/Skeleton";
import styles from "./CountTimer.module.scss";

interface Props {
  date: string;
}

const defaultTime: CountTimerType = {
  minutes: 0,
  hours: 0,
  days: 0,
};

export const CountTimer = ({date}: Props) => {
  const [remainingTime, setRemainingTime] = useState<CountTimerType>(defaultTime);
  const dayText = remainingTime.days === 1 || remainingTime.days === -1 ? "day" : "days";
  const hourText = remainingTime.hours === 1 || remainingTime.hours === -1 ? "hour" : "hours";
  const minuteText = remainingTime.minutes === 1 || remainingTime.minutes === -1 ? "minute" : "minutes";

  const updateRemainingTime = (date: string) => {
    setRemainingTime(getRemainingOrOverdueTime(date));
  };

  const OverdueTimeText = () => {
    return (
      <span className={styles.overdueText}>
        {remainingTime.days > 0 ? "" : remainingTime.hours > 0 ? "" : remainingTime.minutes > 0 ? "" : "Overdue "}
      </span>
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(date);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date]);

  return (
    <>
      {date && remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes === 0 ? (
        <Skeleton elements={["mediumText"]} />
      ) : remainingTime.days !== 0 ? (
        <span className={remainingTime.days < 0 ? styles.overdueText : ""} style={{marginLeft: "6px"}}>
          <OverdueTimeText /> {Math.abs(remainingTime.days)} {dayText}
        </span>
      ) : remainingTime.hours !== 0 ? (
        <span className={remainingTime.hours < 0 ? styles.overdueText : ""} style={{marginLeft: "6px"}}>
          <OverdueTimeText /> {Math.abs(remainingTime.hours)} {hourText}
        </span>
      ) : (
        <span className={remainingTime.minutes < 0 ? styles.overdueText : ""} style={{marginLeft: "6px"}}>
          <OverdueTimeText /> {Math.abs(remainingTime.minutes)} {minuteText}
        </span>
      )}
    </>
  );
};
