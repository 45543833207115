import * as Yup from "yup";

const validationMessage = "* This is a required question";

export const TypeOfFmStepSchema = Yup.object({
    isMSFM: Yup.string().required(validationMessage).nullable(),
});

export const NonUrgentStepSchema = Yup.object({
    type: Yup.string().required(validationMessage).nullable(),
});

export const UrgentFmStepSchema = Yup.object({
    legacyReservationId: Yup.number().integer("Res ID must be a number").required(validationMessage).nullable().typeError("Res ID must be a number"),
    relocationRequestReasonId: Yup.string().required(validationMessage).nullable().typeError(""),
    unitCandidates: Yup.string().required(validationMessage).nullable(),
    extraNotes: Yup.string().required(validationMessage).nullable(),
    cleanAfterStay: Yup.string().required(validationMessage).nullable(),
});

export const ResIdStepSchema = Yup.object({
    legacyReservationId: Yup.number().integer("Res ID must be a number").required(validationMessage).nullable().typeError("Res ID must be a number"),
    relocationRequestReasonId: Yup.string().required(validationMessage).nullable(),
    extraNotes: Yup.string().required(validationMessage).nullable(),
});

export const UnitCodeStepSchema = Yup.object({
    legacyUnitCode: Yup.string().required(validationMessage).nullable(),
    relocationRequestReasonId: Yup.string().required(validationMessage).nullable(),
    extraNotes: Yup.string().required(validationMessage).nullable(),
});

export const UnavailableDatesStepSchema = Yup.object({
    startDate: Yup.date().required(validationMessage).nullable(),
    moveGuest: Yup.string().required(validationMessage).nullable(),
    endDate: Yup.date()
        .required(validationMessage)
        .test('endDate-min', function (value) {
            const today = new Date();
            const yesterday = new Date(today.setDate(today.getDate() - 1));
            const startDate = this.parent.startDate;

            if (startDate && new Date(startDate) > yesterday) {
                if (value < new Date(startDate)) {
                    return this.createError({
                        message: 'End date cannot be before start date',
                        path: 'endDate',
                    });
                }
            } else {
                if (value < yesterday) {
                    return this.createError({
                        message: 'End date cannot be before today',
                        path: 'endDate',
                    });
                }
            }
            return true; // Validation passed
        }),
});

export const BillingStepSchema = Yup.object({
    billed: Yup.string().required(validationMessage).nullable(),
});

export const VacasaBilledStepSchema = Yup.object({
    approvedBy: Yup.string().required(validationMessage).nullable(),
});

export const SplitBilledStepSchema = Yup.object({
    billedPercentage: Yup.string().required(validationMessage).nullable(),
});
