import {CountTimerType, RelocationCase} from "../../typing";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";
import * as _ from "lodash";
import {RelocationService} from "@common/services";
import {FrontendConfiguration} from "@common/configuration";
import {RelocationCaseResolutionAttributesType} from "@common/typing";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const CHECKIN_HOUR = 16;
const CHECKIN_MINUTE = 0;
export const CHECKIN_DEFAULT_TZ = FrontendConfiguration.getTimeZone();
export const CHECKIN_DEFAULT_FORMAT = "YYYY-MM-DD";

export const isCaseResolved = (relocationCase: RelocationCase) =>
    relocationCase?.attributes?.relocation_case_resolution_id !== RelocationService.RESOLUTION_UNRESOLVED;

/**
 * Gets the correct unit code from relocation case "Code" attribute
 *@param code string
 *@returns string
 */
export const getCorrectUnitCode = (code: string): string => {
    if (!code.includes("-")) return code;

    const codeArray = code.split("-");
    const unitCode = codeArray?.splice(0, codeArray?.length - 1)[0];
    return unitCode?.charAt(unitCode?.length - 1) === "-" ? unitCode?.slice(0, -1) : unitCode;
};

/**
 * Gets the remaining or overdue time from relocation case follow_up_date
 *@param date string
 *@returns CountTimerType
 */
export const getRemainingOrOverdueTime = (date: string): CountTimerType => {
    const dateDayJs = dayjs(date);
    const nowDayJs = dayjs();

    return {
        minutes: getRemainingMinutes(nowDayJs, dateDayJs),
        hours: getRemainingHours(nowDayJs, dateDayJs),
        days: getRemainingDays(nowDayJs, dateDayJs),
    };
};

export const getRemainingMinutes = (nowDayJs: any, dateDayJs: any) => {
    return dateDayJs.diff(nowDayJs, "minutes") % 60;
};

export const getRemainingHours = (nowDayJs: any, dateDayJs: any) => {
    return dateDayJs.diff(nowDayJs, "hours") % 24;
};

/**
 * It is obtained from the dates received, only the day 
 * (to avoid problems with 2 days with floating point that caused 2 days to be shown instead of 3 days)
 * @param nowDayJs date now of this moment
 * @param dateDayJs case followup date
 * @returns 
 */
export const getRemainingDays = (nowDayJs: any, dateDayJs: any) => {
    const caseDate = dayjs(dateDayJs.format('YYYY-MM-DD'));
    const nowDate = nowDayJs.format('YYYY-MM-DD');
    return caseDate.diff(nowDate, "days");
};

export const getCheckinInUnitTimeZone = (checkin: string, timezone: string): string => {
    timezone = _.isNil(timezone) || _.isEmpty(timezone) ? CHECKIN_DEFAULT_TZ : timezone;
    const checkinDayJs = dayjs(checkin).set("hour", CHECKIN_HOUR).set("minute", CHECKIN_MINUTE);
    const checkinDayJsInUnitTimeZone = checkinDayJs.tz(timezone).set("hour", CHECKIN_HOUR).set("minute", CHECKIN_MINUTE);
    return checkinDayJsInUnitTimeZone.format("YYYY-MM-DD HH:mm");
};

export const calculateHoursDaysAndMonthsToCheckin = (checkin: string, timezone: string) => {
    const SEVENTEEN_HOURS = 17;
    const MINUS_SEVENTEEN_HOURS = -17;
    const SEVENTY_TWO_HOURS = 72;
    const DAYS90 = 90;
    const MINUS_ONE_MONTH = -1;
    timezone = _.isNil(timezone) || _.isEmpty(timezone) ? CHECKIN_DEFAULT_TZ : timezone;

    const nowInUnitTimeZone = dayjs.tz(dayjs(), timezone);
    const caseCheckin = dayjs(checkin);
    const caseCheckinInUnitTimeZone = dayjs()
        .tz(timezone)
        .set("year", caseCheckin.year())
        .set("month", caseCheckin.month())
        .set("date", caseCheckin.date())
        .set("hour", CHECKIN_HOUR)
        .set("minute", CHECKIN_MINUTE);
    const diff_hours = caseCheckinInUnitTimeZone.diff(nowInUnitTimeZone, "hours");
    const diff_days = caseCheckinInUnitTimeZone.diff(nowInUnitTimeZone, "days");
    const diff_months = caseCheckinInUnitTimeZone.diff(nowInUnitTimeZone, "months");
    const checkinDayOfTheWeek = dayjs(checkin).format("dddd");

    if (diff_hours < 0) {
        if (diff_hours < MINUS_SEVENTEEN_HOURS && diff_months > MINUS_ONE_MONTH) {
            return diff_days + " days";
        } else if (diff_months <= MINUS_ONE_MONTH) {
            return diff_months + " months";
        } else {
            return diff_hours + " hrs";
        }
    }

    if (diff_hours >= 0 && diff_hours < SEVENTEEN_HOURS) {
        return diff_hours + " hrs";
    }

    if (diff_hours >= SEVENTEEN_HOURS && diff_days < DAYS90) {
        return diff_days + " days";
    }

    return diff_months + " months";
};

export const calculateDaysDiff = (firstNight: string, lastNight: string) => {
    const lastNight_time = dayjs(lastNight).add(1, "d");
    const firstNight_time = dayjs(firstNight);

    return lastNight_time.diff(firstNight_time, "days");
};

export const getDateTimeZone = (newDate: string, format?: string, timezone?: string): string => {
    timezone = _.isNil(timezone) || _.isEmpty(timezone) ? CHECKIN_DEFAULT_TZ : timezone;
    format = _.isNil(format) || _.isEmpty(format) ? CHECKIN_DEFAULT_FORMAT : format;
    const dateJsInUnitTimeZone = dayjs(newDate).tz(timezone);
    return dateJsInUnitTimeZone.format(format);
};

export const getResolutionName = (id: number, caseResolutions: RelocationCaseResolutionAttributesType[]) => {
    const caseResolution = caseResolutions.filter((caseResolution: any) => caseResolution.id === id)[0] as any;
    return caseResolution?.attributes?.name;
};

export const getPriorityClass = (weight: number): string => {
    if (weight > 0 && weight <= 6) return "highPriority";
    if (weight > 6 && weight <= 9) return "mediumPriority";
    if (weight > 9 && weight <= 12) return "lowPriority";
    return "noPriority";
};
