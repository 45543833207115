import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Spinner} from "../../components/spinner/Spinner";
import styles from "../../components/queue-header/QueueHeader.module.scss";
import {useAppDispatch, RootState} from "../../store/store";
import {RelocationCaseRanking} from "../../typing";
import {fetchCasesRank} from "../../store/queueSlice";
import {QueueTableItemReport} from "../../components/queue-table-item-report/QueueTableItemReport";

export const CasesRank: React.FC = () => {
  // state
  const dispatch = useAppDispatch();
  const casesPerUser = useSelector((state: RootState) => state.queue.casesRank.list);
  const isLoading = useSelector((state: RootState) => state.queue.casesRank.isLoading);

  useEffect(() => {
    dispatch(fetchCasesRank());
  }, []);

  return (
    <>
      <div className={styles.headerReport}>
        <div className={styles.headerItem}>Agent Name</div>
        <div className={styles.headerItem}>Low Priority</div>
        <div className={styles.headerItem}>Medium Priority</div>
        <div className={styles.headerItem}>High Priority</div>
        <div className={styles.headerItem}>Case Load Total</div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        casesPerUser?.map((userRank: RelocationCaseRanking, index: number) => <QueueTableItemReport key={index} item={userRank} />)
      )}
    </>
  );
};
