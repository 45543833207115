import React, {FC} from "react";
import {amountFinancesContent, carouselDataToItems, carouselDataType, originalFinancesTable, urlFinancesContent} from "../../../utils";
import {FrontendConfiguration} from "@common/configuration";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import localStyles from "../WrapUpFinances.module.scss";
import {FinancesColumn, FinancesCarousel} from "../../../components";

interface OutSideBookingFinancesProps {}

export const OutsideBookingFinances: FC<OutSideBookingFinancesProps> = () => {
  const urlVacasaCom = FrontendConfiguration.getVacasaComUrl();
  const {url} = FrontendConfiguration.adminConfig();

  // finances
  const snapshotOriginPreResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPre) as any;
  const snapshotOriginPostResData = useSelector((state: RootState) => state.wrapUp.finances.origin.snapshotPost) as any;
  const destinationResData = useSelector((state: RootState) => state.wrapUp.finances.destination) as any;

  const isCaseOpen = useSelector((state: RootState) => state.caseProfile.isCaseOpen) as any;
  const isMidStayFM = useSelector((state: RootState) => state.wrapUp.isMidStayFM) as any;
  const currentOriginResData = useSelector((state: RootState) => state.wrapUp.finances.origin.current) as any;
  const compUnit = useSelector((state: RootState) => state.wrapUp.compUnit) as any;

  const originResFinanceReference = isMidStayFM && !isCaseOpen ? snapshotOriginPostResData : currentOriginResData;
  const midStayCarousel: carouselDataType[] = [
    {
      title: "MSFM Totals (Outside Booking)",
      listingLink: {url: compUnit?.attributes?.public_url, urlText: "Link"},
      resIdLink: {urlText: `Conf # ${compUnit.attributes.external_confirmation_code}`},
      rent: {primaryAmount: {amount: "n/a", wrapper: "+/-"}, secondaryAmount: {amount: "n/a", wrapper: "(+/-)"}},
      fees: {primaryAmount: {amount: "n/a", wrapper: "+/-"}, secondaryAmount: {amount: "n/a", wrapper: "(+/-)"}},
      tp: {primaryAmount: {amount: "n/a", wrapper: "+/-"}, secondaryAmount: {amount: "n/a", wrapper: "(+/-)"}},
      taxes: {primaryAmount: {amount: "n/a", wrapper: "+/-"}, secondaryAmount: {amount: "n/a", wrapper: "(+/-)"}},
      total: {
        primaryAmount: {
          amount: originResFinanceReference?.total + parseFloat(compUnit?.attributes?.external_amount) - snapshotOriginPreResData?.total,
          wrapper: "+/-",
        },
        secondaryAmount: {amount: originResFinanceReference?.total + parseFloat(compUnit?.attributes?.external_amount), wrapper: "(+/-)"},
      },
    },
    {
      title: "Unused Nights Removed",
      listingLink: {url: `${urlVacasaCom}/unit/${snapshotOriginPreResData?.unitId}`, urlText: snapshotOriginPreResData?.unitName},
      resIdLink: {url: `${url}/admin/dashboard/reservation/${snapshotOriginPreResData?.resId}`, urlText: snapshotOriginPreResData?.resId},
      rent: {
        primaryAmount: {amount: snapshotOriginPreResData?.rent - originResFinanceReference?.rent},
        secondaryAmount: {amount: -originResFinanceReference?.rent, wrapper: "(+/-)"},
      },
      fees: {
        primaryAmount: {amount: snapshotOriginPreResData?.fees - originResFinanceReference?.fees},
        secondaryAmount: {amount: -originResFinanceReference?.fees, wrapper: "(+/-)"},
      },
      tp: {
        primaryAmount: {amount: snapshotOriginPreResData?.tp - originResFinanceReference?.tp},
        secondaryAmount: {amount: -originResFinanceReference?.tp, wrapper: "(+/-)"},
      },
      taxes: {
        primaryAmount: {amount: snapshotOriginPreResData?.taxes - originResFinanceReference?.taxes},
        secondaryAmount: {amount: -originResFinanceReference?.taxes, wrapper: "(+/-)"},
      },
      total: {
        primaryAmount: {amount: snapshotOriginPreResData?.total - originResFinanceReference?.total},
        secondaryAmount: {amount: -originResFinanceReference?.total, wrapper: "(+/-)"},
      },
    },
    {
      title: "New Res",
      listingLink: {url: compUnit?.attributes?.public_url, urlText: "Link"},
      resIdLink: {urlText: `Conf # ${compUnit.attributes.external_confirmation_code}`},
      rent: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
      fees: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
      tp: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
      taxes: {primaryAmount: {amount: "n/a"}, secondaryAmount: {amount: null}},
      total: {primaryAmount: {amount: parseFloat(compUnit?.attributes?.external_amount)}, secondaryAmount: {amount: null}},
    },
  ];

  return (
    <div className={localStyles.financesTableTwoColumns}>
      {originalFinancesTable(snapshotOriginPreResData, false)}

      {!isMidStayFM ? (
        <FinancesColumn
          leftMarginContent={true}
          header={"New Res"}
          wideBox={false}
          showTitles={false}
          rows={[
            {
              title: "LISTING",
              content: urlFinancesContent("Link", compUnit?.attributes?.public_url),
            },
            {
              title: "RES ID",
              content: urlFinancesContent(`Conf # ${compUnit.attributes.external_confirmation_code}`),
            },
            {title: "RENT", content: amountFinancesContent({amount: "n/a"}, {amount: "n/a"})},
            {title: "FEES", content: amountFinancesContent({amount: "n/a"}, {amount: "n/a"})},
            {title: "TP", content: amountFinancesContent({amount: "n/a"}, {amount: "n/a"})},
            {title: "TAXES", content: amountFinancesContent({amount: "n/a"}, {amount: "n/a"})},
            {
              title: "TOTAL",
              content: amountFinancesContent(
                {amount: parseFloat(compUnit?.attributes.external_amount)},
                {amount: parseFloat(compUnit?.attributes?.external_amount) - snapshotOriginPreResData?.total, bold: true}
              ),
            },
          ]}
        />
      ) : (
        <FinancesCarousel items={carouselDataToItems(midStayCarousel)} showTitles={false} />
      )}
    </div>
  );
};
