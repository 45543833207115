import React, {useState, useEffect} from "react";
import styles from "./CurrencyComponent.module.scss";

interface CurrencyInput {
  value: string;
  onChange: Function;
  readOnly?: boolean;
}

export const CurrencyComponent: React.FunctionComponent<CurrencyInput> = (props) => {
  const urlRegEx = new RegExp("^[+-]?([0-9]+?([,][0-9]{0,3})+([.][0-9]{0,2})?|[0-9]+?([.][0-9]{0,2})?|[.][0-9]{0,2})$");
  const urlRegExToExtract = new RegExp("(^[+-]?([0-9]+?([,][0-9]{0,3})+([.][0-9]{0,2})?|[0-9]+?([.][0-9]{0,2})?|[.][0-9]{0,2})$).*");

  const {value, onChange, readOnly} = props;
  const [amount, setAmount] = useState(value);

  useEffect(() => {
    setAmount("$" + value.toString().replace(urlRegExToExtract, "$1"));
  }, [value]);

  const handleAmount = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value.replace("$0", "").replace("$", "");

    if (newValue === "") {
      setAmount("");
      onChange("");
    } else if (urlRegEx.test(newValue)) {
      setAmount("$" + newValue);
      const validate_value = newValue.endsWith(".") ? newValue.slice(0, -1) : newValue;
      onChange(validate_value);
    }
  };

  return (
    <>
      <input type="text" className={styles.currencyBox} value={amount} onChange={handleAmount} readOnly={readOnly ? readOnly : false} />
    </>
  );
};
