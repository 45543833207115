import React, {ChangeEvent, useEffect, useState, useCallback} from "react";
import {Grid, TextField, CircularProgress} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import styles from "./SearchBar.module.scss";
import * as _ from "lodash";
import {IdpService} from "@common/services";

interface ISearchBarProps {
  value: string;
  placeholder: string;
  title?: string;
  setIsSearchable?: Function;
  handleOnChange?: Function;
  handleOnCloseValue?: Function;
  onSearchChange: (event: any) => void;
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 35,
      color: "#2c3439;",
      fontFamily: "Nunito Sans, sans-serif;",
      fontWeight: "300 !important",
      background: `transparent
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23707579' width='20' height='20' viewBox='0 0 16 16' %3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' %3E%3C/path%3E%3C/svg>")
      no-repeat 5px`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #b2c2c8",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #b2c2c8",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #b2c2c8",
    },
  },
  root: {
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
    "& .MuiFormControl-fullWidth ": {
      width: "219px",
    },
  },
}));

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: any) => option.email,
});

export const SearchBar: React.FunctionComponent<ISearchBarProps> = ({
  placeholder,
  title,
  value,
  onSearchChange,
  handleOnChange,
  handleOnCloseValue,
  setIsSearchable,
}) => {
  const idpService = IdpService.getInstance();
  const classes = useStyles();
  const [options, setOptions] = useState<any[]>([]);
  const [initialOpen, setInitialOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    if (value === "") {
      setOpen(initialOpen);
      setOptions([]);
    }
    setInitialOpen(true);
    if (!_.isNil(value) && value.length > 2 && !value.includes("@vacasa.com")) {
      getIdpUsers();
    }
  }, [value]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  useEffect(() => {
    return () => {
      onSearchChange("");
      onSearchInputChange("");
      setIsSearchable?.(false);
    };
  }, []);

  const getIdpUsers = async () => {
    try {
      setSearching(true);
      const emailFilter = "vacasa.com";
      const {data} = await idpService.getIdpUsers(value.concat(",", emailFilter));
      const idpUsersMapped = data?.map((user: any) => {
        return {
          email: user.attributes.email,
        };
      });
      setOptions(idpUsersMapped);
    } catch (error) {
      console.error(error);
    } finally {
      setSearching(false);
    }
  };

  const onSearchInputChange = useCallback(
    _.debounce((value: any) => {
      if (value === "") {
        onSearchChange("");
        return;
      }
      onSearchChange(value.toLowerCase().split(" ").join("."));
    }, 700),
    []
  );

  const handleClose = async () => {
    setOpen(false);
    setIsSearchable?.(false);
  };

  useEffect(() => {
    setTimeout(async () => {
      const close = await document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
      close.addEventListener("click", async () => {
        await handleOnCloseValue?.();
      });
    }, 100);
  }, []);

  return (
    <>
      <span className={styles.title}>{title}</span>
      <Autocomplete
        id="select-users"
        classes={classes}
        options={options}
        defaultValue={{email: value}}
        value={{email: value}}
        getOptionLabel={(x) => {
          return !x ? "" : `${x.email}`;
        }}
        getOptionSelected={(option, value) => option.email === value.email}
        filterOptions={filterOptions}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        openOnFocus={true}
        onClose={handleClose}
        onChange={(event: ChangeEvent<{}>, newValue: any, reason: string) => {
          setOptions(newValue ? [newValue] : options);
          handleOnChange?.(newValue?.email, reason);
        }}
        onInputChange={(event: ChangeEvent<{}>, newInputValue: string) => {
          onSearchInputChange(newInputValue);
        }}
        renderInput={(params) => (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                {...params}
                id="users-results"
                fullWidth
                value={{email: value}}
                margin="normal"
                placeholder={placeholder}
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        renderOption={(option) => {
          return <div>{option.email}</div>;
        }}
      />
    </>
  );
};
