import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit} from "../index";
import {FormSubmissionSteps} from "../../../constants";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {SplitBilledStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import cx from "classnames";

type SplitBilledStepProps = {
  title: string;
  readOnly: boolean;
};

export const SplitBilledStep = ({title, readOnly}: SplitBilledStepProps) => {
  const dispatch = useAppDispatch();
  const {billedPercentage} = useSelector((state: RootState) => state.formSubmission.formData);
  const {currentStep, stepsPath} = useSelector((state: RootState) => state.formSubmission);

  return (
    <div className={generalStyles.stepContainer}>
      <h3>{title}</h3>
      <Formik
        initialValues={{
          billedPercentage,
        }}
        onSubmit={(values) => {
          dispatch(updateFormData(values));
          dispatch(updateStep({currentStep: FormSubmissionSteps.VACASA_BILLED, previousStep: FormSubmissionSteps.SPLIT_BILLED}));
          dispatch(refreshStepPath({currentStep: FormSubmissionSteps.VACASA_BILLED, buttonAction: "next"}));
        }}
        validationSchema={SplitBilledStepSchema}
      >
        {({errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <div className={generalStyles.container}>
              {/* Billed Percentage Section */}
              <label htmlFor="billedPercentage">
                What percentage are we charging the owner? {!readOnly && <span className={generalStyles.obligatory}>*</span>}
              </label>
              {!readOnly ? (
                <>
                  <Field as="select" name="billedPercentage" onChange={handleChange}>
                    <option value="">choose</option>
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                    <option value="30">30%</option>
                    <option value="40">40%</option>
                    <option value="50">50%</option>
                    <option value="60">60%</option>
                    <option value="70">70%</option>
                    <option value="80">80%</option>
                    <option value="90">90%</option>
                  </Field>
                  {touched.billedPercentage && errors.billedPercentage && <p className={generalStyles.validationError}>{errors.billedPercentage}</p>}
                </>
              ) : (
                <span className={generalStyles.response}>{billedPercentage}%</span>
              )}
            </div>

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton
                  step={stepsPath[stepsPath.findIndex((element) => element === currentStep) - 1]}
                  previousStep={FormSubmissionSteps.SPLIT_BILLED}
                />
                <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                  Next
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
