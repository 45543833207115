//@ts-nocheck
import styles from "./VerticalTimeline.module.scss";
import React, {useEffect, useState} from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

export interface IVerticalTimelineEvent {
  active?: boolean;
  title?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  clicked?: boolean;
  customDot?: JSX.Element | null;
}

interface IVerticalTimelineProps {
  events: IVerticalTimelineEvent[];
}

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
});

export const VerticalTimeline: React.FunctionComponent<IVerticalTimelineProps> = (props) => {
  const initialActive = () => {
    const activeIndex = props.events
      .map((e) => {
        return !!e.active;
      })
      .indexOf(true);
    return activeIndex !== -1 ? activeIndex : props.events.length;
  };

  const [active, setActive] = useState(initialActive());
  const [clicked, setClicked] = useState(initialActive());
  const classes = useStyles();

  useEffect(() => {
    if (!_.isEmpty(props.events)) {
      setActive(initialActive());
      setClicked(initialActive());
    }
  }, [props.events]);

  return (
    <Timeline
      classes={{
        root: styles.scheduleBox,
      }}
    >
      {props.events.map((event, index) => {
        return (
          <TimelineItem
            key={index}
            classes={{
              missingOppositeContent: classes.missingOppositeContent,
              root: styles.eventRow,
            }}
          >
            <TimelineSeparator
              classes={{
                root: styles.timelineSection,
              }}
            >
              <div className={index === active ? styles.verticalBarTopBoxActive : styles.verticalBarTopBox}>
                <TimelineConnector
                  classes={{
                    root:
                      index === 0
                        ? styles.hidden
                        : index <= active
                        ? cx(styles.verticalBar, styles.verticalBarTop, styles.pastVerticalBar)
                        : cx(styles.verticalBar, styles.verticalBarTop),
                  }}
                />
              </div>
              {event.customDot ? (
                <div className={styles.customDot}>{event.customDot}</div>
              ) : (
                <TimelineDot
                  classes={{
                    root: cx(
                      styles.dot,
                      event.clicked ? styles.passiveDot : event.active ? styles.activeDot : index < active ? styles.pastDot : styles.passiveDot
                    ),
                  }}
                />
              )}

              <TimelineConnector
                classes={{
                  root:
                    index === props.events.length - 1
                      ? styles.hidden
                      : index < active
                      ? cx(styles.verticalBar, styles.pastVerticalBar)
                      : styles.verticalBar,
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              classes={{
                root: styles.timelineContent,
              }}
            >
              <div onClick={() => setClicked(index)}>
                <span
                  className={
                    index < active
                      ? cx(styles.clickable, index === clicked ? styles.Title : styles.TitleInactive)
                      : index === clicked
                      ? styles.Title
                      : cx(styles.clickable, styles.TitleInactive)
                  }
                >
                  {event.title}
                </span>
              </div>
              {event.clicked ? event.children : index === clicked && event.children}
              <div className={styles.eventSeparator}></div>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
