import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {updateCreatedAtFilter} from "../../store/queueSlice";
import styles from "./FilterCreatedAt.module.scss";
import dayjs from "dayjs";

interface IFilterCreatedAtProps {
  createdAt: {id: number; text: string; day: number};
  onClose: Function;
}

export const FilterCreatedAt: React.FunctionComponent<IFilterCreatedAtProps> = (props) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useAppDispatch();
  const currentCreatedAt = useSelector((state: RootState) => state.queue.filters.createdAt.value);
  const createdAtDay = dayjs().subtract(props.createdAt.day, "day").format("YYYY-MM-DD");

  const handleSelectedCreatedAt = () => {
    dispatch(updateCreatedAtFilter(createdAtDay));
    setChecked(true);
    props.onClose();
  };

  useEffect(() => {
    // @ts-ignore
    if (currentCreatedAt === createdAtDay) {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (currentCreatedAt !== createdAtDay) {
      setChecked(false);
    }
  }, [currentCreatedAt]);

  return (
    <div className={styles.createdAtGrid} onClick={handleSelectedCreatedAt}>
      <div className={styles.dvRadio}>
        <input
          type="radio"
          name="rbt_createdAt"
          value={props.createdAt.id}
          className={styles.createdAtRadio}
          checked={checked}
          onChange={handleSelectedCreatedAt}
        />
      </div>
      <div>
        <label>{props.createdAt.text}</label>
      </div>
    </div>
  );
};
